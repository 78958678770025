import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {AirportsService} from '../../../../../services/airports.service';
import {ToastService} from '../../../../../services/toast.service';
import {NgbActiveModal, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {
  CurfewWeekday,
  IAirportCurfewTableData,
  IAirportCurfewTime,
  IAirportCurfewTimesUpdateModel
} from '../../../../../shared/models/airport-curfew-time.model';
import {GeneralSettingsService} from '../../../../../services/general-settings.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {IAirport} from "../../../../../shared/models/airport.model";
import dayjs from "dayjs";
import {dayjsToNgbDate, ngbDateToDayjs} from "../../../../../shared/utils/utils";
import {ITimezone} from "../../../../../shared/models/timezone.model";
import {getCurrentTimezoneOffset} from "../../../../../shared/utils/timezone-functions";

@Component({
  selector: 'app-add-edit-airport-curfew-time-dialog',
  templateUrl: './add-edit-airport-curfew-time-dialog.component.html',
  styleUrls: ['./add-edit-airport-curfew-time-dialog.component.scss']
})
export class AddEditAirportCurfewTimeDialogComponent implements OnInit {

  title = "Add Curfew Time";
  dateFormat = 'dd/mm/yyyy';
  formGroup: FormGroup;
  data?: IAirportCurfewTableData;
  airport?: IAirport;
  isBusy = false;
  hoveredDate?: NgbDate;
  allCurfewTimes: IAirportCurfewTime[];
  timezone?: ITimezone;
  timezoneOffsetMinutes: number;
  overlapData: string[];


  constructor(private airportService: AirportsService, private toastService: ToastService, private activeModal: NgbActiveModal, private generalSettingsService: GeneralSettingsService) {
  }

  drp?: boolean;

  unsubscribe$ = new Subject();
  ngOnDestroy() {
      this.unsubscribe$.next(undefined);
      this.unsubscribe$.complete();
    }

  ngOnInit() {
    this.timezoneOffsetMinutes = getCurrentTimezoneOffset(this.timezone);
    this.generalSettingsService.generalSettings.pipe(takeUntil(this.unsubscribe$)).subscribe((settings) => {
      this.dateFormat = settings.dateFormat;
    });
    this.formGroup = new FormGroup({
      isUtc: new FormControl(this.data?.isUtc ?? false),
      isLocal: new FormControl(!this.data?.isUtc ?? true),
      validFrom: new FormControl(this.data?.from ? dayjsToNgbDate(dayjs.utc(this.data?.from, 'DD/MM/YYYY')) : null, Validators.required),
      validTo: new FormControl(this.data?.to ? dayjsToNgbDate(dayjs.utc(this.data?.to, 'DD/MM/YYYY')) : null),
      closingTime: new FormControl(this.data?.closing || null, Validators.required),
      openingTime: new FormControl(this.data?.opening || null, Validators.required),
      mon: new FormControl(!!this.data?.mon?.id),
      tue: new FormControl(!!this.data?.tue?.id),
      wed: new FormControl(!!this.data?.wed?.id),
      thu: new FormControl(!!this.data?.thu?.id),
      fri: new FormControl(!!this.data?.fri?.id),
      sat: new FormControl(!!this.data?.sat?.id),
      sun: new FormControl(!!this.data?.sun?.id),
    }, {
      validators: [this.atLeastOneDayValidator, this.overlapValidator.bind(this)]
    });
    this.formGroup.updateValueAndValidity();

    this.formGroup.get('isUtc').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      this.formGroup.patchValue({ isLocal: !result }, { emitEvent: false, onlySelf: true });
    });
    this.formGroup.get('isLocal').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      this.formGroup.patchValue({ isUtc: !result }, { emitEvent: false, onlySelf: true });
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.formGroup.get('validFrom').value && !this.formGroup.get('validTo').value) {
      this.formGroup?.patchValue({
        validFrom: date
      });
    } else if (this.formGroup.get('validFrom').value && !this.formGroup.get('validTo').value && !date.before(this.formGroup.get('validFrom').value)) {
      this.formGroup?.patchValue({
        validTo: date
      });
    } else {
      this.formGroup?.patchValue({
        validFrom: date,
        validTo: null
      });
    }
  }

  isHovered(date: NgbDate) {
    return this.formGroup.get('validFrom').value && !this.formGroup.get('validTo').value && this.hoveredDate && date.after(this.formGroup.get('validFrom').value) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.formGroup.get('validFrom').value) && date.before(this.formGroup.get('validTo').value);
  }

  isRange(date: NgbDate) {
    return date.equals(this.formGroup.get('validFrom').value) || date.equals(this.formGroup.get('validTo').value) || this.isInside(date) || this.isHovered(date);
  }

  getDateString(date: NgbDate) {
    if (!date) {
      return 'none';
    }
    return date.day + '.' + date.month + '.' + date.year;
  }

  closeModal(refresh?: boolean) {
    this.activeModal.dismiss(refresh);
  }

  onSaveClick() {
    this.isBusy = true;
    const dataArray: IAirportCurfewTime[] = [];
    const oldIds: number[] = [];
    for(let weekday of ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']) {
      if (this.data?.[weekday]?.id) {
        oldIds.push(this.data[weekday].id);
      }
      if (!this.formGroup.value[weekday]) {
        continue;
      }
      const data: IAirportCurfewTime = {
        validFrom: ngbDateToDayjs(this.formGroup.get('validFrom').value).toDate(),
        validTo: this.formGroup.get('validTo').value ? ngbDateToDayjs(this.formGroup.get('validTo').value).endOf('day').millisecond(0).toDate() : null,
        curfewEndTime: this.formGroup.value.closingTime,
        curfewStartTime: this.formGroup.value.openingTime,
        isUtc: this.formGroup.value.isUtc,
        airportId: this.airport?.id,
        isActive: true,
        weekday: CurfewWeekday[weekday.toUpperCase()]
      };
      const validFrom = ngbDateToDayjs(this.formGroup.get('validFrom').value);
      const validTo = this.formGroup.get('validTo').value ? ngbDateToDayjs(this.formGroup.get('validTo').value) : null;
      const curfewStart = dayjs(`${validFrom.format('DD/MM/YYYY')} ${data.curfewStartTime}`, 'DD/MM/YYYY HH:mm').second(0).utcOffset(this.timezoneOffsetMinutes).utc(data.isUtc);
      const curfewEnd = dayjs(`${validTo ? validTo.format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY')} ${data.curfewEndTime}`, 'DD/MM/YYYY HH:mm').second(0).utcOffset(this.timezoneOffsetMinutes).utc(data.isUtc);
      data.curfewStartTime = curfewStart.format('HH:mm');
      data.curfewEndTime = curfewEnd.format('HH:mm');
      data.validFrom = curfewStart.toDate();
      if (data.validTo) {
        data.validTo = curfewEnd.toDate();
      }
      dataArray.push(data);
    }


    const data: IAirportCurfewTimesUpdateModel = {
      data: dataArray,
      oldIds
    }

    this.airportService.saveAirportCurfewTimes(data).subscribe((result) => {
      if(result) {
        this.toastService.showSuccess("Curfew Times have been saved");
        this.closeModal(true);
      }
      this.isBusy = false;
    });
  }

  atLeastOneDayValidator(formGroup: FormGroup): ValidationErrors | null {
    return !formGroup.value.mon && !formGroup.value.tue && !formGroup.value.wed && !formGroup.value.thu && !formGroup.value.fri && !formGroup.value.sat && !formGroup.value.sun ? { noDaysSelected: true } : null;
  }

  overlapValidator(formGroup: FormGroup): ValidationErrors | null {
    if (!formGroup.value.validFrom || !formGroup.value.openingTime || !formGroup.value.closingTime || (!formGroup.value.mon && !formGroup.value.tue && !formGroup.value.wed && !formGroup.value.thu && !formGroup.value.fri && !formGroup.value.sat && !formGroup.value.sun)) {
      return null;
    }
    const dataArray: IAirportCurfewTime[] = [];
    for(let weekday of ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']) {
      if (!formGroup.value[weekday]) {
        continue;
      }
      const data: IAirportCurfewTime = {
        validFrom: ngbDateToDayjs(formGroup.get('validFrom').value).utc(true).toDate(),
        validTo: formGroup.get('validTo').value ? ngbDateToDayjs(formGroup.get('validTo').value).utc(true).endOf('day').millisecond(0).toDate() : null,
        curfewEndTime: formGroup.value.closingTime,
        curfewStartTime: formGroup.value.openingTime,
        isUtc: formGroup.value.isUtc,
        weekday: CurfewWeekday[weekday.toUpperCase()]
      };
      data.curfewStartTime = dayjs(data.curfewStartTime, 'HH:mm').utcOffset(this.timezoneOffsetMinutes).utc(formGroup.value.isUtc).format('HH:mm');
      data.curfewEndTime = dayjs(data.curfewEndTime, 'HH:mm').utcOffset(this.timezoneOffsetMinutes).utc(formGroup.value.isUtc).format('HH:mm');
      dataArray.push(data);
    }
    const overlapping = this.checkCurfewTimesOverlap(this.allCurfewTimes, dataArray);
    return overlapping ? { overlap: true } : null;
  }

  checkCurfewTimesOverlap(existingData: IAirportCurfewTime[], newData: IAirportCurfewTime[]): boolean {
    for (const curfewTimes of existingData) {
      for (const newCurfewTimes of newData) {
        const overlapDates: Date[] = this.calculateOverlapDates(curfewTimes, newCurfewTimes);
        const overlap = this.compareCurfewTimes(curfewTimes, newCurfewTimes, overlapDates);
        if (overlap) {
          return true;
        }
      }
    }
    return false;
  }

  compareCurfewTimes(data1: IAirportCurfewTime, data2: IAirportCurfewTime, dates: Date[]): boolean {
    for (const date of dates) {
      let start1 = dayjs
        .utc(date)
        .hour(+data1.curfewStartTime.substring(0, 2))
        .minute(+data1.curfewStartTime.substring(3, 5))
        .second(0)
        .millisecond(0);
      let end1 = dayjs
        .utc(date)
        .hour(+data1.curfewEndTime.substring(0, 2))
        .minute(+data1.curfewEndTime.substring(3, 5))
        .second(0)
        .millisecond(0);

      let start2 = dayjs
        .utc(date)
        .hour(Number(data2.curfewStartTime.substring(0, 2)))
        .minute(+data2.curfewStartTime.substring(3, 5))
        .second(0)
        .millisecond(0);
      let end2 = dayjs
        .utc(date)
        .hour(+data2.curfewEndTime.substring(0, 2))
        .minute(+data2.curfewEndTime.substring(3, 5))
        .second(0)
        .millisecond(0);

      // Check if interval1 starts before or at the same time as interval2 ends
      // and if interval1 ends after or at the same time as interval2 starts
      const overlapInTime = !(end1.isBefore(start2) || start1.isAfter(end2));

      // Check if the intervals overlap on the same weekday
      const overlapInWeekday = data1.weekday === data2.weekday;

      if (overlapInTime && overlapInWeekday) {
        if (!data1.isUtc) {
          start1 = start1.utcOffset(this.timezoneOffsetMinutes).local();
          end1 = end1.utcOffset(this.timezoneOffsetMinutes).local();
        }
        if (!data2.isUtc) {
          start2 = start2.utcOffset(this.timezoneOffsetMinutes).local();
          end2 = end2.utcOffset(this.timezoneOffsetMinutes).local();
        }
        this.overlapData = [
          `${start1.format(this.generalSettingsService.generalSettings.value.dateFormat + ' HH:mm')} - ${end1.format(this.generalSettingsService.generalSettings.value.dateFormat + ' HH:mm')} `,
          `${start2.format(this.generalSettingsService.generalSettings.value.dateFormat + ' HH:mm')} - ${end2.format(this.generalSettingsService.generalSettings.value.dateFormat + ' HH:mm')} `];
        return true;
      }
      this.overlapData = undefined;
    }
    return false;
  }

  calculateOverlapDates(interval1: IAirportCurfewTime, interval2: IAirportCurfewTime): Date[] {
    const overlapDates: Date[] = [];

    const start1 = dayjs.utc(interval1.validFrom);
    let end1 = dayjs.utc(interval1.validTo || '2300-12-31');

    const start2 = dayjs.utc(interval2.validFrom);
    let end2 = dayjs.utc(interval2.validTo || '2300-12-31');
    if (end1.year() === 2300 && end2.year() !== 2300) {
      end1 = end2;
    } else if (end1.year() !== 2300 && end2.year() === 2300) {
      end2 = end1;
    }
    const startOverlap = dayjs.max(start1, start2);
    const endOverlap = dayjs.min(end1, end2);

    if (startOverlap.isSameOrBefore(endOverlap, 'day')) {
      for (let date = startOverlap; date.isSameOrBefore(endOverlap, 'day'); date = date.add(1, 'day')) {
        overlapDates.push(date.toDate());
      }
    }
    return overlapDates;
  }
}
