import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {dayjsToNgbDate, ngbDateToDayjs} from "../../shared/utils/utils";
import dayjs from "dayjs";
import {IAirport} from "../../shared/models/airport.model";
import {AirportsService} from "../../services/airports.service";
import {UserService} from "../../services/user.service";
import {debounceTime, forkJoin, map, Observable, Subject, tap} from "rxjs";
import {UserRoles} from "../../shared/constants/user-roles.constants";
import {UserLegAssignmentService} from "../../services/user-leg-assignment.service";
import {IUser} from "../../shared/models/user.model";
import {IUserLegAssignmentModel} from "../../shared/models/user-leg-assignment.model";
import {IGenericContainerObject} from "../../shared/models/genericContainerObject.model";
import {LegsService} from "../../services/legs.service";
import {ILegsModel} from "../../shared/models/legs.model";
import {takeUntil} from "rxjs/operators";
import {AuthService} from "../../services/auth.service";
import {ToastService} from "../../services/toast.service";
import {IPairsModel} from "../../shared/models/pairs.model";

@Component({
  selector: 'app-user-assignment',
  templateUrl: './user-assignment.component.html',
  styleUrls: ['./user-assignment.component.scss']
})
export class UserAssignmentComponent implements OnInit, OnDestroy {

  form: FormGroup;
  stations: IAirport[];
  users: IUser[];
  usersKV: IGenericContainerObject<IUser> = {};
  legAssignments: IUserLegAssignmentModel[];
  legAssignmentsKV: IGenericContainerObject<IUserLegAssignmentModel> = {};
  selectedUserId: number;
  legs: ILegsModel[];
  unsubscribe$ = new Subject();
  selectedLegIds: number[] = [];
  deleting: number[] = [];
  pageSize = 10;
  currentPage = 0;
  isBusy = false;
  assignmentsPerUser: IGenericContainerObject<number> = {};
  filteredFlights: ILegsModel[] = [];
  pairs: IPairsModel[];

  constructor(private airportService: AirportsService, private userService: UserService, private userLegAssignmentService: UserLegAssignmentService, private legService: LegsService, private authService: AuthService, private toastService: ToastService) {
    this.airportService.fetchAirports().subscribe((res) => {
      this.stations = res;
    });
    forkJoin([this.userService.fetchUsers({
      isActive: true,
      role: UserRoles.RAMP_AGENT,
      location: this.authService?.user?.role === 'DUTY_MANAGER' ? this.authService?.user?.location : undefined,
    }), this.fetchUserLegAssignments(), this.fetchFlights()]).subscribe(([users, _, legs]) => {
      this.users = users;
      this.selectedUserId = window.history.state?.userId;
      users.forEach((user) => {
        this.usersKV[user.id] = user;
      });
      this.legs = legs;
      this.filterFlights();
    });
  }

  fetchUserLegAssignments() {
    return this.userLegAssignmentService.getUserLegAssignment({isActive: true}).pipe(tap((res) => {
      this.legAssignments = res;
      this.legAssignmentsKV = {};
      this.legAssignments.forEach((assignment) => {
        this.legAssignmentsKV[assignment.arrivalLegId] = assignment;
      });
      this.assignmentsPerUser = {};
      this.legAssignments.forEach((assignment) => {
        if (!this.assignmentsPerUser[assignment.userId]) {
          this.assignmentsPerUser[assignment.userId] = 0;
        }
        this.assignmentsPerUser[assignment.userId]++;
      });
    }));
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      date: new FormControl(dayjsToNgbDate(dayjs.utc())),
      flightNr: new FormControl(),
      station: new FormControl({
        value: this.authService.user?.role === 'DUTY_MANAGER' ? this.authService.user.location : null,
        disabled: this.authService.user?.role === 'DUTY_MANAGER'
      }),
      status: new FormControl(),
    });
    for (const key of ['date', 'flightNr', 'station', 'status']) {
      this.addListener(key);
    }
  }

  addListener(name: string) {
    this.form.get(name).valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(250)).subscribe(() => {
      if (name === 'status') {
        this.filterFlights();
        return;
      }
      this.fetchFlights().subscribe((res) => {
        this.legs = res;
        this.filterFlights();
      });
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  filterFlights() {
    this.currentPage = 0;
    this.filteredFlights = this.legs.filter((leg) => {
      if (this.selectedUserId) {
        if (leg.arrivalStation !== this.usersKV[this.selectedUserId]?.location) {
          return false;
        }
      }
      const val = this.form.value;
      if (val.status === true && !this.legAssignmentsKV[leg.id]) {
        return false;
      }
      return !(val.status === false && this.legAssignmentsKV[leg.id]);
    });
  }

  changedUser(id: number, event: Event) {
    const elem = event.target as HTMLInputElement;
    this.selectedUserId = elem.checked ? id : null;
    if (!this.selectedUserId) {
      this.selectedLegIds.splice(0);
    }
    this.filterFlights();
  }

  fetchFlights() {
    const val = this.form?.value;
    this.selectedLegIds?.splice(0);
    return this.legService.getLegs({
      tod: val?.date ? {
        from: ngbDateToDayjs(this.form.value.date).toDate(),
        to: ngbDateToDayjs(this.form.value.date).endOf('day').toDate()
      } : val === undefined ? {
        from: dayjs.utc().startOf('day').toDate(),
        to: dayjs.utc().endOf('day').toDate()
      } : undefined,
      flightNumber: val?.flightNr ? val?.flightNr : undefined,
      arrivalStation: this.authService.user?.role === 'DUTY_MANAGER' ? this.authService.user.location : val?.station || undefined,
      isActive: true,
    }).pipe(map((data: ILegsModel[]) => {
      return data.sort((a: ILegsModel, b: ILegsModel) => a.sta > b.sta ? 1 : b.sta > a.sta ? -1 : 0);
    }));
  }

  trackByFunction(index: number, item: ILegsModel) {
    return item.id; // or whatever unique identifier you wish to use
  }

  flightClicked(id: number) {
    if (!this.selectedUserId) {
      return;
    }
    const index = this.selectedLegIds?.findIndex((legId) => legId === id);
    if (index !== -1) {
      this.selectedLegIds?.splice(index, 1);
      return;
    }
    this.selectedLegIds.push(id);
  }

  unassignAgent(userLegAssignmentId: number) {
    this.deleting.push(userLegAssignmentId);
    this.userLegAssignmentService.deleteUserLegAssignment(userLegAssignmentId).subscribe((res) => {
      const indexId = this.deleting.findIndex((id) => id === userLegAssignmentId);
      if (res) {
        this.fetchUserLegAssignments().subscribe(() => {
          if (indexId !== -1) {
            this.deleting.splice(indexId, 1);
          }
        });
        return;
      }
      if (indexId !== -1) {
        this.deleting.splice(indexId, 1);
      }

    });
  }

  public readonly Math = Math;

  assignFlights() {
    if (!this.selectedUserId || !this.selectedLegIds?.length) {
      return;
    }
    const obs: Observable<IUserLegAssignmentModel>[] = [];
    for (const selectedLegId of this.selectedLegIds) {
      obs.push(this.userLegAssignmentService.saveUserLegAssignment({
        userId: this.selectedUserId,
        arrivalLegId: selectedLegId,
        isActive: true,
      }));
    }
    if (obs.length) {
      this.isBusy = true;
      forkJoin(obs).subscribe((res) => {
        this.toastService.showSuccess('Ramp agent has been assigned');
        this.fetchUserLegAssignments().subscribe(() => {
          this.isBusy = false;
        });
      });
    }
  }
}
