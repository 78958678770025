<ng-container *ngIf="formGroup && timezoneFormGroup">

<app-settings-header [backButton]="true" backButtonUrl=".." [text]="loaded ? (airport ? 'Edit Airport ' + airport?.iata : 'Add Airport') : 'Loading...'" [showSaveButton]="true" [textSize]="20" (onSaveClicked)="onSaveClick()" [saveButtonDisabled]="formGroup.invalid"
                     [spinner]="isBusy"></app-settings-header>
<div class="settings-container mt-3 flex flex-column avitium-scrollbar">
  <div class="flex flex-column gap-2">
    <app-settings-card sectionTitle="General" [formGroup]="formGroup" data-test="general-card">
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="iata" class="form-label">IATA Code</label>
          <input type="text" class="form-control" id="iata" formControlName="iata" data-test="edit-airport-iata-input">
        </div>
        <div class="flex-basis-30">
          <label for="title" class="form-label">Airport Name</label>
          <input type="text" class="form-control" id="title" formControlName="title" data-test="edit-airport-title-input">
        </div>
        <div class="flex-basis-30">
          <label for="title" class="form-label">Country</label>
          <input type="text" class="form-control" id="settings-airport-country" placeholder="" formControlName="country" [ngbTypeahead]="searchCountry" (blur)="onCountryBlur()" data-test="edit-airport-country-input">
        </div>
      </div>
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="description" class="form-label">Coordinates Latitude</label>
          <input type="text" class="form-control" id="description" formControlName="latitude" data-test="edit-airport-latitude-input">
        </div>
        <div class="flex-basis-30">
          <label for="mtow" class="form-label">Coordinates Longitude</label>
          <input type="text" class="form-control" id="mtow" formControlName="longitude" data-test="edit-airport-longitude-input">
        </div>
        <div class="flex-basis-30">
          <label for="settings-airport-slot-coordination-level" class="form-label">Slot Coordination Level</label>
          <select class="form-select light-blue-border dark-blue" id="settings-airport-slot-coordination-level" formControlName="slotCoordinationLevel" data-test="edit-airport-slot-coordination-level-select">
            <option value="Level 1">Level 1</option>
            <option value="Level 2">Level 2</option>
            <option value="Level 3">Level 3</option>
          </select>
        </div>
      </div>
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="icao" class="form-label">ICAO Code</label>
          <input type="text" class="form-control" id="icao" formControlName="icao" data-test="edit-airport-icao-input">
        </div>
        <div class="flex-basis-30">
          <label for="fireCategory" class="form-label">Fire Category</label>
          <input type="text" class="form-control" id="fireCategory" formControlName="fireCategory" data-test="edit-airport-fire-category-input">
        </div>
        <div class="flex-basis-30 align-self-end">
          <div class="form-check form-switch mb-2 flex align-items-center gap-2">
            <input class="form-check-input" type="checkbox" id="camera" role="switch" data-test="camera-feed-check" [(ngModel)]="cameraFeed" [ngModelOptions]="{ standalone: true }">
            <label class="form-check-label dark-blue align-self-center" for="camera">Show Camera Live Feed</label>
          </div>
        </div>
      </div>
    </app-settings-card>

    <app-settings-card (onAddClicked)="openManageAirportTerminals()" sectionTitle="Terminals" addText="Add Terminal" *ngIf="this.airport" data-test="terminals-card">
        <app-settings-data-table [testTagPipe]="AirportTerminalsTestTagPipe" class="mt-3" [columns]="['Code', 'Description']" [data]="airportTerminals" [variableNames]="['alias', 'name']" (onEditClicked)="openManageAirportTerminals($event)" (onDeleteClicked)="deleteTerminal($event)"></app-settings-data-table>
    </app-settings-card>

    <app-settings-card sectionTitle="Time Zone" [formGroup]="timezoneFormGroup" data-test="timezone-card">
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="utcWithoutDst" class="form-label">Non-DST Variation</label>
          <div class="flex gap-2">
            <select class="form-control form-select" id="settings-airport-timezone-non-dst-variation-operator"
                    formControlName="utcWithoutDstOperator" data-test="non-dst-operator" >
              <option value="">+</option>
              <option value="-">-</option>
            </select>
            <ngb-timepicker appTimepickerFocus [spinners]="false" id="utcWithoutDst"  formControlName="utcWithoutDst" data-test="edit-airport-utc-without-dst-time-picker"></ngb-timepicker>
          </div>
        </div>
        <div class="flex-basis-30">
          <label for="utcWithDst" class="form-label">DST Variation</label>
          <div class="flex gap-2">
            <select class="form-control form-select" id="settings-airport-timezone-dst-variation-operator"
                    formControlName="utcWithDstOperator" data-test="edit-airport-utc-with-dst-operator-select">
              <option value="">+</option>
              <option value="-">-</option>
            </select>
            <ngb-timepicker appTimepickerFocus [spinners]="false" id="utcWithDst" formControlName="utcWithDst" data-test="edit-airport-utc-with-dst-time-picker"></ngb-timepicker>
          </div>
        </div>
        <div class="flex-basis-30 align-self-center">
          <div class="form-check form-switch dst-switch-container">
            <label class="form-check-label dark-blue" for="dstApplies">DST applies</label>
            <input class="form-check-input" type="checkbox" role="switch" id="dstApplies" formControlName="dstApplies" data-test="edit-airport-dst-applies-check">
          </div>
        </div>
      </div>
      <ng-container *ngIf="timezoneFormGroup?.value?.dstApplies">
        <div class="flex dark-blue justify-content-between">
          <div class="flex-basis-30">
            <label for="dstStartTime" class="form-label">DST Start</label>
            <ngb-timepicker appTimepickerFocus [spinners]="false" id="dstStartTime" formControlName="dstStartTime" data-test="edit-airport-dst-start-time-picker"></ngb-timepicker>
          </div>
          <div class="flex-basis-30">
            <label for="settings-airport-timezone-dst-start-month" class="form-label">DST Start Month</label>
            <select class="form-control form-select" id="settings-airport-timezone-dst-start-month"
                    formControlName="dstStartMonth" data-test="edit-airport-dst-start-month-select">
              <option value="JAN">January</option>
              <option value="FEB">February</option>
              <option value="MAR">March</option>
              <option value="APR">April</option>
              <option value="MAY">May</option>
              <option value="JUN">June</option>
              <option value="JUL">July</option>
              <option value="AUG">August</option>
              <option value="SEP">September</option>
              <option value="OCT">October</option>
              <option value="NOV">November</option>
              <option value="DEC">December</option>
            </select>
          </div>
          <div class="flex-basis-30">
            <label for="settings-airport-timezone-dst-start-weekday" class="form-label">DST Start Weekday</label>
            <select class="form-control form-select" id="settings-airport-timezone-dst-start-weekday"
                    formControlName="dstStartWeekday" data-test="edit-airport-dst-start-weekday-select">
              <option value="MON">Monday</option>
              <option value="TUE">Tuesday</option>
              <option value="WED">Wednesday</option>
              <option value="THU">Thursday</option>
              <option value="FRI">Friday</option>
              <option value="SAT">Saturday</option>
              <option value="SUN">Sunday</option>
            </select>
          </div>
        </div>
        <div class="flex dark-blue justify-content-between">
          <div class="flex-basis-30">
            <label for="settings-airport-timezone-dst-start-weekday-counter" class="form-label">Week of the month</label>
            <select class="form-control form-select" id="settings-airport-timezone-dst-start-weekday-counter"
                    formControlName="dstStartWeekOfMonth" data-test="edit-airport-dst-start-week-of-month-select">
              <option value="First">First</option>
              <option value="Second">Second</option>
              <option value="Third">Third</option>
              <option value="Last">Last</option>
            </select>
          </div>
          <div class="flex-basis-30">
          </div>
          <div class="flex-basis-30">
          </div>
        </div>


        <div class="flex dark-blue justify-content-between">
          <div class="flex-basis-30">
            <label for="dstEndTime" class="form-label">DST End</label>
            <ngb-timepicker appTimepickerFocus [spinners]="false" id="dstEndTime" formControlName="dstEndTime" data-test="edit-airport-dst-end-time-picker"></ngb-timepicker>
          </div>
          <div class="flex-basis-30">
            <label for="settings-airport-timezone-dst-end-month" class="form-label">DST End Month</label>
            <select class="form-control form-select" id="settings-airport-timezone-dst-end-month"
                    formControlName="dstEndMonth" data-test="edit-airport-dst-end-month-select">
              <option value="JAN">January</option>
              <option value="FEB">February</option>
              <option value="MAR">March</option>
              <option value="APR">April</option>
              <option value="MAY">May</option>
              <option value="JUN">June</option>
              <option value="JUL">July</option>
              <option value="AUG">August</option>
              <option value="SEP">September</option>
              <option value="OCT">October</option>
              <option value="NOV">November</option>
              <option value="DEC">December</option>
            </select>
          </div>
          <div class="flex-basis-30">
            <label for="settings-airport-timezone-dst-end-weekday" class="form-label">DST End Weekday</label>
            <select class="form-control form-select" id="settings-airport-timezone-dst-end-weekday"
                    formControlName="dstEndWeekday" data-test="edit-airport-dst-end-weekday-select">
              <option value="MON">Monday</option>
              <option value="TUE">Tuesday</option>
              <option value="WED">Wednesday</option>
              <option value="THU">Thursday</option>
              <option value="FRI">Friday</option>
              <option value="SAT">Saturday</option>
              <option value="SUN">Sunday</option>
            </select>
          </div>
        </div>
        <div class="flex dark-blue justify-content-between">
          <div class="flex-basis-30">
            <label for="settings-airport-timezone-dst-end-weekday-counter" class="form-label">Week of the month</label>
            <select class="form-control form-select" id="settings-airport-timezone-dst-end-weekday-counter"
                    formControlName="dstEndWeekOfMonth" data-test="edit-airport-dst-end-week-of-month-select">
              <option value="First">First</option>
              <option value="Second">Second</option>
              <option value="Third">Third</option>
              <option value="Last">Last</option>
            </select>
          </div>
          <div class="flex-basis-30">
          </div>
          <div class="flex-basis-30">
          </div>
        </div>
      </ng-container>

    </app-settings-card>

    <app-settings-card (onAddClicked)="openManageAirportContacts()" sectionTitle="Contacts" addText="Add Contact" *ngIf="this.airport" data-test="contacts-card">
      <app-settings-data-table [testTagPipe]="AirportContactsTestTagPipe" class="mt-3" [columns]="['Name', 'Surname', 'Company', 'Department', 'Tel.Nr.', 'Email']" [data]="airportContacts" [variableNames]="['firstname', 'lastname', 'company', 'department', 'mobile', 'email']" (onEditClicked)="openManageAirportContacts($event)" (onDeleteClicked)="deleteContact($event)"></app-settings-data-table>
    </app-settings-card>

    <app-settings-card sectionTitle="Slot Time Tolerance" [formGroup]="slotTimeToleranceFormGroup" *ngIf="slotTimeToleranceFormGroup" data-test="slot-time-tolerance-card">
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-25">
          <label for="departureEarlyInMinutes" class="form-label">Early Departure Slot Tolerance</label>
          <ngb-timepicker appTimepickerFocus [spinners]="false" id="departureEarlyInMinutes" formControlName="departureEarlyInMinutes" data-test="edit-airport-early-departure-slot-time-picker"></ngb-timepicker>
        </div>
        <div class="flex-basis-25">
          <label for="departureLateInMinutes" class="form-label">Late Departure Slot Tolerance</label>
          <ngb-timepicker appTimepickerFocus [spinners]="false" id="departureLateInMinutes" formControlName="departureLateInMinutes" data-test="edit-airport-late-departure-slot-time-picker"></ngb-timepicker>
        </div>
        <div class="flex-basis-25">
          <label for="arrivalEarlyInMinutes" class="form-label">Early Arrival Slot Tolerance</label>
          <ngb-timepicker appTimepickerFocus [spinners]="false" id="arrivalEarlyInMinutes" formControlName="arrivalEarlyInMinutes" data-test="edit-airport-early-arrival-slot-time-picker"></ngb-timepicker>
        </div>
        <div class="flex-basis-25">
          <label for="arrivalLateInMinutes" class="form-label">Late Arrival Slot Tolerance</label>
          <ngb-timepicker appTimepickerFocus [spinners]="false" id="arrivalLateInMinutes" formControlName="arrivalLateInMinutes" data-test="edit-airport-late-arrival-slot-time-picker"></ngb-timepicker>
        </div>
      </div>
    </app-settings-card>

    <app-settings-card sectionTitle="Curfew Times" *ngIf="curfewTimesFormGroup && this.airport" [formGroup]="curfewTimesFormGroup" data-test="curfew-card" addText="Add curfew times" (onAddClicked)="manageCurfewTimes()">
<!--      <div class="flex flex-column dark-blue gap-2">-->
<!--        <div class="form-check form-switch">-->
<!--          <label class="form-check-label dark-blue" for="curfewLocal">Local</label>-->
<!--          <input class="form-check-input" type="checkbox" role="switch" id="curfewLocal" formControlName="isUtc" data-test="edit-airport-curfew-is-utc-check">-->
<!--        </div>-->
<!--        <div class="flex justify-content-between align-items-end">-->
<!--          <div class="flex-basis-20">-->
<!--            <label class="form-label" for="d">From Date</label>-->
<!--            <div class="flex">-->
<!--              <input class="form-control light-blue-border dark-blue dp-minw" type="text" id="d" ngbDatepicker formControlName="validFrom" #d="ngbDatepicker" displayMonths="2" outsideDays="hidden" data-test="edit-airport-curfew-from-date-input"/>-->
<!--              <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar"-->
<!--                      [ngClass]="{'active': d.isOpen()}"-->
<!--                      (click)="d.open()" data-test="edit-airport-curfew-from-date-calendar-button"></button>-->
<!--            </div>-->
<!--          </div>-->


<!--          <div class="flex-basis-20">-->
<!--            <label class="form-label" for="d">To Date</label>-->
<!--            <div class="flex">-->
<!--              <input class="form-control light-blue-border dark-blue dp-minw" type="text" id="d2" ngbDatepicker formControlName="validTo" #d2="ngbDatepicker" displayMonths="2" outsideDays="hidden" data-test="edit-airport-curfew-to-date-input"/>-->
<!--              <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar"-->
<!--                      [ngClass]="{'active': d2.isOpen()}"-->
<!--                      (click)="d2.open()" data-test="edit-airport-curfew-to-date-calendar-button"></button>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="flex-basis-20">-->
<!--            <label for="openingTime" class="form-label">Opening Time</label>-->
<!--            <ngb-timepicker appTimepickerFocus [spinners]="false" id="openingTime" formControlName="openingTime" data-test="edit-airport-curfew-opening-time-picker"></ngb-timepicker>-->
<!--          </div>-->

<!--          <div class="flex-basis-20">-->
<!--            <label for="closingTime" class="form-label">Closing Time</label>-->
<!--            <ngb-timepicker appTimepickerFocus [spinners]="false" id="closingTime" formControlName="closingTime" data-test="edit-airport-curfew-closing-time-picker"></ngb-timepicker>-->
<!--          </div>-->

<!--        </div>-->
<!--      </div>-->
      <div class="overflow-auto avitium-scrollbar">
        <table class="table table-striped dark-blue curfew-table" [formGroup]="curfewTimesFormGroup">
          <thead>
          <tr>
            <th scope="col">From</th>
            <th scope="col">To</th>
            <th scope="col">Mon</th>
            <th scope="col">Tue</th>
            <th scope="col">Wed</th>
            <th scope="col">Thu</th>
            <th scope="col">Fri</th>
            <th scope="col">Sat</th>
            <th scope="col">Sun</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let dateRange of curfewTimesFormGroup.controls | keyvalue">
            <td>{{ dateRange.value.value?.from }}</td>
            <td>{{ !dateRange.value.value?.to ? '-' : dateRange.value.value?.to }}</td>
            <td>{{ dateRange.value.value.mon ? dateRange.value.value.mon?.curfewStartTimeLocal + ' - ' + dateRange.value.value.mon?.curfewEndTimeLocal : '-- : --' }}{{dateRange.value.value.mon?.isUtc ? 'z' : ''}}</td>
            <td>{{ dateRange.value.value.tue ? dateRange.value.value.tue?.curfewStartTimeLocal + ' - ' + dateRange.value.value.tue?.curfewEndTimeLocal : '-- : --' }}{{dateRange.value.value.tue?.isUtc ? 'z' : ''}}</td>
            <td>{{ dateRange.value.value.wed ? dateRange.value.value.wed?.curfewStartTimeLocal + ' - ' + dateRange.value.value.wed?.curfewEndTimeLocal : '-- : --' }}{{dateRange.value.value.wed?.isUtc ? 'z' : ''}}</td>
            <td>{{ dateRange.value.value.thu ? dateRange.value.value.thu?.curfewStartTimeLocal + ' - ' + dateRange.value.value.thu?.curfewEndTimeLocal : '-- : --' }}{{dateRange.value.value.thu?.isUtc ? 'z' : ''}}</td>
            <td>{{ dateRange.value.value.fri ? dateRange.value.value.fri?.curfewStartTimeLocal + ' - ' + dateRange.value.value.fri?.curfewEndTimeLocal : '-- : --' }}{{dateRange.value.value.fri?.isUtc ? 'z' : ''}}</td>
            <td>{{ dateRange.value.value.sat ? dateRange.value.value.sat?.curfewStartTimeLocal + ' - ' + dateRange.value.value.sat?.curfewEndTimeLocal : '-- : --' }}{{dateRange.value.value.sat?.isUtc ? 'z' : ''}}</td>
            <td>{{ dateRange.value.value.sun ? dateRange.value.value.sun?.curfewStartTimeLocal + ' - ' + dateRange.value.value.sun?.curfewEndTimeLocal : '-- : --' }}{{dateRange.value.value.sun?.isUtc ? 'z' : ''}}</td>
            <td>
              <div class="flex justify-content-end gap-1">
                <img class="me-1 cursor-pointer" src="content/images/icons/pencil.svg" (click)="manageCurfewTimes(dateRange.value.value)" alt="Edit" data-test="edit-curfew-button">
                <img class="cursor-pointer" src="content/images/icons/trash3.svg" (click)="deleteCurfewTimes(dateRange.value.value)" alt="Delete"  data-test="delete-curfew-button">
              </div>
            </td>
<!--            <td class="text-center">Inbound {{flightServiceType?.code}}</td>-->
<!--            <td *ngFor="let serviceType of flightServiceTypes" [attr.data-test]="'outbound-' + serviceType?.code">-->
<!--              {{ DOMDOM?.[activeAcTypeId]?.[flightServiceType?.id]?.[serviceType?.id]?.timeInMinutes | timeFromMinutes }}-->
<!--            </td>-->
          </tr>
          </tbody>
        </table>
      </div>
    </app-settings-card>

    <app-settings-card sectionTitle="Turnaround Times: Domestic to Domestic" addText="Edit minimum turnaround times" (onAddClicked)="editTurnaroundTimes()" *ngIf="this.airport" data-test="tt-domdom-card">
      <div class="flex flex-column dark-blue gap-3" *ngIf="DOMDOM[activeAcTypeId]">
        <div class="flex flex-column gap-2">
          <div class="ac-type">Aircraft ICAO Code</div>
          <div class="ac-select">
            <select class="form-control form-select light-blue-border" [(ngModel)]="selectedAcType" (ngModelChange)="selectedAcTypeChanged()" data-test="edit-airport-turnaround-times-domdom-ac-type-select">
              <option *ngFor="let acType of acTypes" [ngValue]="acType" [attr.data-test]="'ac-type-option-' + acType.alias">{{ acType.icao }}</option>
            </select>
          </div>
        </div>
        <div class="overflow-auto avitium-scrollbar">
          <table class="table table-striped turnaround-table dark-blue">
            <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col" *ngFor="let flightServiceType of flightServiceTypes">
                Outbound {{flightServiceType?.code}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let flightServiceType of flightServiceTypes" [attr.data-test]="'inbound-' + flightServiceType?.code">
               <td class="text-center">Inbound {{flightServiceType?.code}}</td>
              <td *ngFor="let serviceType of flightServiceTypes" [attr.data-test]="'outbound-' + serviceType?.code">
                {{ DOMDOM?.[activeAcTypeId]?.[flightServiceType?.id]?.[serviceType?.id]?.timeInMinutes | timeFromMinutes }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </app-settings-card>

    <app-settings-card sectionTitle="Turnaround Times: Domestic to International" addText="Edit minimum turnaround times" (onAddClicked)="editTurnaroundTimes()" *ngIf="this.airport" data-test="tt-domint-card">
      <div class="flex flex-column dark-blue gap-3" *ngIf="DOMINT[activeAcTypeId]">
        <div class="flex flex-column gap-2">
          <div class="ac-type">Aircraft ICAO Code</div>
          <div class="ac-select">
            <select class="form-control form-select light-blue-border" [(ngModel)]="selectedAcType" (ngModelChange)="selectedAcTypeChanged()" data-test="edit-airport-turnaround-times-domint-ac-type-select">
              <option *ngFor="let acType of acTypes" [ngValue]="acType" [attr.data-test]="'ac-type-option-' + acType.alias">{{ acType.icao }}</option>
            </select>
          </div>
        </div>

        <div class="overflow-auto avitium-scrollbar">
          <table class="table table-striped turnaround-table dark-blue">
            <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col" *ngFor="let flightServiceType of flightServiceTypes">
                Outbound {{flightServiceType?.code}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let flightServiceType of flightServiceTypes" [attr.data-test]="'inbound-' + flightServiceType?.code">
               <td class="text-center">Inbound {{flightServiceType?.code}}</td>
              <td *ngFor="let serviceType of flightServiceTypes" [attr.data-test]="'outbound-' + serviceType?.code">
                {{ DOMINT?.[activeAcTypeId]?.[flightServiceType?.id]?.[serviceType?.id]?.timeInMinutes | timeFromMinutes }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </app-settings-card>




    <app-settings-card sectionTitle="Turnaround Times: International to International" addText="Edit minimum turnaround times" (onAddClicked)="editTurnaroundTimes()" *ngIf="this.airport" data-test="tt-intint-card">
      <div class="flex flex-column dark-blue gap-3" *ngIf="INTINT[activeAcTypeId]">
        <div class="flex flex-column gap-2">
          <div class="ac-type">Aircraft ICAO Code</div>
          <div class="ac-select">
            <select class="form-control form-select light-blue-border" [(ngModel)]="selectedAcType" (ngModelChange)="selectedAcTypeChanged()" data-test="edit-airport-turnaround-times-intint-ac-type-select">
              <option *ngFor="let acType of acTypes" [ngValue]="acType" [attr.data-test]="'ac-type-option-' + acType.alias">{{ acType.icao }}</option>
            </select>
          </div>
        </div>

        <div class="overflow-auto avitium-scrollbar">
          <table class="table table-striped turnaround-table dark-blue">
            <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col" *ngFor="let flightServiceType of flightServiceTypes">
                Outbound {{flightServiceType?.code}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let flightServiceType of flightServiceTypes" [attr.data-test]="'inbound-' + flightServiceType?.code">
               <td class="text-center">Inbound {{flightServiceType?.code}}</td>
              <td *ngFor="let serviceType of flightServiceTypes" [attr.data-test]="'outbound-' + serviceType?.code">
                {{ INTINT?.[activeAcTypeId]?.[flightServiceType?.id]?.[serviceType?.id]?.timeInMinutes | timeFromMinutes }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </app-settings-card>

    <app-settings-card sectionTitle="Turnaround Times: International to Domestic" addText="Edit minimum turnaround times" (onAddClicked)="editTurnaroundTimes()" *ngIf="this.airport" data-test="tt-intdom-card">
      <div class="flex flex-column dark-blue gap-3" *ngIf="INTDOM[activeAcTypeId]">
        <div class="flex flex-column gap-2">
          <div class="ac-type">Aircraft ICAO Code</div>
          <div class="ac-select">
            <select class="form-control form-select light-blue-border" [(ngModel)]="selectedAcType" (ngModelChange)="selectedAcTypeChanged()" data-test="edit-airport-turnaround-times-intdom-ac-type-select">
              <option *ngFor="let acType of acTypes" [ngValue]="acType" [attr.data-test]="'ac-type-option-' + acType.alias">{{ acType.icao }}</option>
            </select>
          </div>
        </div>

        <div class="overflow-auto avitium-scrollbar">
          <table class="table table-striped turnaround-table dark-blue">
            <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col" *ngFor="let flightServiceType of flightServiceTypes">
                Outbound {{flightServiceType?.code}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let flightServiceType of flightServiceTypes" [attr.data-test]="'inbound-' + flightServiceType?.code">
               <td class="text-center">Inbound {{flightServiceType?.code}}</td>
              <td *ngFor="let serviceType of flightServiceTypes" [attr.data-test]="'outbound-' + serviceType?.code">
                {{ INTDOM?.[activeAcTypeId]?.[flightServiceType?.id]?.[serviceType?.id]?.timeInMinutes | timeFromMinutes }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </app-settings-card>
  </div>
</div>



</ng-container>
