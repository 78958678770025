<div class="settings-container mt-3">
  <div class="flex flex-column gap-3 light-blue-border p-3">
    <div class="flex justify-content-between">
      <div class="dark-blue sub-title flex gap-1">
        <div>{{ sectionTitle }}</div>
        <label *ngIf="informationTooltip" [ngbTooltip]="informationTooltip">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" class="bi bi-info-circle-fill" fill="#4285F6" viewBox="0 0 16 16">
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
        </label>
      </div>
      <div class="add-button cursor-pointer" (click)="onAddClicked.emit()" [ngbTooltip]="addTextTooltip" data-test="add-button">{{ addText }}</div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
