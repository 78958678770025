
    <div class="bg-white chat-selection vcard-container mx-3  light-blue-border">
      <div class="flex justify-content-center p-2 chats-bg" [ngClass]="{ 'inactive': !showContacts }">
        <div class="fw-normal mb-0 chats-text dark-blue">Chats</div>
      </div>
      <div class="flex justify-content-evenly mt-2" *ngIf="showContacts && flightStations?.length">
        <div class="form-check form-switch" *ngFor="let station of flightStations">
          <input class="form-check-input" type="checkbox" role="switch" id="{{station}}" (change)="stationSelected(station)" [checked]="selectedStations.includes(station)" [attr.data-test]="'small-chat-contact-station-' + station">
          <label class="form-check-label" for="{{station}}">{{ station }}</label>
        </div>
      </div>
      <div class="select-pair p-2" *ngIf="!showContacts">
        <div class="text-center m-0 select-pair-text dark-blue">{{ defaultText }}</div>
      </div>
      <div class="select-pair p-2" *ngIf="showContacts && !flightStations.length">
        <div class="text-center m-0 select-pair-text dark-blue ">There are no contacts available.</div>
      </div>
      <app-search-bar class="search-bar mt-2 mb-2" width="95%" [(text)]="searchText" *ngIf="showContacts && flightStations.length"></app-search-bar>


    </div>
    <ng-container *ngIf="showContacts">
      <app-vcard-list class="mx-3" *ngIf="!chatOpened" [refreshListener]="stationsChangedEvent" (openChatAction)="changeChatState(true, $event)" [selectedStations]="selectedStations" [flightStations]="flightStations" [searchText]="searchText"></app-vcard-list>
      <app-chat-area *ngIf="chatOpened" [user]="chatUser" (closeChatAction)="changeChatState(false, null)"></app-chat-area>
    </ng-container>

