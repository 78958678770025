<table class="table table-striped">
  <thead>
  <tr class="dark-blue">
    <ng-container *ngFor="let column of columns">
      <th scope="col" *ngIf="sortableColumns[column]; else defaultHeader" [sortable]="sortableColumns[column]" (sort)="onSort($event)"><div [ngbTooltip]="columnTooltips?.[column] || ''">{{ column }}</div></th>
      <ng-template #defaultHeader>
        <th scope="col"><div [ngbTooltip]="columnTooltips?.[column] || ''">{{ column }}</div></th>
      </ng-template>
    </ng-container>


    <th scope="col"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let dataElement of data | slice:0:maxLength; trackBy: trackByFn; index as i" class="dark-blue" [attr.data-test]="dataElement | testTag:testTagPipe">
    <td *ngFor="let varName of variableNames">
      <ng-container *ngTemplateOutlet="getTemplate(varName); context: { $implicit: dataElement[varName], obj: dataElement }">
      </ng-container>
      <ng-container *ngIf="!getTemplate(varName)">{{ dataElement[varName] }}</ng-container>


    </td>
    <td class="text-end gap-1">
      <img class="me-1" [ngClass]="{ 'opacity-50': !canEdit || canEditPropertyCheck ? !dataElement[canEditPropertyCheck] : false, 'cursor-pointer': canEdit && (canEditPropertyCheck ? dataElement[canEditPropertyCheck] : true) }" src="content/images/icons/pencil.svg" (click)="emitEditClicked(dataElement)" alt="Edit" *ngIf="showEdit" data-test="edit-button">
      <img [ngClass]="{ 'opacity-50': !canDelete, 'cursor-pointer': canDelete }" src="content/images/icons/trash3.svg" (click)="emitDeleteClicked(dataElement)" alt="Delete" *ngIf="showDelete"  data-test="delete-button">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#002060" class="bi bi-file-text cursor-pointer" viewBox="0 0 16 16" *ngIf="showView" (click)="emitViewClicked(dataElement)">
        <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
      </svg>
    </td>
  </tr>
  </tbody>
</table>
<div class="text-center cursor-pointer show-more" (click)="showMore()" *ngIf="data?.length > maxLength" data-test="show-more-button">Show more</div>
