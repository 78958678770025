import { Component } from '@angular/core';
import { IAcRegistration } from '../../../shared/models/ac-registration.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { DeleteModalComponent } from '../../../shared/components/delete-modal/delete-modal.component';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { IAcType } from '../../../shared/models/ac-type.model';
import { AcRegistrationsService } from '../../../services/ac-registrations.service';
import { ToastService } from '../../../services/toast.service';
import { ISeatingConfigurationModel } from '../../../shared/models/seating-configuration.model';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsComponent } from '../settings.component';
import { forkJoin } from 'rxjs';
import { GeneralSettingsService } from '../../../services/general-settings.service';
import { AcTypesService } from '../../../services/ac-types.service';
import { IAirlineDesignator } from '../../../shared/models/airline-designator.model';
import { AcRegistrationsTestTagPipe } from '../../../shared/pipes/ac-registrations-test-tag.pipe';

@Component({
  selector: 'app-settings-ac-registration-management',
  templateUrl: './settings-ac-registration-mgmt.component.html',
  styleUrls: ['./settings-ac-registration-mgmt.component.scss']
})
export class SettingsAcRegistrationMgmtComponent {

  acRegistrations: IAcRegistration[];
  acTypes: IAcType[];
  airlineDesignators: IAirlineDesignator[];
  pageSize = 25;

  constructor(private acTypesService: AcTypesService, private generalSettingsService: GeneralSettingsService, private modalService: NgbModal, private fns: AngularFireFunctions, public acRegistrationService: AcRegistrationsService, private toastService: ToastService, private router: Router, private route: ActivatedRoute, public parent: SettingsComponent) {
    forkJoin([this.acRegistrationService.fetchAcRegistrations(), this.acTypesService.fetchAcTypes(), this.generalSettingsService.getAirlineDesignators()]).subscribe((results) => {
      this.acRegistrations = results[0];
      this.acTypes = results[1];
      this.airlineDesignators = results[2];
    });
  }

  getApplicableSeatingConfigurations(seats: ISeatingConfigurationModel[]) {
    return seats.map((item) => {
      return item.code + item.description
    }).join(' / ');
  }

  getAirlineDesignator(seats: ISeatingConfigurationModel[]): string {
    return this.airlineDesignators.find((des) => des.id === seats[0]?.operatedBy)?.description || '-'
  }

  doAdd() {
    this.router.navigate(['add'], {relativeTo: this.route });
  }

  doEdit(item: number) {
    this.router.navigate([item], {relativeTo: this.route });
  }

  doDelete(item: number) {
    if (!item || !this.acRegistrations || this.acRegistrations.findIndex((reg => reg.id === item)) === -1) {
      return;
    }
    const acReg = this.acRegistrations.find((reg) => reg.id === item);
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.term = 'A/C Registration ' + acReg.registration;
    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.acRegistrationService.deleteAcRegistration(+item).subscribe(() => {
        this.toastService.showSuccess('AC Registration has been deleted');
        this.acRegistrationService.fetchAcRegistrations().subscribe((results) => {
          this.acRegistrations = results;
        });
      });
    });
  }

  getAcTypeFromId(acTypeId: number) {
    return this.acTypes.find((acType) => acType.id === acTypeId);
  }

  public readonly AcRegistrationsTestTagPipe = AcRegistrationsTestTagPipe;
}
