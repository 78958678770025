// Function to check if the given date is within the DST period
import * as dayjs from 'dayjs';
import {toTitleCase} from './text.utils';
import {ITimezone} from "../models/timezone.model";

const weekdaysMap: Record<string, number> = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
};

function getMonthIdFromString(value: string): number {
  return dayjs(value.substring(0, 1).toUpperCase(), value.substring(1).toLowerCase(), 'MMM').month();
}

export function getDSTPeriod(data: ITimezone, year?: number): { dstStart: dayjs.Dayjs; dstEnd: dayjs.Dayjs } {
  if (!year) {
    year = dayjs.utc().year();
  }
  let dstStart = dayjs
    .utc()
    .year(year)
    .month(getMonthIdFromString(toTitleCase(data.dstStartMonth)))
    .date(1);
  if (data.dstStartWeekOfMonth === 'Last') {
    dstStart = dstStart.endOf('month');
    for (let i = 0; i < 7; i++) {
      if (dstStart.weekday() === weekdaysMap[toTitleCase(data.dstStartWeekday)]) {
        break;
      }
      dstStart = dstStart.subtract(1, 'day');
    }
  } else {
    dstStart = dstStart.add(daysToAdd(data.dstStartWeekOfMonth), 'days');
    for (let i = 0; i < 7; i++) {
      if (dstStart.weekday() === weekdaysMap[toTitleCase(data.dstStartWeekday)]) {
        break;
      }
      dstStart = dstStart.add(1, 'day');
    }
  }

  let dstEnd = dayjs
    .utc()
    .year(year)
    .month(getMonthIdFromString(toTitleCase(data.dstEndMonth)))
    .date(1);
  if (dstEnd.isBefore(dstStart)) {
    dstEnd = dstEnd.add(1, 'year');
  }
  if (data.dstEndWeekOfMonth === 'Last') {
    dstEnd = dstEnd.endOf('month');
    for (let i = 0; i < 7; i++) {
      if (dstEnd.weekday() === weekdaysMap[toTitleCase(data.dstEndWeekday)]) {
        break;
      }
      dstEnd = dstEnd.subtract(1, 'day');
    }
  } else {
    dstEnd = dstEnd.add(daysToAdd(data.dstEndWeekOfMonth), 'days');
    for (let i = 0; i < 7; i++) {
      if (dstEnd.weekday() === weekdaysMap[toTitleCase(data.dstEndWeekday)]) {
        break;
      }
      dstEnd = dstEnd.add(1, 'day');
    }
  }
  return { dstStart, dstEnd };
}

function daysToAdd(weekOfMonth: string): number {
  switch (weekOfMonth) {
    case 'First':
      return 0;
    case 'Second':
      return 7;
    case 'Third':
      return 14;
    case 'Last':
      return 21;
    default:
      return 0;
  }
}


export function getCurrentTimezoneOffset(timezoneData: ITimezone): number {
  const inDst = isInDst(timezoneData);
  const timeOffset = inDst ? timezoneData.utcWithDst.padStart(4, '0') : timezoneData.utcWithoutDst.padStart(4, '0');
  const hh: number = +timeOffset.substring(0, 2);
  const mm: number = +timeOffset.substring(2, 4);
  return hh * 60 + mm;
}

export function isInDst(timezone: ITimezone): boolean {
  const date = dayjs.utc();
  const { dstStart, dstEnd } = getDSTPeriod(timezone, date.year());
  return date.isBetween(dstStart, dstEnd);
}
