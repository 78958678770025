<ng-container *ngIf="form">
  <form class="children-fs16" [formGroup]="form">
    <div class="row mb-4">
      <div class="col-6 align-self-center fw-bold dark-blue">
        {{leg?.airlineDesignator}}{{leg?.flightNumber}} | {{leg?.std | date:'d MMM' | uppercase }} | {{leg?.departureStation}} {{leg?.std | date:'HH:mm':'+00:00'}} - {{leg?.arrivalStation}} {{leg?.sta | date:'HH:mm':'+00:00'}} | {{leg?.acType}}
      </div>
      <div class="col-6 flex justify-content-end">
        <button class="btn btn-danger divert" [disabled]="!pair.arrivalLeg.takeOffTime" (click)="divertFlight(leg)" data-test="change-arrival-flight-details-divert-flight-button">Divert Flight</button>
      </div>
    </div>
    <div class="row pb-3">
      <div class="col-6 left flex flex-column gap-3">
        <div class="row">
          <div class="col-7 form-field-header">
            Estimated Time of Departure (ETD)
          </div>
          <div class="col-5">
            <div class="input-group">
              <input class="form-control light-blue-border" [placeholder]="dateFormat"
                     name="dp" formControlName="etdDate" ngbDatepicker #d="ngbDatepicker" data-test="change-arrival-flight-details-etd-date-input">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar light-blue-border"
                        (click)="d.toggle()" type="button" [disabled]="form.get('etdDate').disabled" data-test="change-arrival-flight-details-etd-date-calendar-button"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-7"></div>
          <div class="col-5">
            <ngb-timepicker appTimepickerFocus formControlName="etdTime" [spinners]="false" [disabled]="true" name="etdTime" data-test="change-arrival-flight-details-etd-time-picker"></ngb-timepicker>
          </div>
        </div>
        <div class="h-divider"></div>
        <div class="row">
          <div class="col-7 form-field-header">
            Actual Time of Departure (ATD)
          </div>
          <div class="col-5">
            <div class="input-group">
              <input [disabled]="disabled" class="form-control light-blue-border" [placeholder]="dateFormat"
                     name="dp" formControlName="atdDate" ngbDatepicker #d2="ngbDatepicker" (ngModelChange)="localCalculateETA()" data-test="change-arrival-flight-atd-date-input">
              <div class="input-group-append">
                <button [disabled]="disabled" class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar light-blue-border"
                        (click)="d2.toggle()" type="button" data-test="change-arrival-flight-details-atd-calendar-button"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-7"></div>
          <div class="col-5">
            <ngb-timepicker appTimepickerFocus formControlName="atdTime" [readonlyInputs]="disabled" [spinners]="false" name="atdTime" (ngModelChange)="localCalculateETA()" data-test="change-arrival-flight-details-atd-time-picker"></ngb-timepicker>
          </div>
        </div>
        <div class="row">
          <div class="col-7 form-field-header">
            Take Off Time
          </div>
          <div class="col-5">
            <ngb-timepicker appTimepickerFocus formControlName="totTime" [readonlyInputs]="disabled" [spinners]="false" name="takeoffTime" (ngModelChange)="localCalculateETA()" data-test="change-arrival-flight-details-tot-time-picker"></ngb-timepicker>
          </div>
        </div>
        <div class="row ">
          <div class="col-7 form-field-header">
            Estimated Time of Arrival (ETA)
          </div>
          <div class="col-5">
            <ngb-timepicker appTimepickerFocus formControlName="etaTime" [readonlyInputs]="disabled" [spinners]="false" name="etaTime" data-test="change-arrival-flight-details-eta-time-picker"></ngb-timepicker>
          </div>
        </div>
        <div class="row ">
          <div class="col-7 form-field-header">
            Landing Time
          </div>
          <div class="col-5">
            <ngb-timepicker appTimepickerFocus formControlName="lndTime" [spinners]="false" name="landTime" data-test="change-arrival-flight-details-lnd-time-picker"></ngb-timepicker>
          </div>
        </div>
        <div class="row ">
          <div class="col-7 form-field-header">
            Actual Time of Arrival (ATA)
          </div>
          <div class="col-5">
            <ngb-timepicker appTimepickerFocus formControlName="ataTime" [spinners]="false" name="ataTime" data-test="change-arrival-flight-details-ata-time-picker"></ngb-timepicker>
          </div>
        </div>
        <div class="h-divider"></div>
        <div class="row">
          <div class="col-7 form-field-header">
            Passenger Gate
          </div>
          <div class="col-5">
            <div class="">
              <input type="text" class="form-control light-blue-border" id="arrival-flight-passenger-gate" placeholder=""
                     formControlName="gate" data-test="change-arrival-flight-details-gate-input">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-7 form-field-header">
            Aircraft Stand / Gate
          </div>
          <div class="col-5">
            <div class="">
              <input type="text" class="form-control light-blue-border" id="arrival-flight-aircraft-stand" placeholder=""
                     formControlName="stand" data-test="change-arrival-flight-details-stand-input">
            </div>
          </div>
        </div>
        <div class="h-divider"></div>
        <div class="row">
          <div class="col-7 form-field-header">
            Next Information
          </div>
          <div class="col-5 flex">
            <ngb-timepicker appTimepickerFocus formControlName="niTime" [readonlyInputs]="true" [spinners]="false" name="ni" (ngModelChange)="localCalculateETA()" data-test="change-arrival-flight-details-ni-time-picker"></ngb-timepicker>
          </div>
        </div>
      </div>
      <!-- Left side end -->
      <div class="col-6 right flex flex-column gap-3">
        <div class="row">
          <div class="col-7 form-field-header">
            Calculated Take Off Time (CTOT)
          </div>
          <div class="col-5 flex justify-content-end">
            <ngb-timepicker appTimepickerFocus formControlName="ctotTime" [spinners]="false" name="ctotTime" [readonlyInputs]="true" data-test="change-arrival-flight-details-ctot-time-picker"></ngb-timepicker>
          </div>
        </div>
        <div class="h-divider"></div>
        <div class="row">
          <div class="col-6 flex flex-column gap-3">
            <div class="row">
              <div class="form-field-header">
                Departure Delays
              </div>
            </div>
            <form class="flex gap-2" [formGroup]="delay.value"
                  *ngFor="let delay of departureDelayListNew | keyvalue; let i = index;">
              <div class="delay-code-select">
                <ng-select formControlName="delayCode" [attr.data-test]="'change-arrival-flight-details-departure-delay-code-' + i">
                  <ng-option *ngFor="let code of delayCodes" [value]="code.id" >{{code.code}} {{ code.description }}</ng-option>
                  <ng-template ng-option-tmp let-item="item">
                    <span class="ng-option-label" [attr.data-test]="'delay-code-' + item">{{ delayCodesKV[item]?.code }} {{ delayCodesKV[item]?.description }}</span>
                  </ng-template>
                </ng-select>
              </div>
              <div class="delay-time-input">
                <app-time-input formControlName="time" (focusLost)="getRemainingDelay()" [attr.data-test]="'change-arrival-flight-details-departure-delay-time-' + i"></app-time-input>
              </div>
            </form>
          </div>
          <div class="col-6 flex flex-column gap-3">
            <div class="row">
              <div class="form-field-header">
                Arrival Delays
              </div>
            </div>
            <form class="flex gap-2" [formGroup]="delay.value"
                  *ngFor="let delay of arrivalDelayListNew | keyvalue; let i = index">
              <div class="delay-code-select">
                <ng-select id="arrival-flight-arrival-delay-code" formControlName="delayCode" [attr.data-test]="'change-arrival-flight-details-arrival-delay-code-' + i">
                  <ng-option *ngFor="let code of delayCodes" [value]="code.id" >{{code.code}} {{ code.description }}</ng-option>
                  <ng-template ng-option-tmp let-item="item">
                    <span class="ng-option-label" [attr.data-test]="'delay-code-' + item">{{ delayCodesKV[item]?.code }} {{ delayCodesKV[item]?.description }}</span>
                  </ng-template>
                </ng-select>
              </div>
              <div class="delay-time-input">
                <app-time-input formControlName="time" (focusLost)="getRemainingDelay()" [attr.data-test]="'change-arrival-flight-details-arrival-delay-time-' + i"></app-time-input>
              </div>
            </form>
            <div class="row">
              <div class="delay-info-box">
                <ng-container *ngIf="maxArrivalDelayMinutes > 0 && currentArrivalDelayMinutes !== maxArrivalDelayMinutes">
                  <div class="row">
                    <span class="delay-error">The delay minutes you entered ({{currentArrivalDelayMinutes}}) do not match the current flight delay time ({{ maxArrivalDelayMinutes }}) </span>
                  </div>
                </ng-container>
                <ng-container *ngIf="maxDepartureDelayMinutes > 0 && currentDepartureDelayMinutes !== maxDepartureDelayMinutes">
                  <div class="row">
                    <span class="delay-error">The delay minutes you entered ({{currentDepartureDelayMinutes}}) do not match the current flight delay time ({{ maxDepartureDelayMinutes }}) </span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="h-divider"></div>
        <div class="passengers-container">
          <div class="estimated-passengers-area">
            <div class="estimated-passengers flex flex-column gap-2" formGroupName="estimatedPassengers">
              <div class="form-field-header align-self-start">
                Estimated Passengers
              </div>
              <div class="flex justify-content-between align-items-center text-gray" *ngFor="let pClass of passengerClasses" [formGroupName]="pClass.code">
                <div>{{ pClass.code }} <span>({{seatingConfigurations?.[pClass.code] || '0'}})</span></div>
                <input type="text" class="form-control light-blue-border passenger-input" id="estimated-passengers-class-{{pClass.code}}" placeholder="" formControlName="description"
                       [attr.data-test]="'estimated-passengers-class-' + pClass.code">
              </div>
            </div>
          </div>
          <div class="actual-passengers-area justify-self-center">
            <div class="actual-passengers flex flex-column gap-2" formGroupName="actualPassengers">
              <div class="form-field-header align-self-start">
                Actual Passengers
              </div>
              <div class="flex justify-content-between align-items-center text-gray" *ngFor="let pClass of passengerClasses" [formGroupName]="pClass.code">
                <div>{{ pClass.code }} <span>({{seatingConfigurations?.[pClass.code] || '0'}})</span></div>
                <input type="text" class="form-control light-blue-border passenger-input" id="actual-passengers-class-{{pClass.code}}" placeholder=""
                       [attr.data-test]="'actual-passengers-class-' + pClass.code" formControlName="description">
              </div>
            </div>
          </div>
          <div class="passenger-categories-area">
            <div class="flex flex-column gap-2 passenger-categories  skip-invalid-border" formGroupName="categories">
              <div class="form-field-header">
                Passenger Categories
              </div>
              <div class="flex justify-content-between align-items-center text-gray  skip-invalid-border" *ngFor="let category of passengerCategories" [formGroupName]="category?.code">
                <div>{{ category.description }}</div>
                <input type="text" class="form-control light-blue-border passenger-input" placeholder=""
                       [attr.data-test]="category.description + '-passenger-category-input'" formControlName="amount">
              </div>
            </div>

          </div>
          <div class="connecting-passengers-text">
            <div class="connecting-passengers flex flex-column gap-2">
              <div class="form-field-header align-self-start">
                Connecting Passengers
              </div>
              <div class="flex align-items-center text-gray connecting-passenger-row">
                <div>EW0656 / 14 ATH Y12</div>
              </div>
              <div class="flex align-items-center text-gray connecting-passenger-row">
                <div>EW0858 / 14 BER C1 Y22</div>
              </div>

            </div>
          </div>
          <div class="connecting-passengers-input">
            <div class="flex flex-column gap-2">
              <div class="spacer"></div>
              <div class="flex gap-2">
                <input type="text" class="form-control light-blue-border passenger-input" placeholder="" value="00">
                <input type="text" class="form-control light-blue-border passenger-input" placeholder="" value="45">
              </div>
              <div class="flex gap-2">
                <input type="text" class="form-control light-blue-border passenger-input" placeholder="" value="01">
                <input type="text" class="form-control light-blue-border passenger-input" placeholder="" value="25">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="h-divider w-100"></div>
    <div class="row">
      <div class="col-12">
        <app-restore-cancel-row [showSpinner]="isBusy" (restoreClicked)="restoreForm()" (saveClicked)="saveForm()" [isSaveDisabled]="form.invalid || maxDepartureDelayMinutes > 0 && currentDepartureDelayMinutes !== maxDepartureDelayMinutes || maxArrivalDelayMinutes > 0 && currentArrivalDelayMinutes !== maxArrivalDelayMinutes"></app-restore-cancel-row>
      </div>
    </div>
  </form>

</ng-container>
