<ng-container *ngIf="tableData">
<app-settings-header [text]="currentGse ? 'Edit ' + title : 'Add Process & GSE'" [backButton]="true" backButtonUrl=".." [showSaveButton]="true" (onSaveClicked)="saveClicked()" [saveButtonDisabled]="form.invalid || isBusy" [spinner]="isBusy"></app-settings-header>
<div class="settings-container flex flex-column avitium-scrollbar">
<app-settings-card sectionTitle="Standard Ground Service Equipment (GSE)" data-test="gse-card">
  <div class="flex flex-column gap-3 dark-blue skip-invalid-border" [formGroup]="form">
    <div class="flex justify-content-between align-items-end">
      <div class="flex-basis-30">
        <label for="processName" class="form-label">Process Name</label>
        <input type="text" class="form-control light-blue-border" id="processName" formControlName="processName" autofocus [readonly]="this.currentGse" data-test="gse-edit-first-level-process-name-input">
      </div>
      <div class="flex-basis-30">
        <label for="maxTime" class="form-label">Max Process Time (minutes)</label>
        <input type="number" class="form-control light-blue-border" id="maxTime" formControlName="maxTime" data-test="gse-edit-first-level-max-time-input">
      </div>
      <div class="flex-basis-30">
        <div class="form-check form-switch">
          <label class="form-check-label dark-blue" for="gseRequired">GSE Required</label>
          <input class="form-check-input" type="checkbox" role="switch" id="gseRequired" formControlName="gseRequired" data-test="gse-edit-first-level-gse-required-switch">
        </div>
      </div>
    </div>

    <div class="flex justify-content-between align-items-end">
      <div class="flex-basis-30">
        <ng-container *ngIf="form.value.gseRequired">
          <label for="gseType" class="form-label">GSE Type</label>
          <select class="form-control form-select" id="gseType" formControlName="gseType" data-test="gse-edit-first-level-gse-type-select">
            <option *ngFor="let gse of definedGses | keyvalue" [value]="gse?.value.equipmentIdentifier" selected [attr.data-test]="'sla-gse-' + gse.value.title">{{gse?.value.title}}</option>
          </select>
        </ng-container>
      </div>
      <div class="flex-basis-30">
        <ng-container *ngIf="form.value.gseRequired && form.value.gseType">
          <i [class]="'demo-icon vertical-middle align-self-center ' + getIconClass(form.value.gseType) + ' fs-30'"></i>
        </ng-container>
      </div>
      <div class="flex-basis-30 flex gap-3">
        <div class="form-check form-switch">
          <label class="form-check-label dark-blue" for="isSla">SLA</label>
          <input class="form-check-input" type="checkbox" role="switch" id="isSla" formControlName="isSla" data-test="gse-edit-first-level-is-sla-switch">
        </div>
        <span *ngIf="form.value.isSla" class="sla-indicator sla align-self-center">SLA</span>
        <span *ngIf="!form.value.isSla" class="sla-indicator non-sla align-self-center">Non-SLA</span>
      </div>
    </div>

  </div>
</app-settings-card>

<app-settings-card sectionTitle="Airports" *ngIf="currentGse" data-test="airports-card">
  <div class="flex flex-column gap-1">
    <app-search-bar [(text)]="searchText" (textChange)="filterAirports()" width="250px"></app-search-bar>
    <app-settings-data-table [testTagPipe]="AirportsTestTagPipe" class="mt-3" [columns]="['IATA Code', 'SLA / Non-SLA', 'GSE Type', 'Process Time', 'Applicable', 'Exceptions']" [data]="tableData" [variableNames]="['iata', 'sla', 'gseType', 'processTime', 'applicable', 'exceptions']" [canDelete]="false" (onEditClicked)="onEdit($event)" emitProperty="airportId">
      <ng-template propName="iata" let-value let-obj="obj">
        <ng-container *ngIf="obj.applicable === 'No'; else defaultTemp">
          <del>{{ value }}</del>
        </ng-container>
        <ng-template #defaultTemp>{{ value }}</ng-template>
      </ng-template>

      <ng-template propName="sla" let-value let-obj="obj">
        <ng-container *ngIf="obj.applicable === 'No'; else defaultTemp">
          <del>{{ value }}</del>
        </ng-container>
        <ng-template #defaultTemp>{{ value }}</ng-template>
      </ng-template>

      <ng-template propName="gseType" let-value let-obj="obj">
        <ng-container *ngIf="obj.applicable === 'No'; else defaultTemp">
          <del>{{ definedGses[value.toUpperCase()]?.title }}</del>
        </ng-container>
        <ng-template #defaultTemp>{{ definedGses[value.toUpperCase()]?.title }}</ng-template>
      </ng-template>

      <ng-template propName="processTime" let-value let-obj="obj">
        <ng-container *ngIf="obj.applicable === 'No'; else defaultTemp">
          <del>{{ value }}</del>
        </ng-container>
        <ng-template #defaultTemp>{{ value }}</ng-template>
      </ng-template>

      <ng-template propName="exceptions" let-value let-obj="obj">
        <ng-container *ngIf="obj.applicable === 'No'; else defaultTemp">
          <del>{{ value }}</del>
        </ng-container>
        <ng-template #defaultTemp>{{ value }}</ng-template>
      </ng-template>
    </app-settings-data-table>
  </div>
</app-settings-card>

</div>
</ng-container>
