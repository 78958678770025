import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TimeFromMinutesPipe } from '../../../pipes/time-from-minutes.pipe';
import { ISimpleData } from '../../../models/simpleData.model';

export class FlightsChangeFlightDetailsArrivalDepartureFlightFormBuilder {

  static constructForm(
    fb: FormBuilder,
    timeFromMinutesPipe: TimeFromMinutesPipe,
    passengerClassesArray: ISimpleData[] = [],
    passengerCategoriesArray: ISimpleData[] = [],
  ): FormGroup {
    console.log('passenger categories:', passengerCategoriesArray);

    const actualPassengersFormGroup = new FormGroup({});
    const estimatedPassengersFormGroup = new FormGroup({});

    passengerClassesArray.forEach((item: ISimpleData) => {
      if (item?.code) {
        actualPassengersFormGroup.addControl(item.code, new FormGroup({
          code: new FormControl(item.code, Validators.required),
          description: new FormControl()
        }));
        estimatedPassengersFormGroup.addControl(item.code, new FormGroup({
          code: new FormControl(item.code, Validators.required),
          description: new FormControl()
        }));
      }
    });

    const passengerCategoriesFormGroup = new FormGroup({});
    passengerCategoriesArray.forEach((item: ISimpleData) => {
      if (item?.code) {
        passengerCategoriesFormGroup.addControl(item.code, new FormGroup({
          code: new FormControl(item.code, [Validators.required, Validators.pattern(/^[a-zA-Z0-9]+$/), Validators.maxLength(5)]),
          amount: new FormControl(null, Validators.pattern(/^\d+$/))
        }));
      }
    });

    return fb.group({
      etdDate: [{value: null, disabled: true}],
      etdTime: [{value: null, disabled: true}, Validators.pattern('\\d{2}[:]\\d{2}')],
      atdDate: [null],
      atdTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      totTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      etaTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      lndTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      ataTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      ctotTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      actualPassengers: actualPassengersFormGroup,
      estimatedPassengers: estimatedPassengersFormGroup,
      gate: [null],
      stand: [null],
      niTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      departureDelays: new FormGroup({}),
      arrivalDelays: new FormGroup({}),
      arrivalDelaysNew: new FormGroup({
        arrival1: new FormGroup({
          delayCode: new FormControl(null),
          time: new FormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
        arrival2: new FormGroup({
          delayCode: new FormControl(null),
          time: new FormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        })
      }),
      departureDelaysNew: new FormGroup({
        departure1: new FormGroup({
          delayCode: new FormControl(null),
          time: new FormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
        departure2: new FormGroup({
          delayCode: new FormControl(null),
          time: new FormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
        departure3: new FormGroup({
          delayCode: new FormControl(null),
          time: new FormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
        departure4: new FormGroup({
          delayCode: new FormControl(null),
          time: new FormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
      }),
      categories: passengerCategoriesFormGroup,
    });
  }
}
