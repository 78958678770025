<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()" data-test="edit-user-close-x-button">
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="container">
      <div class="row">
        <div class="col-6">
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                Firstname*
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-user-firstname" placeholder="" formControlName="firstname" data-test="edit-user-firstname-input">
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                Lastname*
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-user-lastname" placeholder="" formControlName="lastname" data-test="edit-user-lastname-input">
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                Username
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-user-username" placeholder="" formControlName="username" data-test="edit-user-username-input">
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                Email address*
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="email" class="form-control" id="settings-user-email" placeholder="" formControlName="email" data-test="edit-user-email-input">
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                Airline Designator*
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <select class="form-control form-select" id="settings-user-airline-designator" formControlName="airlineDesignatorId" data-test="edit-user-airline-designator-select">
                    <ng-container *ngFor="let loc of airlineDesignators">
                      <option [ngValue]="loc?.id">{{loc?.code}} {{loc?.description}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 border-start">
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                Role*
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <div class="mb-3">
                    <select class="form-control form-select" id="settings-user-role" formControlName="role" data-test="edit-user-role-select">
                      <ng-container *ngFor="let role of userRoleObject.LIST">
                        <option [value]="role">{{role | i18nSelect: userRoleObject.DISPLAY_TITLE_MAP}}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                Country
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-user-country" placeholder="" formControlName="country" [ngbTypeahead]="searchCountry" (blur)="onCountryBlur()" data-test="edit-user-country-input">
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                Location*
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <div class="mb-3">
                    <select class="form-control form-select" id="settings-user-location" formControlName="location" data-test="edit-user-location-select">
                      <ng-container *ngFor="let loc of airportContainer">
                        <option [value]="loc.iata" [attr.data-test]="'add-edit-user-' + loc.iata">{{loc?.iata}}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                Phone number
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-user-phone" placeholder="" formControlName="phone" data-test="edit-user-phone-input">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()" data-test="edit-user-cancel-button">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          [disabled]="form?.disabled || form?.invalid || isBusy" data-test="edit-user-save-button">
    {{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
