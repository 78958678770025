export class AlertConstants {
  static unpairedSectorDepartureWithinHours = 4;
  static IDS = {
    1: 'UNPAIRED_SECTOR',
    2: 'AIRPORT_CURFEW_TIME',
    3: 'INADEQUATE_TURNAROUND_TIME',
    4: 'NEW_INFORMATION_ISSUED',
    5: 'TURNAROUND_NOT_STARTED',
    6: 'REQUEST_FOR_EXTENSION',
    7: 'UNSUCCESSFUL_MESSAGE',
    8: 'NO_ACTUAL_TIMES_ON_SECTOR',
    9: 'OVERLAPPING_SECTORS',
    10: 'CTOT_VIOLATION',
    11: 'EARLY_DEPARTURE',
    12: 'AIRCRAFT_RETURN_TO_RAMP',
    13: 'AIRCRAFT_RETURN_FROM_AIRBORNE',
    14: 'AIRCRAFT_DIVERSION',
    17: 'LATE_ONGOING_PROCESS',
    18: 'MVT_ACARS_DIFFERENCE_IN_ATD',
    19: 'MVT_ACARS_DIFFERENCE_IN_ATA',
    20: 'MVT_ACARS_DIFFERENCE_IN_LND',
    21: 'MVT_ACARS_DIFFERENCE_IN_TOT',
    22: 'ASSIGN_RAMP_AGENT',
    23: 'ASSIGN_RAMP_AGENT_CRITICAL',
  };

  static STR_TO_ID = {
    UNPAIRED_SECTOR: 1,
    AIRPORT_CURFEW_TIME: 2,
    INADEQUATE_TURNAROUND_TIME: 3,
    NEW_INFORMATION_ISSUED: 4,
    TURNAROUND_NOT_STARTED: 5,
    REQUEST_FOR_EXTENSION: 6,
    UNSUCCESSFUL_MESSAGE: 7,
    NO_ACTUAL_TIMES_ON_SECTOR: 8,
    OVERLAPPING_SECTORS: 9,
    CTOT_VIOLATION: 10,
    EARLY_DEPARTURE: 11,
    AIRCRAFT_RETURN_TO_RAMP: 12,
    AIRCRAFT_RETURN_FROM_AIRBORNE: 13,
    AIRCRAFT_DIVERSION: 14,
    LATE_ONGOING_PROCESS: 17,
    MVT_ACARS_DIFFERENCE_IN_ATD: 18,
    MVT_ACARS_DIFFERENCE_IN_ATA: 19,
    MVT_ACARS_DIFFERENCE_IN_LND: 20,
    MVT_ACARS_DIFFERENCE_IN_TOT: 21,
    ASSIGN_RAMP_AGENT: 22,
    ASSIGN_RAMP_AGENT_CRITICAL: 23,
  };
}
