<!--<div class="exit-screen-page-container">-->
<!--    <div class="flex flex-row flex-wrap mb-3">-->
<!--      <div class="exit-screen-content-page">-->
<!--        <form class="container children-fs16" [formGroup]="form">-->
<!--            <div class="flex-row mt-3 mb-3 flex">-->
<!--                <div class="form-check form-switch">-->
<!--                    <input class="form-check-input" type="checkbox" role="switch" id="show-unpaired" >-->
<!--                    <label class="form-check-label" for="show-unpaired">For Avitium</label>-->
<!--                </div>-->
<!--                <div class="form-check form-switch ms-3">-->
<!--                    <input class="form-check-input" type="checkbox" role="switch" id="show-unpaired" >-->
<!--                    <label class="form-check-label" for="show-unpaired">For Other Systems</label>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="row pb-3">-->
<!--                <div class="col-6 flex flex-column gap-3">-->
<!--                    <div class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            Airline Designator-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <select class="form-control form-select" id="flight-service" formControlName="airlineDesignator">-->
<!--                                <option *ngFor="let airDesignator of airlineDesignator" [value]="airDesignator.value.code">{{airDesignator.value.code}}</option>-->
<!--                            </select>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="row">-->
<!--                        <div class="col-7 form-field-header flex">-->
<!--                            Flight Number-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <input type="text" class="form-control" id="arrival-flight-passenger-gate" placeholder="" formControlName="flightNumber">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            Date of Departure-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <div class="input-group">-->
<!--                                <input class="form-control" [placeholder]="dateFormat"-->
<!--                                    name="dateOfDeparture" formControlName="dateOfDeparture" ngbDatepicker #d1="ngbDatepicker" (ngModelChange)="fixDateFormat()">-->
<!--                                <div class="input-group-append">-->
<!--                                <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar"-->
<!--                                        (click)="d1.toggle()" type="button"></button>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-6 flex flex-column gap-3">-->
<!--                    <div class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            A/C Registration-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <select class="form-control form-select" id="ac-registration" formControlName="acRegistration">-->
<!--                                <option *ngFor="let acRegistration of acRegistrations" [value]="acRegistration.value.registration">{{acRegistration.value.registration}}</option>-->
<!--                            </select>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            Airport of Movement-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <select class="form-control form-select" id="movement-airport" formControlName="movementAirport">-->
<!--                                <option *ngFor="let airport of airports" [value]="airport.value.iata">{{airport.value.iata}}</option>-->
<!--                            </select>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            Message Type-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <select class="form-control form-select" id="message-type" formControlName="messageType">-->
<!--                                <option [value]="'ED'">ED - Delay Message</option>-->
<!--                                <option [value]="'NI'">NI - New Information Message </option>-->
<!--                                <option [value]="'AD'">AD - Actual Departure Times Message </option>-->
<!--                                <option [value]="'AA'">AA - Actual Arrival Times Message </option>-->
<!--                            </select>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                </div>-->
<!--            </div>-->
<!--            <div class="row pb-3">-->
<!--                <div class="col-6 flex flex-column gap-3">-->
<!--                    <div class="row mt-2 mb-2">-->
<!--                        <h3><strong>Mandatory Fields</strong></h3>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.delayCode" class="row">-->
<!--                        <div class="col-7 form-field-header flex">-->
<!--                            Delay Code-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <input type="number" class="form-control" id="delay-code" placeholder="" formControlName="delayCode">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.delayMinutes" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            Delay Minutes-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <ngb-timepicker appTimepickerFocus formControlName="delayMinutes" [spinners]="false" name="delayMinutes" (ngModelChange)="fixTimesFormat()"></ngb-timepicker>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.etdDate" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            ETD Date-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <div class="input-group">-->
<!--                                <input class="form-control" [placeholder]="dateFormat"-->
<!--                                    name="etdDate" formControlName="etdDate" ngbDatepicker #d2="ngbDatepicker" (ngModelChange)="fixDateFormat()">-->
<!--                                <div class="input-group-append">-->
<!--                                <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar"-->
<!--                                        (click)="d2.toggle()" type="button"></button>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.etdTime" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            ETD Time-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <ngb-timepicker appTimepickerFocus formControlName="etdTime" [spinners]="false" name="etdTime" (ngModelChange)="fixTimesFormat()"></ngb-timepicker>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.niDate" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            New Info Date-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <div class="input-group">-->
<!--                                <input class="form-control" [placeholder]="dateFormat"-->
<!--                                    name="niDate" formControlName="niDate" ngbDatepicker #d3="ngbDatepicker" (ngModelChange)="fixDateFormat()">-->
<!--                                <div class="input-group-append">-->
<!--                                <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar"-->
<!--                                        (click)="d3.toggle()" type="button"></button>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.niTime" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            New Info Time-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <ngb-timepicker appTimepickerFocus formControlName="niTime" [spinners]="false" name="niTime" (ngModelChange)="fixTimesFormat()"></ngb-timepicker>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.atdDate" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            ATD Date-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <div class="input-group">-->
<!--                                <input class="form-control" [placeholder]="dateFormat"-->
<!--                                    name="atdDate" formControlName="atdDate" ngbDatepicker #d4="ngbDatepicker" (ngModelChange)="fixDateFormat()">-->
<!--                                <div class="input-group-append">-->
<!--                                <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar"-->
<!--                                        (click)="d4.toggle()" type="button"></button>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.atdTime" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            ATD Time-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <ngb-timepicker appTimepickerFocus formControlName="atdTime" [spinners]="false" name="atdTime" (ngModelChange)="fixTimesFormat()"></ngb-timepicker>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.totDate" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            Take Off Date-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <div class="input-group">-->
<!--                                <input class="form-control" [placeholder]="dateFormat"-->
<!--                                    name="totDate" formControlName="totDate" ngbDatepicker #d5="ngbDatepicker" (ngModelChange)="fixDateFormat()">-->
<!--                                <div class="input-group-append">-->
<!--                                <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar"-->
<!--                                        (click)="d5.toggle()" type="button"></button>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.totTime" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            Take Off Time-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <ngb-timepicker appTimepickerFocus formControlName="totTime" [spinners]="false" name="totTime" (ngModelChange)="fixTimesFormat()"></ngb-timepicker>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.landDate" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            Landing Date-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <div class="input-group">-->
<!--                                <input class="form-control" [placeholder]="dateFormat"-->
<!--                                    name="landDate" formControlName="landDate" ngbDatepicker #d7="ngbDatepicker" (ngModelChange)="fixDateFormat()">-->
<!--                                <div class="input-group-append">-->
<!--                                <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar"-->
<!--                                        (click)="d7.toggle()" type="button"></button>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.landTime" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            Landing Time-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <ngb-timepicker appTimepickerFocus formControlName="landTime" [spinners]="false" name="landTime" (ngModelChange)="fixTimesFormat()"></ngb-timepicker>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.ataDate" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            ATA Date-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <div class="input-group">-->
<!--                                <input class="form-control" [placeholder]="dateFormat"-->
<!--                                    name="ataDate" formControlName="ataDate" ngbDatepicker #d6="ngbDatepicker" (ngModelChange)="fixDateFormat()">-->
<!--                                <div class="input-group-append">-->
<!--                                <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar"-->
<!--                                        (click)="d6.toggle()" type="button"></button>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.ataTime" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                           ATA Time-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <ngb-timepicker appTimepickerFocus formControlName="ataTime" [spinners]="false" name="ataTime" (ngModelChange)="fixTimesFormat()"></ngb-timepicker>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.etaTime" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            ETA Time-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <ngb-timepicker appTimepickerFocus formControlName="etaTime" [spinners]="false" name="etaTime" (ngModelChange)="fixTimesFormat()"></ngb-timepicker>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.arrAirport" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            Arriving Airport-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <select class="form-control form-select" id="arriving-airport" formControlName="arrAirport">-->
<!--                                <option *ngFor="let airport of airports" [value]="airport.value.iata">{{airport.value.iata}}</option>-->
<!--                            </select>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.pax" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            PAX-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <input type="number" class="form-control" id="arrival-flight-passenger-gate" placeholder="" formControlName="pax">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-6 flex flex-column gap-3">-->
<!--                    <div class="row mt-2 mb-2">-->
<!--                        <h1><strong>Optional Fields</strong></h1>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.extraDelayCode" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            Extra Delay-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <input type="number" class="form-control" id="arrival-flight-passenger-gate" placeholder="" formControlName="extraDelayCode">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div *ngIf="this.exitScreenSetup??.extraDelayMinutes" class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            Delay Minutes-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <ngb-timepicker appTimepickerFocus formControlName="extraDelayMinutes" [spinners]="false" name="extraDelayMinutes" (ngModelChange)="fixTimesFormat()"></ngb-timepicker>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="row">-->
<!--                        <div class="col-7 form-field-header">-->
<!--                            SI-->
<!--                        </div>-->
<!--                        <div class="col-5">-->
<!--                            <input oninput="this.value = this.value.toUpperCase()" type="text" class="form-control" id="arrival-flight-passenger-gate" placeholder="" formControlName="si">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </form>-->
<!--        <div class="footer">-->
<!--            <div style="float:left" ><p class="text-muted">* All times should be in UTC format</p></div>-->
<!--            <div style="float:right">-->
<!--                <button type="button" (click)="restoreForm()" class="btn btn-secondary me-1 settings-modal-button" >Cancel-->
<!--                </button>-->
<!--                <button type="button" (click)="downloadFile(this.form.value.messageType, this.form.value.messageType+this.departureDate)" class="btn btn-primary ms-1 settings-modal-button" ngbAutofocus -->
<!--                        [disabled]="form?.disabled || form?.invalid">-->
<!--                  {{ isBusy ? '' : 'Create' }}-->
<!--                  <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>-->
<!--                </button>-->
<!--            </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="exit-screen-menu">-->
<!--        <div class="mb-2" style="text-align: center;"><h4>Preview</h4></div>-->
<!--        <div *ngIf="this.form.value.messageType === ''" class="flex flex-column flex-wrap bg-white" style="text-align: center;">-->
<!--              <p>Please select a message type to see its preview</p>-->
<!--        </div>-->
<!--        <div *ngIf="this.form.value.messageType === 'ED'" class="flex flex-column flex-wrap bg-white" style="text-align: center;">-->
<!--            MVT <br>-->
<!--            {{this.form.value.airlineDesignator+this.form.value.flightNumber}}/{{this.departureDate}}.{{this.form.value.acRegistration}}.{{this.form.value.movementAirport}}<br>-->
<!--            {{this.form.value.messageType}}{{this.etdDate}}{{this.etdTime}}<br>-->
<!--            DL{{this.form.value.delayCode}}<br>-->
<!--            {{this.form.value?.si ? 'SI '+this.form.value?.si : ''}}-->
<!--        </div>-->
<!--        <div *ngIf="this.form.value.messageType === 'NI'" class="flex flex-column flex-wrap bg-white" style="text-align: center;">-->
<!--            MVT <br>-->
<!--            {{this.form.value.airlineDesignator+this.form.value.flightNumber}}/{{this.departureDate}}.{{this.form.value.acRegistration}}.{{this.form.value.movementAirport}}<br>-->
<!--            {{this.form.value.messageType}}{{this.niDate}}{{this.niTime}}<br>-->
<!--            {{this.form.value?.si ? 'SI '+this.form.value?.si : ''}}-->
<!--        </div>-->
<!--        <div *ngIf="this.form.value.messageType === 'AD'" class="flex flex-column flex-wrap bg-white" style="text-align: center;">-->
<!--            MVT <br>-->
<!--            {{this.form.value.airlineDesignator+this.form.value.flightNumber}}/{{this.departureDate}}.{{this.form.value.acRegistration}}.{{this.form.value.movementAirport}}<br>-->
<!--            {{this.form.value.messageType}}{{this.atdDate}}{{this.atdTime}}/{{this.totDate}}{{this.totTime}} EA{{this.etaTime}} {{this.form.value.arrAirport}}<br>-->
<!--            DL{{this.form.value.delayCode}}{{this.form.value?.extraDelayCode ? '/'+this.form.value?.extraDelayCode : ''}}/{{this.delayMinutes}}{{this.form.value?.extraDelayMinutes ? '/'+this.extraDelayMinutes : ''}}<br>-->
<!--            <div *ngIf="this.form.value?.pax">PX{{this.form.value.pax}}<br></div>-->
<!--            {{this.form.value?.si ? 'SI '+this.form.value.si : ''}}-->

<!--        </div>-->
<!--        <div *ngIf="this.form.value.messageType === 'AA'" class="flex flex-column flex-wrap bg-white" style="text-align: center;">-->
<!--            MVT <br>-->
<!--            {{this.form.value.airlineDesignator+this.form.value.flightNumber}}/{{this.departureDate}}.{{this.form.value.acRegistration}}.{{this.form.value.movementAirport}}<br>-->
<!--            {{this.form.value.messageType}}{{this.landDate}}{{this.landTime}}/{{this.ataDate}}{{this.ataTime}}<br>-->
<!--            {{this.form.value?.si ? 'SI '+this.form.value?.si : ''}}-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--</div>-->

<div class="main-container dark-blue p-3" >
  <div class="flex flex-column">
    <div class="flex justify-content-between mt-3 mb-3">
      <div class="flex">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="for-avitium" [(ngModel)]="forAvitium" data-test="exit-screen-mvt-for-avitium-check">
          <label class="form-check-label" for="for-avitium">For Avitium</label>
        </div>
        <div class="form-check form-switch ms-3">
          <input class="form-check-input" type="checkbox" role="switch" id="for-other" [(ngModel)]="forOthers" disabled data-test="exit-screen-mvt-for-others-check">
          <label class="form-check-label" for="for-other">For Other Systems</label>
        </div>
      </div>
      <span class="me-3">* All times in UTC</span>
    </div>

    <div class="flex gap-3 skip-invalid-border" *ngIf="form" [formGroup]="form">
      <div class="flex flex-column gap-2 flex-grow-1">
        <app-settings-card sectionTitle="Required Fields">
          <div class="flex dark-blue justify-content-between">
            <div class="flex-basis-30">
              <label for="message-type" >Action - Message Type</label>
              <select class="form-control form-select" id="message-type" formControlName="messageType" data-test="exit-screen-mvt-message-type-select">
                <option value="ED" data-test="message-type-ed-option">ED - Delay Message</option>
                <option value="NI" data-test="message-type-ni-option">NI - New Information Message </option>
                <option value="AD" data-test="message-type-ad-option">AD - Actual Departure Times Message </option>
                <option value="AA" data-test="message-type-aa-option">AA - Actual Arrival Times Message </option>
              </select>
            </div>
            <div class="flex-basis-30">
              <label for="ac-registration" >A/C Registration</label>
              <ng-select id="ac-registration" formControlName="acRegistration" data-test="exit-screen-mvt-ac-reg-select" [inputAttrs]="{ 'data-test': 'ac-registration-input' }">
                <ng-option  *ngFor="let acRegistration of acRegistrations" [value]="acRegistration.registration">{{acRegistration.registration}}</ng-option>
                <ng-template ng-option-tmp let-item="item">
                  <span class="ng-option-label" [attr.data-test]="'ac-registration-' + item">{{ item }}</span>
                </ng-template>
              </ng-select>
            </div>
            <div class="flex-basis-30">
              <label for="airline-designator" >Airline Designator</label>
              <select class="form-control form-select" id="airline-designator" formControlName="airlineDesignator" data-test="exit-screen-mvt-airline-designator-select">
                <option *ngFor="let airDesignator of airlineDesignators" [value]="airDesignator.code" [attr.data-test]="'airline-des-option-' + airDesignator?.code">{{airDesignator.code}}</option>
              </select>
            </div>
          </div>

          <div class="flex dark-blue justify-content-between">
            <div class="flex-basis-30">
              <label for="flight-number">Flight Number</label>
              <input type="text" class="form-control" id="flight-number" placeholder="" formControlName="flightNumber" data-test="exit-screen-mvt-flight-number-input">
            </div>
            <div class="flex-basis-30">
              <label for="dateOfDeparture" >Departure Date</label>
              <div class="input-group">
                <input class="form-control" id="dateOfDeparture"
                       formControlName="dateOfDeparture" ngbDatepicker #d="ngbDatepicker" [placeholder]="this.dateFormat" data-test="exit-screen-mvt-departure-date-input">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar h-100" (click)="d.toggle()" type="button" data-test="exit-screen-mvt-departure-calendar-button"></button>
                </div>
              </div>
            </div>
            <div class="flex-basis-30">
              <label for="movement-airport" >Airport of Movement</label>
              <ng-select id="movement-airport" formControlName="movementAirport" data-test="exit-screen-mvt-airport-select" [inputAttrs]="{ 'data-test': 'movement-airport-input' }">
                <ng-option *ngFor="let airport of airports" [value]="airport.iata">{{airport.iata}}</ng-option>
                <ng-template ng-option-tmp let-item="item">
                  <span class="ng-option-label" [attr.data-test]="'movement-airport-' + item">{{ item }}</span>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </app-settings-card>

        <app-settings-card sectionTitle="Mandatory Fields">
          <div class="field-layout dark-blue">
            <div  *ngIf="this.exitScreenSetup?.etdTime">
              <label >ETD Time</label>
              <ngb-timepicker appTimepickerFocus formControlName="etdTime" [spinners]="false" data-test="exit-screen-mvt-etd-time-picker"></ngb-timepicker>
            </div>

            <div  *ngIf="this.exitScreenSetup?.etdDate">
              <label >ETD Date</label>
              <div class="input-group">
                <input class="form-control" [placeholder]="this.dateFormat" id="etdDate"
                       formControlName="etdDate" ngbDatepicker #d2="ngbDatepicker" data-test="exit-screen-mvt-etd-date-input">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar h-100" (click)="d2.toggle()" type="button" data-test="exit-screen-mvt-etd-calendar-button"></button>
                </div>
              </div>
            </div>

            <div  *ngIf="this.exitScreenSetup?.niTime">
              <label >NI Time</label>
              <ngb-timepicker appTimepickerFocus formControlName="niTime" [spinners]="false" data-test="exit-screen-mvt-ni-time-picker"></ngb-timepicker>
            </div>

            <div  *ngIf="this.exitScreenSetup?.niDate">
              <label >NI Date</label>
              <div class="input-group">
                <input class="form-control" [placeholder]="this.dateFormat" id="niDate"
                       formControlName="niDate" ngbDatepicker #d3="ngbDatepicker" data-test="exit-screen-mvt-ni-date-input">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar h-100" (click)="d3.toggle()" type="button" data-test="exit-screen-mvt-ni-calendar-button"></button>
                </div>
              </div>
            </div>

            <ng-container *ngIf="this.exitScreenSetup?.atdTime || this.exitScreenSetup?.atdDate">
              <div class="flex justify-content-between">
                <div  *ngIf="this.exitScreenSetup?.atdTime">
                  <label >ATD Time</label>
                  <ngb-timepicker appTimepickerFocus formControlName="atdTime" [spinners]="false" data-test="exit-screen-mvt-atd-time-picker"></ngb-timepicker>
                </div>

                <div  *ngIf="this.exitScreenSetup?.atdDate">
                  <label >ATD Date</label>
                  <div class="input-group">
                    <input class="form-control" [placeholder]="this.dateFormat" id="atdDate"
                           formControlName="atdDate" ngbDatepicker #d4="ngbDatepicker" data-test="exit-screen-mvt-atd-date-input">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar h-100" (click)="d4.toggle()" type="button" data-test="exit-screen-mvt-atd-calendar-button"></button>
                    </div>
                  </div>
                </div>
              </div>

            </ng-container>

            <ng-container *ngIf="this.exitScreenSetup?.totTime || this.exitScreenSetup?.totDate">
              <div class="flex justify-content-between">
                <div  *ngIf="this.exitScreenSetup?.totTime">
                  <label >Takeoff Time</label>
                  <ngb-timepicker appTimepickerFocus formControlName="totTime" [spinners]="false" data-test="exit-screen-mvt-tot-time-picker"></ngb-timepicker>
                </div>

                <div  *ngIf="this.exitScreenSetup?.totDate">
                  <label >Takeoff Date</label>
                  <div class="input-group">
                    <input class="form-control" [placeholder]="this.dateFormat" id="totDate"
                           formControlName="totDate" ngbDatepicker #d5="ngbDatepicker" data-test="exit-screen-mvt-tot-date-input">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar h-100" (click)="d5.toggle()" type="button" data-test="exit-screen-mvt-tot-calendar-button"></button>
                    </div>
                  </div>
                </div>
              </div>

            </ng-container>



            <div  *ngIf="this.exitScreenSetup?.etaTime">
              <label >ETA Time</label>
              <ngb-timepicker appTimepickerFocus formControlName="etaTime" [spinners]="false" data-test="exit-screen-mvt-eta-time-picker"></ngb-timepicker>
            </div>

            <div  *ngIf="this.exitScreenSetup?.arrAirport">
              <label for="arrival-airport" >Arrival Airport</label>
              <ng-select id="arrival-airport" formControlName="arrAirport" data-test="exit-screen-mvt-arrival-airport-select" [inputAttrs]="{ 'data-test': 'arrival-airport-input' }">
                <ng-option *ngFor="let airport of airports" [value]="airport.iata">{{airport.iata}}</ng-option>
                <ng-template ng-option-tmp let-item="item">
                  <span class="ng-option-label" [attr.data-test]="'arrival-airport-' + item">{{ item }}</span>
                </ng-template>
              </ng-select>
            </div>

            <ng-container *ngIf="this.exitScreenSetup?.ataTime || this.exitScreenSetup?.ataDate">
              <div class="flex justify-content-between">
                <div  *ngIf="this.exitScreenSetup?.ataTime">
                  <label >ATA Time</label>
                  <ngb-timepicker appTimepickerFocus formControlName="ataTime" [spinners]="false" data-test="exit-screen-mvt-ata-time-picker"></ngb-timepicker>
                </div>

                <div  *ngIf="this.exitScreenSetup?.ataDate">
                  <label >ATA Date</label>
                  <div class="input-group">
                    <input class="form-control" [placeholder]="this.dateFormat" id="ataDate"
                           formControlName="ataDate" ngbDatepicker #d6="ngbDatepicker" data-test="exit-screen-mvt-ata-date-input">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar h-100" (click)="d6.toggle()" type="button" data-test="exit-screen-mvt-ata-calendar-button"></button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>


            <ng-container *ngIf="this.exitScreenSetup?.landTime || this.exitScreenSetup?.landDate">
              <div class="flex justify-content-between">
                <div  *ngIf="this.exitScreenSetup?.landTime">
                  <label >Landing Time</label>
                  <ngb-timepicker appTimepickerFocus formControlName="landTime" [spinners]="false" data-test="exit-screen-mvt-land-time-picker"></ngb-timepicker>
                </div>

                <div  *ngIf="this.exitScreenSetup?.landDate">
                  <label >Landing Date</label>
                  <div class="input-group">
                    <input class="form-control" [placeholder]="this.dateFormat" id="landDate"
                           formControlName="landDate" ngbDatepicker #d7="ngbDatepicker" data-test="exit-screen-mvt-land-date-input">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar h-100" (click)="d7.toggle()" type="button" data-test="exit-screen-mvt-land-calendar-button"></button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>


            <div  *ngIf="this.exitScreenSetup?.pax">
              <label  for="pax">PAX</label>
              <input type="number" class="form-control" id="pax" min="0" formControlName="pax" data-test="exit-screen-mvt-pax-input">
            </div>

            <div *ngIf="this.exitScreenSetup?.delayCode">
              <label  for="delay-code">Delay Code</label>
              <ng-select id="delay-code" formControlName="delayCode" data-test="exit-screen-mvt-delay-code-select" [inputAttrs]="{ 'data-test': 'delay-code-input' }">
                <ng-container *ngIf="delayCodes?.length">
                  <ng-option *ngFor="let code of delayCodes" [value]="code">{{code.code}} {{ code.description }}</ng-option>
                  <ng-template ng-option-tmp let-item="item">
                    <span class="ng-option-label" [attr.data-test]="'delay-code-' + item.code">{{ item.code }} {{ item.description }}</span>
                  </ng-template>
                </ng-container>

              </ng-select>
            </div>

            <div  *ngIf="this.exitScreenSetup?.delayMinutes">
              <label  for="delayMinutes">Delay Minutes</label>
              <input type="number" class="form-control" id="delayMinutes" min="0" formControlName="delayMinutes" data-test="exit-screen-mvt-delay-minutes-input">
            </div>

            <div *ngIf="this.exitScreenSetup?.extraDelayCode">
              <label  for="extra-delay-code">Extra Delay Code</label>
              <ng-select id="extra-delay-code" formControlName="extraDelayCode" data-test="exit-screen-mvt-extra-delay-code-select" [inputAttrs]="{ 'data-test': 'extra-delay-code-input' }">
                <ng-container *ngIf="delayCodes?.length">
                  <ng-option *ngFor="let code of delayCodes" [value]="code">{{ code.code }} {{ code.description }}</ng-option>
                </ng-container>

                <ng-template ng-option-tmp let-item="item">
                  <span class="ng-option-label" [attr.data-test]="'delay-code-' + item.code">{{ item.code }} {{ item.description}}</span>
                </ng-template>

              </ng-select>
            </div>

            <div  *ngIf="this.exitScreenSetup?.extraDelayMinutes">
              <label for="extraDelayMinutes">Delay Minutes</label>
              <input type="number" class="form-control" id="extraDelayMinutes" min="0" formControlName="extraDelayMinutes" data-test="exit-screen-mvt-extra-delay-minutes-input">
            </div>


          </div>

          <div class="dark-blue" *ngIf="this.exitScreenSetup?.si">
            <label for="si">SI</label>
            <input toUppercase type="text" class="form-control" id="si" placeholder="" formControlName="si" data-test="exit-screen-mvt-si-input">
          </div>
        </app-settings-card>
      </div>
      <div class="flex flex-column right-side gap-3">
        <app-message-creation-preview class=" mt-3" [text]="this.fileContent"></app-message-creation-preview>
        <div class="flex justify-content-between">
          <button class="btn btn-outline-secondary clear-btn" (click)="clearForm()" data-test="exit-screen-mvt-clear-button">Clear</button>
          <button class="btn button-blue" [disabled]="!forAvitium || form.invalid" (click)="submit()" data-test="exit-screen-mvt-submit-button">Submit</button>
          <button class="btn button-blue" (click)="downloadFile()" [disabled]="form.invalid" data-test="exit-screen-mvt-download-button">Download</button>
        </div>
      </div>
    </div>
  </div>
</div>
