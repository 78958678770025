import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { dayjsToNgbDate } from '../../../utils/utils';
import * as dayjs from 'dayjs';

export class ExitScreenCreateAsmFormBuilder {

    static constructForm(fb: FormBuilder): FormGroup {

    return fb.group({
      messageType: ['', Validators.required],
      airlineDesignator: ['', Validators.required],
      flightNumber: ['', Validators.required],
      dateOfDeparture: [dayjsToNgbDate(dayjs.utc()), Validators.required],
      flightServiceType: ['', Validators.required],
      acType: ['', Validators.required],
      acConfig: ['', Validators.required],
      acRegistration: [''],
      depAirport: ['', Validators.required],
      arrAirport: ['', Validators.required],
      std: ['', Validators.required],
      sta: ['', Validators.required],
      si: [''],
    });
  }
}
