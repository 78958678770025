import { Component, Input, OnInit } from '@angular/core';
import { AirportsService } from '../../../../../services/airports.service';
import { ToastService } from '../../../../../services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAirportContact } from '../../../../../shared/models/airport-contact.model';
import { AuthService } from '../../../../../services/auth.service';

@Component({
  selector: 'app-add-edit-airport-contact-dialog',
  templateUrl: './add-edit-airport-contact-dialog.component.html',
  styleUrls: ['./add-edit-airport-contact-dialog.component.scss']
})
export class AddEditAirportContactComponent implements OnInit {

  title = "Add Airport Contact";
  @Input() contactData?: IAirportContact;
  @Input() airportId?: number;
  form: FormGroup;
  isBusy = false;

  constructor(private airportService: AirportsService, private toastService: ToastService, private activeModal: NgbActiveModal, private authService: AuthService) {}

  ngOnInit() {
    this.form = new FormGroup({
      firstname: new FormControl(this.contactData?.firstname || '', Validators.required),
      lastname: new FormControl(this.contactData?.lastname || '', Validators.required),
      email: new FormControl(this.contactData?.email || '', Validators.pattern(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)),
      company: new FormControl(this.contactData?.company || '', Validators.required),
      department: new FormControl(this.contactData?.department || ''),
      mobile: new FormControl(this.contactData?.mobile || ''),
      phone: new FormControl(this.contactData?.phone || ''),
      remarks: new FormControl(this.contactData?.remarks || ''),
      sita1: new FormControl(this.contactData?.sita1 || ''),
      sita2: new FormControl(this.contactData?.sita2 || ''),
      vhf: new FormControl(this.contactData?.vhf || ''),
    });
  }

  closeModal(reason?: any) {
    this.activeModal.dismiss(reason);
  }

  onSaveClick() {
    const data: IAirportContact = { ...this.contactData, airportId: this.airportId, userId: this.authService.user.id, ...this.form.value };
    this.isBusy = true;
    this.airportService.saveAirportContactCompanies(data).subscribe((result) => {
      if(result?.id) {
        this.toastService.showSuccess("Contact company has been saved");
        this.closeModal(true);
      }
      this.isBusy = false;
    });
  }
}
