import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ISimpleData } from '../../../models/simpleData.model';
import * as dayjs from 'dayjs';
import { dayjsToNgbDate } from '../../../utils/utils';

export class SettingsAcTypeMainFormBuilder {

  static constructForm(
    fb: FormBuilder,
    passengerClassesArray: ISimpleData[],
    maxVersion: number): FormGroup {
    const seatConfigFormGroup = new FormGroup({
      seats: new FormArray([], validConfigValidator()),
    });

    const seatsArray = seatConfigFormGroup.get('seats') as FormArray;

    for (let i= 1; i<= maxVersion; i++) {
       const fg = new FormGroup({
         validFrom: new FormControl(dayjsToNgbDate(dayjs.utc())),
         validTo: new FormControl(),
         operatedBy: new FormControl()
       }, {});
      passengerClassesArray.forEach((item: ISimpleData) => {
        fg.addControl(item.code, new FormGroup({
          code: new FormControl(item.code),
          description: new FormControl(),
          id: new FormControl(),
        }));
      });
      seatsArray.push(fg);
    }


    function validConfigValidator(): ValidationErrors | null {
      return (configArray: FormArray): ValidationErrors | null => {
        let isValid = false;

        for (const control of configArray.controls) {
          if(isValid) {
            break;
          }
          const group: FormGroup = control as FormGroup;
          for (const passengerClassCode of passengerClassesArray.map((pc) => pc.code)) {
            if(+group.controls[passengerClassCode].value?.description) {
              isValid = true;
              break;
            }
          }
        }

        return isValid ? null : { 'noSeat': true };
      };
    }

    return fb.group({
      alias: ['', Validators.required],
      description: [null],
      calibrationId: ['', Validators.required],
      fireCategory: [null],
      iata: ['', Validators.compose([Validators.required, Validators.pattern('[\\d\\w]{3}')])],
      icao: ['', Validators.required],
      mtow: [null],
      defaultMinTurnaroundTimeInMinutes: ['', Validators.compose([Validators.required, Validators.pattern('\\d{2}[:]\\d{2}')])],
      seatingConfig: seatConfigFormGroup,
      wakeTurbulence: [null]
    });


  }
}
