import { ILegsFirestoreModel, ILegsModel } from '../models/legs.model';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import { IPairOverview } from '../models/pair-overview.model';
import { IPairDetail, isPairDetail } from '../models/pair-detail.model';
import { IPairsModel } from '../models/pairs.model';
import { IAlertsModel } from '../models/alerts.model';

export function emptyObject(obj: Record<string, any>) {
  if (obj) {
    for (const key in obj) {
      delete obj[key];
    }
  }
}

export function emptyOrNewObject(obj: Record<string, any>): Record<string, any> {
  if (obj) {
    for (const key in obj) {
      delete obj[key];
    }
  } else {
    obj = {};
  }
  return obj;
}


export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

/**
 * Compares a text and array of texts and returns true if the seating configuration TEXT is equal to the content of an array of texts
 * @param text Seating configuration like C40Y120
 * @param searchWords an array of strings like ['C40', 'Y120']
 */
export function compareSeatingConfigurations(text: string, searchWords: string[]) {
  return searchWords.findIndex((word) => word === text) !== -1;
}

export function extractSeatingConfigurations(text: string): string[] {
  console.log('Extracting:', text);
  const seatingConfigurationRegex = /([A-Z][0-9]{1,3})/g;
  return text.split(seatingConfigurationRegex).filter((el) => el !== '');
}

export function getLatestLegTime(leg: ILegsModel, arrival = true): Date {
  if (arrival) {
    return leg?.ata ?? leg?.eta ?? leg?.sta;
  } else {
    return leg?.atd ?? leg?.etd ?? leg?.std;
  }
}

export function dayjsToNgbDate(date: dayjs.Dayjs): NgbDateStruct {
  return {day: date.date(), month: date.month() + 1, year: date.year()};
}

export function ngbDateToFormat(date: NgbDateStruct, format = 'DD.MM.YYYY'): string {
  if (!date) {
    return '';
  }
  return dayjs.utc([date.year, date.month - 1, date.day]).format(format);
}

export function ngbDateToDayjs(date: NgbDateStruct): dayjs.Dayjs {
  if (!date) {
    return dayjs.utc();
  }
  return dayjs.utc([date.year, date.month - 1, date.day]);
}

export function areLegTimesSame(leg1: ILegsModel, leg2: ILegsModel): boolean {
  return isDateSame(leg1.std, leg2.std) && isDateSame(leg1.sta, leg2.sta) && isDateSame(leg1.etd, leg2.etd) && isDateSame(leg1.eta, leg2.eta) && isDateSame(leg1.atd, leg2.atd) && isDateSame(leg1.ata, leg2.ata);
}

export function needsSqlRefresh(leg1: ILegsModel, leg2: ILegsModel): boolean {
  return !isDateSame(leg1.std, leg2.std) || !isDateSame(leg1.etd, leg2.etd) || !isDateSame(leg1.atd, leg2.atd) || !isDateSame(leg1.ata, leg2.ata);
}

function isDateSame(date1: Date, date2: Date): boolean {
  if (!date1 && !date2) {
    return true;
  } else if ((date1 && !date2) || (!date1 && date2)) {
    return false;
  }
  return dayjs(date1).utc().isSame(dayjs(date2).utc());
}

/**
 * Returns the ETA time in HH:mm format
 * @param leg The leg model
 * @param atdDate ATD date in NgbDateStruct format or null
 * @param atdTime ATD time in HH:MM format or null
 * @param totTime TOT time in HH:MM format or null
 * @param etd ETD in date format or null
 */
export function calculateETA(leg: ILegsModel, atdDate: NgbDateStruct, atdTime: string, totTime: string, etd: Date): string {

  // atdTime HH:mm string format
  // atdDate is NgbDateStruct
  const hasAtd = atdDate?.year && atdDate?.month && atdDate?.day && atdTime?.length === 5;
  // HH:mm string format
  const takeoffTime: string = totTime;
  if (!etd && !hasAtd) {
    return dayjs(leg.sta).utc().format('HH:mm');
  }
  const blockTime = dayjs(leg.sta).diff(leg.std, 'minutes');
  if (takeoffTime && takeoffTime.length === 5) {
    const totSplit = takeoffTime.split(':');
    let dateObj = ngbDateToDayjs(atdDate);
    dateObj = dateObj.hour(Number(totSplit[0]));
    dateObj = dateObj.minute(Number(totSplit[1]));
    dateObj = dateObj.add(blockTime, 'minutes').subtract(10, 'minutes');
    return dateObj.format('HH:mm');
  } else if (hasAtd) {
    const atdTimeSplit = atdTime.split(':');
    let dateObj = ngbDateToDayjs(atdDate).hour(Number(atdTimeSplit[0])).minute(Number(atdTimeSplit[1])).add(blockTime, 'minutes');
    return dateObj.format('HH:mm');
  } else if (etd) {
    return dayjs(etd).utc().add(blockTime, 'minutes').format('HH:mm');
  }
}

export function calculateETADate(leg: ILegsModel): dayjs.Dayjs {
  if (!leg.etd && !leg.atd) {
    return dayjs(leg.sta).utc();
  }
  const blockTime = dayjs(leg.sta).diff(leg.std, 'minutes');
  if (leg.takeOffTime) {
    return dayjs(leg.takeOffTime).utc().add(blockTime, 'minutes').subtract(10, 'minutes');
  } else if (leg.atd) {
    return dayjs(leg.atd).utc().add(blockTime, 'minutes');
  } else if (leg.etd) {
    return dayjs(leg.etd).utc().add(blockTime, 'minutes');
  }
}


export function fixMonth(text: string): string {
  switch (text.length) {
    case 3:
      return text.slice(0, 1) + text.slice(1, 3).toLowerCase();
    case 5:
      return text.slice(0, 3) + text.slice(3, 5).toLowerCase();
    case 7:
      return text.slice(0, 3) + text.slice(3, 5).toLowerCase() + text.slice(5, 7);
  }
  return text;
}

export function getTimestamp(): string {
  return dayjs.utc().format('DD_MM_YYYY_HH_mm_ss_SSS');
}


export function isPairOverview(pair: IPairOverview | IPairDetail): pair is IPairOverview {
  return 'hasExtensionRequest' in pair;
}

export function stationsFromAlert(alert: IAlertsModel) {
  if (!alert || !alert.__pair__ && !alert.__leg__) {
    return [];
  }
  if (alert.__pair__) {
    return stationsFromPair(alert.__pair__);
  }
  return stationsFromLeg(alert.__leg__);
}

export function stationsFromLeg(leg: ILegsModel | ILegsFirestoreModel) {
  if (!leg) {
    return [];
  }
  const stations: string[] = [];
  stations.push(leg.arrivalStation);
  if (!stations.includes(leg.departureStation)) {
    stations.push(leg.departureStation);
  }
  return stations;
}

export function stationsFromPair(pair: IPairsModel | IPairDetail): string[] {
  if (!pair) {
    return [];
  }
  const stations = new Set<string>();
  //const stations: string[] = [];
  if (isPairDetail(pair)) {
    if (pair.arrivalLeg) {
      if (pair.arrivalLeg?.arrivalStation?.length) {
        stations.add(pair.arrivalLeg.arrivalStation);
      }
      if (pair.arrivalLeg?.departureStation?.length) {
        stations.add(pair.arrivalLeg.departureStation);
      }
    }
    if (pair.departureLeg) {
      if (pair.departureLeg?.arrivalStation?.length) {
        stations.add(pair.departureLeg.arrivalStation);
      }
      if (pair.departureLeg?.departureStation?.length) {
        stations.add(pair.departureLeg.departureStation);
      }
    }
    return Array.from(stations);
  }

  if (pair.__arrivalLegModel__) {
    if (pair.__arrivalLegModel__?.arrivalStation?.length) {
      stations.add(pair.__arrivalLegModel__.arrivalStation);
    }
    if (pair.__arrivalLegModel__?.departureStation?.length) {
      stations.add(pair.__arrivalLegModel__.departureStation);
    }
  }
  if (pair.__departureLegModel__) {
    if (pair.__departureLegModel__?.arrivalStation?.length) {
      stations.add(pair.__departureLegModel__.arrivalStation);
    }
    if (pair.__departureLegModel__?.departureStation?.length) {
      stations.add(pair.__departureLegModel__.departureStation);
    }
  }
  return Array.from(stations);
}

export function dateToNgbDate(date: Date): NgbDate {
  if(!date) {
    return null;
  }
  return new NgbDate(date?.getFullYear(),date?.getMonth() + 1,date?.getDate());
}

export function minutesToHHmm(actualMinutes:number): string {
  const hours = Math.floor(actualMinutes / 60);
  console.log('Hours:', hours);
  const minutes = actualMinutes % 60;
  console.log('Minutes:', minutes);

  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  console.log('Formated time:', formattedTime);

  return dayjs(formattedTime, 'HH:mm').format('HHmm');
}


export function areObjectsSame(obj1: any, obj2: any) {
  console.log('areObjectsSame:', obj1, obj2);
  if (!obj1 && !obj2) {
    return true;
  }
  if (!obj1 || !obj2) {
    return false;
  }
  // Get the property names of the first object
  const keys = Object.keys(obj1);

  // Iterate over each property name
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    // Compare the values of the current property
    if (obj1[key] !== obj2[key]) {
      return false; // Values are different, objects are not equal
    }
  }

  return true; // All properties are equal
}

export function extractPassengersPerCategory(text: string): { code: string; amount: number }[] {
  const pairs: string[] = text.split('/');

  return pairs.map((pair) => {
    const [code, amount] = pair.split(':');
    return { code, amount: Number(amount) };
  });
}
