import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EditPairDialogComponent} from '../edit-pair-dialog/edit-pair-dialog.component';
import {firstValueFrom, interval, Subject, Subscription} from 'rxjs';
import {AlertService} from '../../../services/alert.service';
import {IAlertsModel} from '../../../shared/models/alerts.model';
import {AlertSeverity} from '../../../shared/constants/alert-severity.enum';
import {takeUntil} from 'rxjs/operators';
import dayjs from 'dayjs';
import {TimezoneService} from '../../../services/timezone.service';
import {IUser} from '../../../shared/models/user.model';
import {SortDirection} from '../../../shared/constants/table-sort.types';
import {faSortNumericDown, faSortNumericUp} from '@fortawesome/free-solid-svg-icons';
import {AlertConstants} from "../../../shared/constants/alert.constants";
import {Router} from "@angular/router";
import {UserLegAssignmentService} from "../../../services/user-leg-assignment.service";

@Component({
  selector: 'app-alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.scss'],
})
export class AlertListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() severityFilters: AlertSeverity[];
  @Input() searchKeyword = '';
  @Input() user: IUser;
  @Input() severityChanged: EventEmitter<void>;
  @Output() setAlertIndex: EventEmitter<number> = new EventEmitter();
  @Output() activeAlertChanged: EventEmitter<IAlertsModel> = new EventEmitter();
  activeAlertIndex = -1;
  resolvedAlerts = [];
  alerts: IAlertsModel[] = [];
  filteredAlerts: IAlertsModel[] = [];
  fetchAlertSubscription: Subscription;
  unsubscribe$ = new Subject();
  alertsToRemind: { [alertId: number]: number } = {};
  sortOrder: SortDirection = 'desc';

  ascIcon = faSortNumericUp;
  descIcon = faSortNumericDown;

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }


  constructor(private modalService: NgbModal, public alertService: AlertService, public timezoneService: TimezoneService, private router: Router, private userLegAssignmentService: UserLegAssignmentService) {
  }

  ngOnInit(): void {
    const alertsToRemindStr = localStorage.getItem('alerts_to_remind');
    if (alertsToRemindStr) {
      this.alertsToRemind = JSON.parse(localStorage.getItem('alerts_to_remind'));
    }
    this.fetchAlerts();
    this.fetchAlertSubscription = interval(10000).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.fetchAlerts();
    });
    this.severityChanged.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.updateFilteredList();
    });
  }

  fetchAlerts() {
    this.alertService.getAlerts().subscribe((results) => {
      this.alerts = results.filter((alert) => {
        return alert.alertTypeId !== 9 || !!alert.__pair__.__departureLegModel__
      });
      this.updateFilteredList();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.severityFilter || changes.searchKeyword) {
      this.updateFilteredList();
    }
  }


  doSetAlertIndex(pairKey: number) {
    if (!pairKey || this.activeAlertIndex === pairKey) {
      this.activeAlertIndex = -1;
    } else {
      this.activeAlertIndex = pairKey;
    }
    this.setAlertIndex.emit(this.activeAlertIndex);
    if (this.activeAlertIndex === -1) {
      this.activeAlertChanged.emit(null);
      return;
    }
    const activeAlert = this.alerts.find((alert) => alert.id === this.activeAlertIndex);
    this.activeAlertChanged.emit(activeAlert);
  }

  editClicked(alert: IAlertsModel) {
    if (alert.alertTypeId === AlertConstants.STR_TO_ID.ASSIGN_RAMP_AGENT || alert.alertTypeId === AlertConstants.STR_TO_ID.ASSIGN_RAMP_AGENT_CRITICAL) {
      this.userLegAssignmentService.getUserLegAssignment({
        arrivalLegId: alert.legId
      }).subscribe((res) => {
        this.router.navigate(['/assign'], {state: {userId: res?.[0]?.userId}});
      });

      return;
    }
    if (alert.alertTypeId === 1) {
      const modalRef = this.modalService.open(EditPairDialogComponent, {size: 'lg'});
      const modal = modalRef.componentInstance as EditPairDialogComponent;
      modal.alert = alert;
    } else {
      const modalRef = this.modalService.open(EditPairDialogComponent, {size: 'xxl'});
      const modal = modalRef.componentInstance as EditPairDialogComponent;
      modal.alert = alert;
    }
  }

  async markAsResolvedClick(alertId: number) {
    const alert = this.alerts.find((x) => x.id === alertId);
    //await firstValueFrom(this.alertService.saveAlert({ ...alert, isResolved: true, resolvedAt: dayjs().utc().toDate(), isActive: false }));
    await firstValueFrom(this.alertService.markAsResolvedAlert({
      ...alert,
      isResolved: true,
      resolvedAt: dayjs().utc().toDate(),
      isActive: false
    }))
    this.fetchAlerts();
  }

  updateFilteredList(): void {
    const search: string = this.searchKeyword;
    this.filteredAlerts.splice(0, this.filteredAlerts.length);
    this.filteredAlerts.push(...this.alerts.filter((x) => (this.alertsToRemind ? (this.alertsToRemind[x.id] == null || this.alertsToRemind[x.id] < dayjs().unix()) : true) && (this.alertService.alertTypes[x.alertTypeId]?.description.toLowerCase().includes(search.toLowerCase()) || x.__leg__?.flightNumber?.toLowerCase().includes(search.toLowerCase()) || x.__pair__?.__arrivalLegModel__?.flightNumber?.toLowerCase()?.includes(search.toLowerCase()) || x.__pair__?.__departureLegModel__?.flightNumber?.toLowerCase()?.includes(search.toLowerCase())) && (this.severityFilters ? this.severityFilters.includes(this.alertService.alertTypes[x.alertTypeId].severity) : true)));
    const sortOrderFactor = this.sortOrder === 'asc' ? 1 : -1;
    this.filteredAlerts.sort((a, b) => (a.occuredAt > b.occuredAt ? 1 : -1) * sortOrderFactor);
  }

  remindLater(data: { id: number; hours: number; minutes: number }) {
    this.alertsToRemind[data.id] = dayjs().add(data.hours, 'hours').add(data.minutes, 'minutes').unix();
    localStorage.setItem('alerts_to_remind', JSON.stringify(this.alertsToRemind));
    this.updateFilteredList();
  }

  alertTrack(index: number, model: IAlertsModel): number {
    return model.id;
  }

  public changeSort() {
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    this.updateFilteredList();
  }
}
