import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faBell, faCheckCircle, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RemindMeDialogComponent } from '../../remind-me-dialog/remind-me-dialog.component';
import { AlertListComponent } from '../alert-list.component';
import { IAlertsModel } from '../../../../shared/models/alerts.model';
import { AlertService } from '../../../../services/alert.service';
import { AlertSeverity } from '../../../../shared/constants/alert-severity.enum';
import dayjs from 'dayjs';
import { PairsService } from '../../../../services/pairs.service';
import { firstValueFrom } from 'rxjs';
import { AcTypesService } from '../../../../services/ac-types.service';
import { IAcType } from '../../../../shared/models/ac-type.model';
import { IPairsModel } from '../../../../shared/models/pairs.model';

@Component({
  selector: 'app-alert-list-new-item',
  templateUrl: './alert-list-new-item.component.html',
  styleUrls: ['./alert-list-new-item.component.scss']
})
export class AlertListNewItemComponent implements OnInit {
  localSeverityEnum = AlertSeverity;
  public isActive = false;
  @Input() timezone: string;
  @Input() activeAlertIndex: number;
  @Input() alert: IAlertsModel;
  @Input() alertList: AlertListComponent;
  @Output() editClicked = new EventEmitter<IAlertsModel>();
  @Output() resolvedClicked = new EventEmitter<number>();
  @Output() remindLaterClick = new EventEmitter<{ id: number; hours: number; minutes: number }>();
  @Output() toggleClicked = new EventEmitter<number>();
  checkIcon = faCheckCircle;
  bellIcon = faBell;
  editIcon = faPenToSquare;
  pairDetails: IPairsModel;
  acTypeModel: IAcType;
  djs = dayjs;

  constructor(private modalService: NgbModal, public alertService: AlertService, public pairService: PairsService, public acTypeService: AcTypesService) {
  }

  ngOnInit(): void {
    if (this.alert.alertTypeId === 3) {
      firstValueFrom(this.pairService.getPairDetail(this.alert.pairId)).then((pair) => {
        this.pairDetails = pair;
      }).catch();
      firstValueFrom(this.acTypeService.fetchAcTypes({ id: this.alert.__pair__.__departureLegModel__?.acTypeId })).then((acType) => {
        this.acTypeModel = acType[0];
      }).catch();
    }
  }

  getBgColor(isActive: boolean): string {
    if (isActive) {
      return 'bg-baby-blue';
    }
    return 'bg-white';
  }

  clicked() {
    this.isActive = !this.isActive;
  }

  edit(evt: MouseEvent): void {
    evt.stopPropagation();
    this.editClicked.emit(this.alert);
  }

  markAsResolve(evt: MouseEvent) {
    evt.stopPropagation();
    this.resolvedClicked.emit(this.alert.id);
  }

  remindMeLaterClicked() {
    const modalRef = this.modalService.open(RemindMeDialogComponent, {size: 'm' as 'm'});
    const modal = modalRef.componentInstance as RemindMeDialogComponent;
    modal.alertList = this.alertList;
    modal.alertId = this.alert.id;
  }

  remindMeLaterConfirm(evt: MouseEvent, hours: number, minutes: number) {
    this.remindLaterClick.emit({id: this.alert.id, hours, minutes});
  }

  // calculateEtdExtension() {
  //   if (this.alert.__pair__.__arrivalLegModel__.etd) {
  //     return ' by ' + dayjs.duration(dayjs(this.alert.__pair__.__arrivalLegModel__.etd).diff(this.alert.__pair__.__arrivalLegModel__.std)).format('HH:mm') + ' hours';
  //   }
  //   return '.';
  // }

  calculateEtdFromEta() {
    if (!this.alert.__pair__.__prevDeparturePairModel__?.__arrivalLegModel__?.eta) {
      return '1. Correct the situation in the planning system';
    }
    const standardText = '1. Issue ETD greater than ETA + min. ground time of Flight ' + this.alert.__pair__.__prevDeparturePairModel__?.__arrivalLegModel__.airlineDesignator.concat(this.alert.__pair__.__prevDeparturePairModel__?.__arrivalLegModel__.flightNumber) + ' i.e. New ETD ';
    const minTurnaroundTime = this.alert.__pair__.__prevDeparturePairModel__?.__arrivalLegModel__?.acTypeModel?.defaultMinTurnaroundTimeInMinutes;
    const newEtd = dayjs(this.alert.__pair__.__prevDeparturePairModel__?.__arrivalLegModel__.eta).utc().add(minTurnaroundTime, 'minutes').format('HH:mm');
    return standardText + newEtd;
  }

  getAffectedSectors() {
    if (this.alert.__leg__) {
      return this.alert.__leg__.airlineDesignator + this.alert.__leg__.flightNumber + '/'  +  dayjs(this.alert.__leg__.std).format('DDMMM').toUpperCase();
    }
    if (this.alert.__pair__) {
      const flightNumbers: string[] = [];
      if (this.alert.__pair__.__arrivalLegModel__) {
        flightNumbers.push(this.alert.__pair__.__arrivalLegModel__.airlineDesignator + this.alert.__pair__.__arrivalLegModel__.flightNumber);
      }
      if (this.alert.__pair__.__departureLegModel__) {
        flightNumbers.push(this.alert.__pair__.__departureLegModel__.airlineDesignator + this.alert.__pair__.__departureLegModel__.flightNumber);
      }
      if(flightNumbers.length === 1) {
        if (this.alert.__pair__.__arrivalLegModel__) {
          return flightNumbers[0] + '/'  +  dayjs(this.alert.__pair__.__arrivalLegModel__.std).format('DDMMM').toUpperCase();
        }
        if (this.alert.__pair__.__departureLegModel__) {
          return flightNumbers[0] + '/'  +  dayjs(this.alert.__pair__.__departureLegModel__.std).format('DDMMM').toUpperCase();
        }
      }
      return flightNumbers.join(' - ');
    }
  }

  calculateNewEtdForInadequate() {
    if (this.pairDetails?.__arrivalLegModel__?.toa && this.acTypeModel?.defaultMinTurnaroundTimeInMinutes) {
      return dayjs(this.pairDetails?.__arrivalLegModel__?.toa).utc().add(this.acTypeModel?.defaultMinTurnaroundTimeInMinutes, 'minutes').format('HH:mm').concat(' or later');
    }
    return '';
  }
}
