import {Component, OnInit} from '@angular/core';
import {faFileCode, faFileCsv, faFilePdf} from '@fortawesome/free-solid-svg-icons';
import {AirportsService} from '../../../services/airports.service';
import {AcTypesService} from '../../../services/ac-types.service';
import {LegsService} from '../../../services/legs.service';
import {PairsService} from '../../../services/pairs.service';
import {FormControl, FormGroup} from '@angular/forms';
import {GeneralSettingsService} from '../../../services/general-settings.service';
import {GseService} from '../../../services/gse.service';
import {LegDelaysLogService} from '../../../services/leg-delays-log.service';
import {PairsProcessesService} from '../../../services/pairs-processes.service';
import {ProcessesService} from '../../../services/processes.service';
import {SeatingConfigurationService} from '../../../services/seating-configuration.service';
import {IAcType} from '../../../shared/models/ac-type.model';
import {IAirport} from '../../../shared/models/airport.model';
import {ILegsModel} from '../../../shared/models/legs.model';
import {IPairsModel} from '../../../shared/models/pairs.model';
import {dayjsToNgbDate, ngbDateToDayjs} from '../../../shared/utils/utils';
import * as dayjs from 'dayjs';
import * as XLSX from 'xlsx/xlsx.mjs';
import {firstValueFrom} from 'rxjs';
import {UserService} from '../../../services/user.service';
import {MemosService} from '../../../services/memos.service';
import {IPairDetail} from "../../../shared/models/pair-detail.model";


@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {

  formGroup: FormGroup;
  airports: IAirport[];
  acTypes: IAcType[];
  legs: ILegsModel[];
  pairs: IPairsModel[];
  filterDayFrom;
  filterDayTo;
  filterStation: string;
  filterAcType: string;
  totalDelayMinutes: number;
  totalFlights: number;
  startFilterDay;
  endFilterDay;

  constructor(
    private airportsService: AirportsService,
    private acTypesService: AcTypesService,
    private legsService: LegsService,
    private pairsService: PairsService,
    private seatConfigService: SeatingConfigurationService,
    private generalSettingsService: GeneralSettingsService,
    private legDelayLogsService: LegDelaysLogService,
    private memosService: MemosService,
    private gseService: GseService,
    private pairProcessesService: PairsProcessesService,
    private processService: ProcessesService,
    private usersService: UserService,
  ) {
  }

  pdfIcon = faFilePdf;
  csvIcon = faFileCsv;
  codeIcon = faFileCode;

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      allData: new FormControl(dayjsToNgbDate(dayjs.utc())),
      flightDetails: new FormControl(null),
      flightPairings: new FormControl(null),
      processes: new FormControl(null),
      gseUtilization: new FormControl(null),
      memos: new FormControl(null),
      turnaroundVsGround: new FormControl(null),
    })
  }


  getFLightDetailsXLS() {
    const defaultStartDate = dayjs.utc(new Date()).startOf('day').format();
    const defaultEndDate = dayjs.utc(new Date()).endOf('day').format();
    const data: any[] = [['Airline Designator', 'Flight Number', 'Ac Registration', 'Ac Type', 'Departing Airport', 'STD', 'ETD', 'ATD', 'TAKE OFF', 'Arriving Airport', 'STA', 'ETA', 'ATA', 'LAND', 'PAX', 'Passenger Gate', 'Aircraft Stand', 'CTOT']];
    let delayMinutesPerCode: { [legId: number]: any } = {};

    if (this.formGroup.get('flightDetails').value === null) {
      //default dates
      this.filterDayFrom = defaultStartDate;
      this.filterDayTo = defaultEndDate;
    } else {
      this.filterDayFrom = this.formGroup.get('flightDetails').value.from !== null ? ngbDateToDayjs(this.formGroup.get('flightDetails').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
      this.filterDayTo = this.formGroup.get('flightDetails').value.to !== null ? ngbDateToDayjs(this.formGroup.get('flightDetails').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
    }

    this.legsService.getLegsForReports({
      startDate: this.filterDayFrom,
      endDate: this.filterDayTo,
      timeType: 'atd'
    }).subscribe(async (result) => {
      this.legs = result;
      const legIdsInLegList = this.legs.map((leg) => leg.id);

      const legDelayLogs = await firstValueFrom(this.legDelayLogsService.getLegDelayLogs({
        isActive: [true],
        legId: legIdsInLegList
      }));
      const legIdsInDelayLogs = legDelayLogs.map((delaylog) => delaylog.legId);
      const delayCodes = await firstValueFrom(this.generalSettingsService.getDelayCodes());
      //const codesInDelayCodesList = delayCodes.map((delayCode) => delayCode.id);

      for (const delayLog of legDelayLogs) {
        const delayCodesWeNeed = delayCodes.filter((delaycode) => delayLog.delayCodeId === delaycode.id);
        delayMinutesPerCode[delayCodesWeNeed[0].code] = {legId: delayLog.legId, minutes: delayLog.minutes}
      }
      console.log('asd :', delayMinutesPerCode);


      const counts: Record<string, number> = {};

      legDelayLogs.forEach(obj => {
        const id = obj.legId.toString();
        counts[id] = (counts[id] || 0) + 1;
      });
      const maxCount = Math.max(...Object.values(counts));
      for (let i = 0; i < maxCount; i++) {
        data[0].push('Delays(Code/Minutes)')
      }
      console.warn(maxCount); // Output: 3
      let delayMinutes;
      for (const leg of this.legs) {
        const foundDelayLog = legDelayLogs.filter((log) => log.legId === leg.id)
        if (foundDelayLog.length === 1) {
          delayMinutes = foundDelayLog[0].minutes;
          const delayCode = delayCodes.filter((delaycode) => legDelayLogs.find((log) => log.legId === leg.id).delayCodeId === delaycode.id)[0].code;
          data.push([leg.airlineDesignator, leg.flightNumber, leg.acRegistration, leg.acType, leg.departureStation, leg.std, leg.etd, leg.atd, leg.takeOffTime, leg.arrivalStation, leg.sta, leg.eta, leg.ata, leg.landingTime, leg.pax, leg.arrivalGate, leg.arrivalAcStand, leg.ctot, delayCode + '/' + delayMinutes])
        } else if (foundDelayLog.length > 1) {
          const row = [leg.airlineDesignator, leg.flightNumber, leg.acRegistration, leg.acType, leg.departureStation, leg.std, leg.etd, leg.atd, leg.takeOffTime, leg.arrivalStation, leg.sta, leg.eta, leg.ata, leg.landingTime, leg.pax, leg.arrivalGate, leg.arrivalAcStand, leg.ctot]
          for (const foundLog of foundDelayLog) {
            delayMinutes = foundLog.minutes;
            const delayCode = delayCodes.filter((delaycode) => legDelayLogs.find((log) => log.legId === leg.id).delayCodeId === delaycode.id)[0].code;
            row.push(delayCode + '/' + delayMinutes)
          }
          data.push(row);
        } else {
          data.push([leg.airlineDesignator, leg.flightNumber, leg.acRegistration, leg.acType, leg.departureStation, leg.std, leg.etd, leg.atd, leg.takeOffTime, leg.arrivalStation, leg.sta, leg.eta, leg.ata, leg.landingTime, leg.pax, leg.arrivalGate, leg.arrivalAcStand, leg.ctot])

        }
      }
      this.createXLSFile(data, "Flight Details", this.filterDayFrom, this.filterDayTo)

    });


  }

  async getFLightPairingsXLS() {
    const defaultStartDate = dayjs.utc(new Date()).startOf('day').format();
    const defaultEndDate = dayjs.utc(new Date()).endOf('day').format();
    const allDelayCodes = await firstValueFrom(this.generalSettingsService.getDelayCodes());
    const pairData: any[] = [['Ac Registration', 'Ac Type', 'Arrival Flight Number', 'STA', 'ETA', 'ATA', 'LAND', 'IN PAX', 'Turnaround Airport', 'Departing Flight Number', 'STD', 'ETD', 'ATD', 'TAKE OFF', 'OUT PAX', 'CTOT', 'Arrival Delays 1(Code/Minutes)', 'Arrival Delays 2', 'Arrival Delays 3', 'Arrival Delays 4', 'Departure Delays 1', 'Departure Delays 2', 'Departure Delays 3', 'Departure Delays 4', 'Process 1', 'Process 2', 'Process 3', 'Process 4', 'Process 5', 'Process 6', 'Process 7', 'Process 8', 'Process 9', 'Process 10', 'Process 11', 'Process 12', 'Process 13', 'Process 14', 'Process 15']];

    if (this.formGroup.get('flightPairings').value === null) {
      //default dates
      this.filterDayFrom = defaultStartDate;
      this.filterDayTo = defaultEndDate;
    } else {
      this.filterDayFrom = this.formGroup.get('flightPairings').value.from !== null ? ngbDateToDayjs(this.formGroup.get('flightPairings').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
      this.filterDayTo = this.formGroup.get('flightPairings').value.to !== null ? ngbDateToDayjs(this.formGroup.get('flightPairings').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
    }

    this.pairsService.getPairsForReports({
      startDate: this.filterDayFrom,
      endDate: this.filterDayTo,
      acType: null,
      station: null,
      withAtd: true
    }).subscribe(async (result) => {
      this.pairs = result;
      const pairIdsInPairList = this.pairs.map((pair) => pair.id);
      const pairDetails = await firstValueFrom(this.pairsService.getPairDetailsByFilter({id: pairIdsInPairList}));
      console.log('pairDetail are : ', pairDetails);
      for (let i = 0; i < pairDetails.length; i++) {
        const pair = pairDetails[i] as any
        pairData.push([
          pair.acRegistration,
          pair.departureLeg.acType,
          pair.arrivalLeg.airlineDesignator + pair.arrivalLeg.flightNumber,
          pair.arrivalLeg.sta,
          pair.arrivalLeg.eta,
          pair.arrivalLeg.ata,
          pair.arrivalLeg.landingTime,
          pair.arrivalLeg.pax,
          pair.airportCode,
          pair.departureLeg.airlineDesignator + pair.departureLeg.flightNumber,
          pair.departureLeg.std,
          pair.departureLeg.etd,
          pair.departureLeg.atd,
          pair.departureLeg.takeOffTime,
          pair.departureLeg.pax,
          pair.departureLeg.ctot,
        ]);

        if (pair.arrivalLeg.arrivalDelays) {
          let arrDelayCount = 0;
          for (let k in pair.arrivalLeg.arrivalDelays) {
            const value2 = pair.arrivalLeg.arrivalDelays[k];
            const code = allDelayCodes.find((dCode) => dCode.id === value2.delayCodeId).code;
            pairData[i + 1].push(code + '/' + value2.minutes)
            arrDelayCount++;
          }
          if (arrDelayCount === 0) {
            pairData[i + 1].push('', '', '', '');
          } else if (arrDelayCount === 1) {
            pairData[i + 1].push('', '', '');
          } else if (arrDelayCount === 2) {
            pairData[i + 1].push('', '');
          } else if (arrDelayCount === 3) {
            pairData[i + 1].push('');
          }
        }

        if (pair.departureLeg.departureDelays) {
          let depDelayCount = 0;
          for (let k in pair.departureLeg.departureDelays) {
            const value2 = pair.departureLeg.departureDelays[k];
            const code = allDelayCodes.find((dCode) => dCode.id === value2.delayCodeId).code;
            pairData[i + 1].push(code + '/' + value2.minutes)
            depDelayCount++;
          }
          if (depDelayCount === 0) {
            pairData[i + 1].push('', '', '', '');
          } else if (depDelayCount === 1) {
            pairData[i + 1].push('', '', '');
          } else if (depDelayCount === 2) {
            pairData[i + 1].push('', '');
          } else if (depDelayCount === 3) {
            pairData[i + 1].push('');
          }
        }
        if (pair.processes.length) {
          for (const process of pair.processes) {
            pairData[i + 1].push(process.title)
          }
        }

      }
      console.log('DATA ', pairData);
      this.createXLSFile(pairData, "Flight Pairings", this.filterDayFrom, this.filterDayTo)

    });
  }

  async getUsersReportXLS() {
    this.usersService.fetchUsers().subscribe((result) => {
      const users = result;
      const data: any[] = [['Username', 'First Name', 'Last Name', 'Location', 'Airline', 'Role', 'Phone', 'Email']];
      for (const user of users) {
        data.push([user.username, user.firstname, user.lastname, user.location, user.airline, user.role, user.phone, user.email])
      }

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(data);
      workbook.SheetNames.push("Users");
      workbook.Sheets["Users"] = worksheet;
      XLSX.writeFile(workbook, "demo.xlsx");
    });
  }

  async getProcessesXLS() {
    const defaultStartDate = dayjs.utc(new Date()).startOf('day').format();
    const defaultEndDate = dayjs.utc(new Date()).endOf('day').format();
    const processesData: any[] = [['Ac Registration', 'Ac Type', 'Arriving Flight Number', 'Departing Flight Number', 'Turnaround Airport', 'Process 1', 'Process 2']];

    if (this.formGroup.get('processes').value === null) {
      //default dates
      this.filterDayFrom = defaultStartDate;
      this.filterDayTo = defaultEndDate;
    } else {
      this.filterDayFrom = this.formGroup.get('processes').value.from !== null ? ngbDateToDayjs(this.formGroup.get('processes').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
      this.filterDayTo = this.formGroup.get('processes').value.to !== null ? ngbDateToDayjs(this.formGroup.get('processes').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
    }

    this.pairsService.getPairsForReports({
      startDate: this.filterDayFrom,
      endDate: this.filterDayTo,
      acType: null,
      station: null,
      withAtd: true
    }).subscribe(async (result) => {
      this.pairs = result;
      const pairIdsInPairList = this.pairs.map((pair) => pair.id);
      const pairProcesses = await firstValueFrom(this.pairProcessesService.getPairsProccessesByPairId(pairIdsInPairList));
      const pairIdsinPairProcessList = pairProcesses.map((process) => process.pairId);
      console.log('Difference in IDS:', pairIdsInPairList, pairIdsinPairProcessList);
      const allProcesses = await firstValueFrom(this.processService.fetchProcesses());
      for (let i = 0; i < this.pairs.length; i++) {
        const pair = this.pairs[i];
        if (pairIdsinPairProcessList.includes(pair.id)) {
          const pairProcessIdsForPair = pairProcesses.filter((p) => p.pairId === pair.id).map((a) => a.processId);
          processesData[i + 1].push([
            pair.acRegistration,
            pair.__arrivalLegModel__.acType,
            pair.__arrivalLegModel__.airlineDesignator + pair.__arrivalLegModel__.flightNumber,
            pair.__departureLegModel__.airlineDesignator + pair.__departureLegModel__.flightNumber,
            pair.__arrivalLegModel__.arrivalStation,
          ])
          for (const pairProcessId of pairProcessIdsForPair) {
            const processName = allProcesses.find((pName) => pName.id === pairProcessId).title;
            processesData[i + 1].push(processName);
          }

        }
      }
      this.createXLSFile(processesData, "List of Processes", this.filterDayFrom, this.filterDayTo)

    });
  }

  async getGseUtilizationXLS() {
    const defaultStartDate = dayjs.utc(new Date()).startOf('day').format();
    const defaultEndDate = dayjs.utc(new Date()).endOf('day').format();
    const gseData: any[] = [['Ac Type', 'Airport', 'Process', 'SLA/Non-SLA', 'Maximum Process Time in Minutes', 'Valid From', 'Valid To']];

    if (this.formGroup.get('gseUtilization').value === null) {
      //default dates
      this.filterDayFrom = defaultStartDate;
      this.filterDayTo = defaultEndDate;
    } else {
      this.filterDayFrom = this.formGroup.get('gseUtilization').value.from !== null ? ngbDateToDayjs(this.formGroup.get('gseUtilization').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
      this.filterDayTo = this.formGroup.get('gseUtilization').value.to !== null ? ngbDateToDayjs(this.formGroup.get('gseUtilization').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
    }

    this.gseService.fetchGses().subscribe(async (result) => {
      const allGses = result;
      const allAirports = await firstValueFrom(this.airportsService.airports);
      const allAcTypes = await firstValueFrom(this.acTypesService.acTypes);

      for (const gse of allGses) {
        if (gse.acTypeId && gse.airportId) {
          const acType = allAcTypes.find((acT) => acT.id === gse.acTypeId).alias;
          const airport = allAirports.find((airp) => airp.id === gse.airportId).iata;

          gseData.push([acType, airport, gse.title, gse.isSla === true ? 'SLA' : 'Non-SLA', gse.maximumProcessTimeInMinutes, gse.validFrom, gse.validTo])
        }
      }
      this.createXLSFile(gseData, "Gse Utilization Per Airport", this.filterDayFrom, this.filterDayTo)

    });
  }

  async getMemosXLS() {

    const defaultStartDate = dayjs.utc(new Date()).startOf('day').format();
    const defaultEndDate = dayjs.utc(new Date()).endOf('day').format();
    const memoData: any[] = [['Flight Number', 'Username', 'Role', 'Content', 'Time',]];

    if (this.formGroup.get('memos').value === null) {
      //default dates
      this.filterDayFrom = defaultStartDate;
      this.filterDayTo = defaultEndDate;
    } else {
      this.filterDayFrom = this.formGroup.get('memos').value.from !== null ? ngbDateToDayjs(this.formGroup.get('memos').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
      this.filterDayTo = this.formGroup.get('memos').value.to !== null ? ngbDateToDayjs(this.formGroup.get('memos').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
    }

    this.legsService.getLegsForReports({
      startDate: this.filterDayFrom,
      endDate: this.filterDayTo,
      timeType: 'atd'
    }).subscribe(async (result) => {
      this.legs = result;
      const allMemos = await firstValueFrom(this.memosService.getMemos());
      const allusers = await firstValueFrom(this.usersService.fetchUsers());
      const legIdsInMemos = allMemos.map((mem) => mem.legId);


      for (const leg of this.legs) {
        if (legIdsInMemos.includes(leg.id)) {
          const memos = allMemos.filter((memo) => memo.legId === leg.id);
          for (const memo of memos) {
            memoData.push([
              leg.airlineDesignator + leg.flightNumber,
              allusers.find((user) => user.id === +memo.lastChangedBy).username,
              allusers.find((user) => user.id === +memo.lastChangedBy).role,
              memo.content,
              memo.lastChangedAt,
            ])
          }

        }
      }
      this.createXLSFile(memoData, "Flight Memos", this.filterDayFrom, this.filterDayTo)

    });
  }

  createXLSFile(data, sheetName, dateFrom, dateTo) {
    const dateNow = dayjs.utc().format("DD/MM/YYYY");
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    workbook.SheetNames.push(sheetName);
    workbook.Sheets[sheetName] = worksheet;
    let fileName: string;
    if (dateTo === null) {
      fileName = dateNow + '_' + sheetName + '_' + dayjs(dateFrom).format('DD/MM/YYYY') + '.xlsx';
    } else {
      fileName = dateNow + '_' + sheetName + '_' + dayjs(dateFrom).format('DD/MM/YYYY') + '-' + dayjs(dateTo).format('DD/MM/YYYY') + '.xlsx';
    }

    XLSX.writeFile(workbook, fileName);
  }

  async getTurnaroundGroundTimeDiffXLS() {
    const dateValue = this.formGroup.get('turnaroundVsGround').value
    const from = dateValue?.from ? ngbDateToDayjs(dateValue.from).startOf('day').toDate() : dayjs.utc().startOf('day').toDate();
    const to = dateValue?.to ? ngbDateToDayjs(dateValue.to).endOf('day').toDate() : dayjs.utc().endOf('day').toDate();
    const data: IPairDetail[] = await firstValueFrom(this.pairsService.getPairDetailsByFilter({
      isActive: true,
      dateOfOperation: {from, to},
    })).catch((err) => {
      console.log('Error fetching pairs for report Turnaround/GroundTime differences');
      return [];
    });
    const filteredData = data.filter((data) => {
      const legTod = dayjs.utc(data.departureLeg.tod);
      return data.turnaround?.currentState === "FINISHED" && legTod.isAfter(from) && legTod.isBefore(to) && data.departureLeg.atd;
    });
    const excelData: string[][] = [['Date', 'Arr. Flight Number', 'Arr. Sector', 'Dep. Flight Number', 'Dep. Sector', 'Arr. ATA', 'Dep. ATD', 'Turnaround Start Time', 'Turnaround Finish Time', 'ATA-ATD', 'Turnaround Time', 'Difference']];
    for (const pair of filteredData) {
      const turnaroundStart = pair.turnaround.turnaroundEvents.reverse().find((evt) => evt.newState === "IN_PROGRESS");
      const turnaroundStartTime = dayjs.utc(turnaroundStart.datetime);
      const turnaroundFinishTime = dayjs.utc(pair.turnaround.lastFinishedAt);
      const ata = pair.arrivalLeg.ata;
      const atd = pair.departureLeg.atd;
      const groundTime = dayjs.utc(atd).diff(ata, 'minutes');
      const turnaroundTime = dayjs.utc(turnaroundFinishTime).diff(turnaroundStartTime, 'minutes', true);
      excelData.push([
        dayjs.utc(pair.dateOfOperation).format(this.generalSettingsService.generalSettings.value.dateFormat),
        pair.arrivalLeg.airlineDesignator + pair.arrivalLeg.flightNumber,
        `${pair.arrivalLeg.departureStation}-${pair.arrivalLeg.arrivalStation}`,
        pair.departureLeg.airlineDesignator + pair.departureLeg.flightNumber,
        `${pair.departureLeg.departureStation}-${pair.departureLeg.arrivalStation}`,
        dayjs.utc(pair.arrivalLeg.toa).format(`HH:mm`),
        dayjs.utc(pair.departureLeg.tod).format(`HH:mm`),
        turnaroundStartTime.format('HH:mm'),
        turnaroundFinishTime.format('HH:mm'),
        dayjs.duration(Math.abs(Math.round(groundTime)), 'minutes').format('HH:mm'),
        dayjs.duration(Math.abs(Math.round(turnaroundTime)), 'minutes').format('HH:mm'),
        dayjs.duration(Math.abs(Math.round(groundTime - turnaroundTime)), 'minutes').format('HH:mm')
      ])
    }
    this.createXLSFile(excelData, "Turnaround & Ground time", from, to)
  }
}
