<div class="flex flex-column mx-3 chatArea">
  <app-chat-header-selectable [user]="user" class="chatHeader" (closeChatAction)="closeChatAction.emit()"
                              [canClose]="canClose"></app-chat-header-selectable>
  <!--  <app-chat-body class="chatBody bg-white"></app-chat-body>-->
  <div class="light-blue-border flex flex-column">
    <app-chat-session class="chatBody" [user]="user" [(chatGroupId)]="chatGroupId" #session></app-chat-session>
    <app-chat-form class="chatForm"
                   [numberOfUnreadMessages]="this.chatService.myMessageGroups[chatGroupId]?.numberOfUnreadMessages"
                   [user]="user" [(chatGroupId)]="chatGroupId" (newMessage)="session.conversation.unshift($event)"
                   [conversation]="session.conversation"></app-chat-form>
  </div>

</div>
