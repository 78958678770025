import { Component, Input, OnInit } from '@angular/core';
import { IAlertsModel } from '../../../../shared/models/alerts.model';
import { IGenericContainerObject } from '../../../../shared/models/genericContainerObject.model';
import { IDelayModel } from '../../../../shared/models/delays.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { ILegsModel } from '../../../../shared/models/legs.model';
import { PairsService } from '../../../../services/pairs.service';
import { ISimpleData } from '../../../../shared/models/simpleData.model';

@Component({
  selector: 'app-no-actual-times-alert-type',
  templateUrl: './no-actual-times-alert-type.component.html',
  styleUrls: ['./no-actual-times-alert-type.component.scss']
})
export class NoActualTimesAlertTypeComponent implements OnInit {
  @Input() alert: IAlertsModel;
  @Input() delayCodes: IDelayModel[];
  @Input() passengerClasses: ISimpleData[];
  leg: ILegsModel;
  constructor(private activeModal: NgbActiveModal, private pairService: PairsService) { }

  ngOnInit(): void {
    this.pairService.getPairsByFilter({ departureLegId: this.alert.legId, isActive: true }).subscribe((result) => {
      if (result[0]) {
        firstValueFrom(this.pairService.getPairDetail(result[0].id)).then((pairDetails) => {
          this.leg = pairDetails.departureLeg;
        });
      }
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

}
