<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()" data-test="change-flight-details-close-button">
  </button>
</div>
<app-tabs-navigation [tabs]="tabs" (tabChanged)="activeTab = $event"></app-tabs-navigation>
<div class="modal-body">
  <ng-container *ngIf="dataComplete">
    <ng-container *ngIf="activeTab === flightDetailsConstants.ARRIVING_FLIGHT_INFORMATION">
      <app-change-flights-arriving-flight-information
        [leg]="pair?.arrivalLeg"
        [timezone]="timezone"
        [pair]="pair"
        [delayCodes]="delayCodes"
        [passengerClasses]="passengerClasses">
      </app-change-flights-arriving-flight-information>
    </ng-container>
    <ng-container *ngIf="activeTab === flightDetailsConstants.DEPARTING_FLIGHT_INFORMATION">
      <app-change-flights-departing-flight-information
        [leg]="pair?.departureLeg"
        [timezone]="timezone"
        [delayCodes]="delayCodes"
        [pair]="pair"
        [passengerClasses]="passengerClasses"
      ></app-change-flights-departing-flight-information>
    </ng-container>
    <ng-container *ngIf="activeTab === flightDetailsConstants.TURNAROUND_PROCESSES_AND_GSES">
      <app-change-flights-turnaround-processes
        [pairId]="pair?.id"
        [airportId]="pair?.arrivalLeg?.arrivalStationId ? pair?.arrivalLeg?.arrivalStationId : pair?.departureLeg?.departureStationId"
        [acTypeId]="pair?.arrivalLeg?.acTypeId ? pair?.arrivalLeg?.acTypeId : pair?.departureLeg?.acTypeId"
      ></app-change-flights-turnaround-processes>
    </ng-container>
  </ng-container>
</div>
