<div class="flex flex-column dark-blue">
  <app-settings-header text="Flight Schedule Management" [showSaveButton]="false" [textSize]="20"></app-settings-header>
  <app-settings-card sectionTitle="Upload SSIM">
    <div class="flex flex-column gap-2 mt-3">
      <div class="settings-row font-size-20">
        <div class="custom-file">
          <input type="file" class="form-control" id="file" (change)="handleFileInput($event.target.files)" data-test="ssim-upload-file-input">
          <!--        <label class="custom-file-label" for="file">{{(ssimFile) ? ssimFile.name : 'Choose file'}}</label>-->
        </div>
      </div>

      <div class="settings-row fs-16 align-items-center">
        <div class="flex-basis-40 fs-16">
          <div class="form-check form-check-inline align-items-baseline">
            <input class="form-check-input" type="radio" name="delimitFlights" id="unlimited" [value]="false" [(ngModel)]="delimitFlights" data-test="ssim-upload-delimit-false-check">
            <label class="form-check-label" for="unlimited">Select period as defined in SSIM file</label>
          </div>
        </div>
        <div class="flex-basis-40 fs-16">
          <div class="form-check form-check-inline align-items-baseline">
            <input class="form-check-input" type="radio" name="delimitFlights" id="delimited" [value]="true" [(ngModel)]="delimitFlights" data-test="ssim-upload-delimit-true-check">
            <label class="form-check-label" for="delimited">Select period manually</label>
          </div>
        </div>

        <button type="button" class="btn add-button button-blue flex-basis-20 border-radius-5" *ngIf="!delimitFlights" [disabled]="!isReady" (click)="openValidationView()" data-test="ssim-upload-proceed-button">
          Proceed
        </button>
      </div>
      <div class="settings-row" *ngIf="delimitFlights">

        <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                        outsideDays="hidden" data-test="ssim-upload-delimit-date-picker">
        </ngb-datepicker>

        <ng-template #t let-date let-focused="focused">
  <span class="custom-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
        </ng-template>
      </div>
      <div class="settings-row" *ngIf="delimitFlights">
        <div class="flex-basis-80">
          From: {{ fromDate ? fromDate.day + '.' + fromDate.month + '.' + fromDate.year : 'Please select a date' }} -
          To: {{ toDate ? toDate.day + '.' + toDate.month + '.' + toDate.year : 'Please select a date'}}
        </div>

        <button type="button" class="btn add-button button-blue flex-basis-20 border-radius-5" [disabled]="!isReady" (click)="openValidationView()" data-test="ssim-upload-proceed-button-2">
          Proceed
        </button>
      </div>
    </div>
  </app-settings-card>
</div>



