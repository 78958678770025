<ng-container *ngIf="loaded">
<app-settings-header text="A/C Type Management" [showSaveButton]="false" [textSize]="20"></app-settings-header>
<app-settings-card (onAddClicked)="doAdd()" sectionTitle="A/C Types" addText="Add Aircraft Type" data-test="ac-types-card">
  <app-settings-data-table [testTagPipe]="AcTypesTestTagPipe" class="mt-3" [columns]="['Aircraft Type','IATA Code', 'ICAO Code', 'Description', 'Calibration', 'Fire Category']" [data]="this.acTypes" [variableNames]="['alias', 'iata', 'icao', 'description', 'calibration', 'fireCategory']" (onEditClicked)="doEdit($event)" (onDeleteClicked)="doDelete($event)">
  </app-settings-data-table>
</app-settings-card>

<app-settings-card sectionTitle="Seating Configurations" data-test="seating-configurations-card">
  <div class="flex flex-column dark-blue gap-3" *ngIf="activeAcTypeId">
    <div class="flex flex-column gap-2">
      <div class="ac-type">Aircraft ICAO Code</div>
      <div class="ac-select">
        <select class="form-control form-select light-blue-border" [(ngModel)]="selectedAcType" (ngModelChange)="selectedAcTypeChanged()" data-test="ac-type-select">
          <option *ngFor="let acType of acTypes" [ngValue]="acType" [attr.data-test]="'ac-type-option-' + acType.alias">{{ acType.icao }}</option>
        </select>
      </div>
    </div>
    <div class="overflow-auto avitium-scrollbar">
      <table class="table table-striped turnaround-table dark-blue">
        <thead>
        <tr>
          <th scope="col" *ngFor="let pClass of passengerClasses">
            {{pClass?.code}}
          </th>
          <th scope="col">
            Valid from
          </th>
          <th scope="col">
            Valid To
          </th>
          <th scope="col">
            Operated by
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let seat of Object.keys(seatingConfigurationsForSelectedAcType)" [attr.data-test]="seatingConfigurationsForSelectedAcType | seatingConfigurationsForAcTypesTestTag:+seat">
          <td *ngFor="let pClass of passengerClasses">
            {{ getNumberForSeatsForCode(+seat, pClass.code) }}
          </td>
          <td>{{ seatingConfigurationsForSelectedAcType[seat]?.length && seatingConfigurationsForSelectedAcType[seat][0].validFrom ?  dayjs.utc(seatingConfigurationsForSelectedAcType[seat][0].validFrom).format(dateFormat) : '-' }}</td>
          <td>{{ seatingConfigurationsForSelectedAcType[seat]?.length && seatingConfigurationsForSelectedAcType[seat][0].validTo ?  dayjs.utc(seatingConfigurationsForSelectedAcType[seat][0].validTo).format(dateFormat) : '-' }}</td>
          <td>{{ seatingConfigurationsForSelectedAcType[seat]?.length ? getDesignatorNameFromId(seatingConfigurationsForSelectedAcType[seat][0].operatedBy) : '-' }}</td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</app-settings-card>

<app-settings-card sectionTitle="Turnaround Times: Domestic to Domestic" data-test="tt-domdom-card">
  <div class="flex flex-column dark-blue gap-3" *ngIf="DOMDOM[activeAcTypeId] && flightServiceTypes?.length">
    <div class="flex flex-column gap-2">
      <div class="ac-type">Aircraft ICAO Code</div>
      <div class="ac-select">
        <select class="form-control form-select light-blue-border" [(ngModel)]="selectedAcType" (ngModelChange)="selectedAcTypeChanged()" data-test="ac-type-select">
          <option *ngFor="let acType of acTypes" [ngValue]="acType" [attr.data-test]="'ac-type-option-' + acType.alias">{{ acType.icao }}</option>
        </select>
      </div>
    </div>
    <div class="overflow-auto avitium-scrollbar">
      <table class="table table-striped turnaround-table dark-blue">
        <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" *ngFor="let flightServiceType of flightServiceTypes">
            Outbound {{flightServiceType?.code}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let flightServiceType of flightServiceTypes" [attr.data-test]="'inbound-' + flightServiceType?.code">
          <td class="text-center">Inbound {{flightServiceType?.code}}</td>
          <td *ngFor="let serviceType of flightServiceTypes" [attr.data-test]="'outbound-' + serviceType?.code">
            {{ DOMDOM?.[activeAcTypeId]?.[flightServiceType?.id]?.[serviceType?.id] | timeFromMinutes }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</app-settings-card>

<app-settings-card sectionTitle="Turnaround Times: Domestic to International" data-test="tt-domint-card">
  <div class="flex flex-column dark-blue gap-3" *ngIf="DOMINT[activeAcTypeId]">
    <div class="flex flex-column gap-2">
      <div class="ac-type">Aircraft ICAO Code</div>
      <div class="ac-select">
        <select class="form-control form-select light-blue-border" [(ngModel)]="selectedAcType" (ngModelChange)="selectedAcTypeChanged()" data-test="ac-type-select">
          <option *ngFor="let acType of acTypes" [ngValue]="acType" [attr.data-test]="'ac-type-option-' + acType.alias">{{ acType.icao }}</option>
        </select>
      </div>
    </div>

    <div class="overflow-auto avitium-scrollbar">
      <table class="table table-striped turnaround-table dark-blue">
        <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" *ngFor="let flightServiceType of flightServiceTypes">
            Outbound {{flightServiceType?.code}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let flightServiceType of flightServiceTypes" [attr.data-test]="'inbound-' + flightServiceType?.code">
          <td class="text-center">Inbound {{flightServiceType?.code}}</td>
          <td *ngFor="let serviceType of flightServiceTypes" [attr.data-test]="'outbound-' + serviceType?.code">
            {{ DOMINT?.[activeAcTypeId]?.[flightServiceType?.id]?.[serviceType?.id] | timeFromMinutes }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</app-settings-card>




<app-settings-card sectionTitle="Turnaround Times: International to International" data-test="tt-intint-card">
  <div class="flex flex-column dark-blue gap-3" *ngIf="INTINT[activeAcTypeId]">
    <div class="flex flex-column gap-2">
      <div class="ac-type">Aircraft ICAO Code</div>
      <div class="ac-select">
        <select class="form-control form-select light-blue-border" [(ngModel)]="selectedAcType" (ngModelChange)="selectedAcTypeChanged()" data-test="ac-type-select">
          <option *ngFor="let acType of acTypes" [ngValue]="acType" [attr.data-test]="'ac-type-option-' + acType.alias">{{ acType.icao }}</option>
        </select>
      </div>
    </div>

    <div class="overflow-auto avitium-scrollbar">
      <table class="table table-striped turnaround-table dark-blue">
        <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" *ngFor="let flightServiceType of flightServiceTypes">
            Outbound {{flightServiceType?.code}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let flightServiceType of flightServiceTypes" [attr.data-test]="'inbound-' + flightServiceType?.code">
          <td class="text-center">Inbound {{flightServiceType?.code}}</td>
          <td *ngFor="let serviceType of flightServiceTypes" [attr.data-test]="'outbound-' + serviceType?.code">
            {{ INTINT?.[activeAcTypeId]?.[flightServiceType?.id]?.[serviceType?.id] | timeFromMinutes }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</app-settings-card>

<app-settings-card sectionTitle="Turnaround Times: International to Domestic" data-test="tt-intdom-card">
  <div class="flex flex-column dark-blue gap-3" *ngIf="INTDOM[activeAcTypeId]">
    <div class="flex flex-column gap-2">
      <div class="ac-type">Aircraft ICAO Code</div>
      <div class="ac-select">
        <select class="form-control form-select light-blue-border" [(ngModel)]="selectedAcType" (ngModelChange)="selectedAcTypeChanged()" data-test="ac-type-select">
          <option *ngFor="let acType of acTypes" [ngValue]="acType" [attr.data-test]="'ac-type-option-' + acType.alias">{{ acType.icao }}</option>
        </select>
      </div>
    </div>

    <div class="overflow-auto avitium-scrollbar">
      <table class="table table-striped turnaround-table dark-blue">
        <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" *ngFor="let flightServiceType of flightServiceTypes">
            Outbound {{flightServiceType?.code}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let flightServiceType of flightServiceTypes" [attr.data-test]="'inbound-' + flightServiceType?.code">
          <td class="text-center">Inbound {{flightServiceType?.code}}</td>
          <td *ngFor="let serviceType of flightServiceTypes" [attr.data-test]="'outbound-' + serviceType?.code">
            {{ INTDOM?.[activeAcTypeId]?.[flightServiceType?.id]?.[serviceType?.id] | timeFromMinutes }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</app-settings-card>
</ng-container>
