/* tslint:disable:max-line-length */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ILeg } from '../../../models/leg.model';
import { ISimpleData } from '../../../models/simpleData.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IGenericContainerObject } from '../../../models/genericContainerObject.model';
import { TimeFromMinutesPipe } from '../../../pipes/time-from-minutes.pipe';
import { MinutesFromTimePipe } from '../../../pipes/minutes-from-time.pipe';
import { FlightsChangeFlightDetailsArrivalDepartureFlightFormBuilder } from '../../formBuilders/flights/flights-change-flight-details-arrival-departure-flight-form-builder';
import * as dayjs from 'dayjs';
import { debounceTime, firstValueFrom, Observable, Subject } from 'rxjs';
import { ILegsModel } from 'src/app/shared/models/legs.model';
import { ToastService } from 'src/app/services/toast.service';
import { LegsService } from 'src/app/services/legs.service';
import { calculateETA, dayjsToNgbDate, extractPassengersPerCategory, extractSeatingConfigurations, getLatestLegTime, getTimestamp, minutesToHHmm, ngbDateToDayjs, ngbDateToFormat } from '../../../utils/utils';
import { PairsService } from '../../../../services/pairs.service';
import { IPairDetail } from '../../../models/pair-detail.model';
import { GeneralSettingsService } from '../../../../services/general-settings.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ILegDelaysLogBulkModel, ILegDelaysLogModel } from '../../../models/leg-delays-log.model';
import { ITimeTypesModel } from '../../../models/time-types.model';
import { TimeTypesService } from '../../../../services/time-types.service';
import { takeUntil } from 'rxjs/operators';
import { LegDelaysLogService } from '../../../../services/leg-delays-log.service';
import { PairLegTimesLogService } from '../../../../services/pair-leg-times-log.service';
import { ReturnToRampAirborneDialogComponent } from '../../../components/return-to-ramp-airborne-dialog/return-to-ramp-airborne-dialog.component';
import { AuthService } from '../../../../services/auth.service';
import { ITransferFile } from '../../../models/transfer-file.model';
import { TransferFileService } from '../../../../services/transfer-file.service';
import { IPassengerCategory } from '../../../models/passenger-categories.model';

@Component({
  selector: 'app-change-flights-departing-flight-information',
  templateUrl: './change-flights-departing-flight-information.component.html',
  styleUrls: ['./change-flights-departing-flight-information.component.scss']
})
export class ChangeFlightsDepartingFlightInformationComponent implements OnInit, OnDestroy {

  _leg: ILegsModel | null | undefined;
  @Input() pair?: IPairDetail;

  get leg(): ILegsModel | any {
    return this._leg || null;
  }

  @Input('leg')
  set leg(val: ILegsModel | undefined) {
    if (!val) {
      this._leg = {};
    } else {
      this._leg = val ? val : {};
    }
  }

  @Input() timezone?: string;
  @Input() delayCodes?: ISimpleData[];
  delayCodesKV?: IGenericContainerObject<ISimpleData> = {};

  _passengerClasses: ISimpleData[] = [];
  get passengerClasses(): ISimpleData[] {
    return this._passengerClasses;
  }

  @Input('passengerClasses')
  set passengerClasses(val: ISimpleData[] | null) {
    if (!val) {
      this._passengerClasses = [];
    } else {
      this._passengerClasses = val;
    }
  }

  form?: FormGroup;
  formRestoreValue: any;
  paxContainer: IGenericContainerObject<ISimpleData> = {};
  dateFormat = 'DD/MM/YYYY';
  isBusy = false;
  disabled = false;
  timeTypes: IGenericContainerObject<ITimeTypesModel> = {};
  maxArrivalDelayMinutes = 0;
  maxDepartureDelayMinutes = 0;
  currentArrivalDelayMinutes = 0;
  currentDepartureDelayMinutes = 0;
  unsubscribe = new Subject();
  calculateDelaySubject = new Subject<void>();
  calculate$ = this.calculateDelaySubject.pipe(debounceTime(100));
  seatingConfigurations: IGenericContainerObject<string> = {};
  delaysToBeSaved;
  passengerCategories: IPassengerCategory[];

  constructor(private fb: FormBuilder,
              private timeFromMinutesPipe: TimeFromMinutesPipe,
              private legService: LegsService,
              private minutesFromTimePipe: MinutesFromTimePipe,
              private authService: AuthService,
              private toastService: ToastService,
              private pairsService: PairsService,
              private generalSettingsService: GeneralSettingsService,
              private activeModal: NgbActiveModal,
              private legDelayService: LegDelaysLogService,
              private timeTypesService: TimeTypesService,
              private modalService: NgbModal,
              private pairLegTimesLogs: PairLegTimesLogService,
              private transferFileService: TransferFileService) {}

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
    this.calculateDelaySubject.complete();
  }

  calculateMaxCurrentDelays(autoUpdate = true)
  {
    this.currentArrivalDelayMinutes = 0;
    this.currentDepartureDelayMinutes = 0;
    this.maxArrivalDelayMinutes = 0;
    this.maxDepartureDelayMinutes = 0;
    if(this.form.getRawValue().atdDate && this.form.getRawValue().atdTime) {
      const time = this.form.getRawValue().atdTime.split(':');
      this.maxDepartureDelayMinutes = ngbDateToDayjs(this.form.getRawValue().atdDate).utc(true).hour(time[0]).minute(time[1]).diff(dayjs(this.leg.std).utc(), 'minutes');
    } else if (this.leg.etd) {
      this.maxDepartureDelayMinutes = dayjs(this.leg.etd).diff(dayjs(this.leg.std).utc(), 'minutes');
    }
    if(this.form.getRawValue().ataTime) {
      const atdTime: string[] = this.form.getRawValue().atdTime?.split(':');
      let atdDate = ngbDateToDayjs(this.form.getRawValue()?.atdDate);
      if (atdTime?.length === 2 && atdDate.isValid())
      {
        atdDate = atdDate.hour(+atdTime[0]).minute(+atdTime[1]);
      } else {
        atdDate = dayjs.utc();
      }
      const estimatedAta = this.calcApplicableButPossibleNextDayDateTime(this.form.getRawValue().ataTime, atdDate);
      this.maxArrivalDelayMinutes = estimatedAta.diff(dayjs.utc(this.leg.sta), 'minutes');
    }
    for (const delay of Object.values(this.form.getRawValue().arrivalDelaysNew) as any[]) {
      if (!delay?.time) {
        continue;
      }
      const minutes = typeof delay.time === 'number' ? delay.time : this.minutesFromTimePipe.transform(delay.time);
      this.currentArrivalDelayMinutes += minutes;
    }
    for (const delay of Object.values(this.form.getRawValue().departureDelaysNew) as any[]) {
      if (!delay?.time) {
        continue;
      }
      const minutes = typeof delay.time === 'number' ? delay.time : this.minutesFromTimePipe.transform(delay.time);
      this.currentDepartureDelayMinutes += minutes;
    }
    if (!autoUpdate) {
      return;
    }
    let dataChanged = false;
    if (this.maxArrivalDelayMinutes !== this.currentArrivalDelayMinutes && this.maxArrivalDelayMinutes > 0) {
      this.form.get('arrivalDelaysNew').patchValue({
        arrival1: {
          delayCode: this.form.get('arrivalDelaysNew').value.arrival1.delayCode || Object.values(this.delayCodes).filter((item) => item.code === '99')[0].id,
          time: this.maxArrivalDelayMinutes,
        }
      });
      dataChanged = true;
    }
    if (this.maxDepartureDelayMinutes !== this.currentDepartureDelayMinutes && this.maxDepartureDelayMinutes > 0) {
      this.form.get('departureDelaysNew').patchValue({
        departure1: {
          delayCode: this.form.get('departureDelaysNew').value.departure1.delayCode || Object.values(this.delayCodes).filter((item) => item.code === '99')[0].id,
          time: this.maxDepartureDelayMinutes,
        }
      });
      dataChanged = true;
    }
    if(dataChanged) {
      this.calculateMaxCurrentDelays(false);
    }
  }

  actualTimeChanged(arrivalSide: boolean) {
    if(arrivalSide) {
      this.form.get('arrivalDelaysNew').patchValue({
        arrival1: {
          time: '',
        },
        arrival2: {
          time: '',
        }
      });
      return;
    }
    //this.form.get('departureDelaysNew').reset();
    this.form.get('departureDelaysNew').patchValue({
      departure1: {
        time: '',
      },
      departure2: {
        time: '',
      },
      departure3: {
        time: '',
      },
      departure4: {
        time: '',
      }
    });
  }

  getRemainingDelay() {
    this.calculateMaxCurrentDelays(false);
    let dataChanged = false;
    if(this.maxDepartureDelayMinutes > this.currentDepartureDelayMinutes) {
      console.log('We need more minutes!');
      const departureTimeInputs = (this.form.get('departureDelaysNew') as FormGroup).controls;
      let foundFreeCell = false;
      for (const input of Object.values(departureTimeInputs)) {
        console.log('Checking', input, 'for its value(', input?.value, ') [', input?.value?.time, ']');
        if (!input?.value?.time?.length || input.invalid || input.value.time === '00:00') {
          input.patchValue({ time: this.maxDepartureDelayMinutes - this.currentDepartureDelayMinutes });
          foundFreeCell = true;
          break;
        }
      }
      if (!foundFreeCell) {
        departureTimeInputs['departure4'].patchValue({ time: this.maxDepartureDelayMinutes - this.currentDepartureDelayMinutes + this.minutesFromTimePipe.transform(departureTimeInputs['departure4'].value.time) });
      }
      dataChanged = true;
    }
    if(this.maxArrivalDelayMinutes > this.currentArrivalDelayMinutes) {
      dataChanged = true;
      const arrivalTimeInputs = (this.form.get('arrivalDelaysNew') as FormGroup).controls;
      let foundFreeCell = false;
      for (const input of Object.values(arrivalTimeInputs)) {
        if (!input?.value?.time?.length || input.invalid || input.value.time === '00:00') {
          input.patchValue({ time: this.maxArrivalDelayMinutes - this.currentArrivalDelayMinutes });
          foundFreeCell = true;
          break;
        }
      }
      if (!foundFreeCell) {
        arrivalTimeInputs['arrival2'].patchValue({ time: this.maxArrivalDelayMinutes - this.currentArrivalDelayMinutes + this.minutesFromTimePipe.transform(arrivalTimeInputs['arrival2'].value.time) });
      }
    }
    if (dataChanged) {
      this.calculateMaxCurrentDelays(false);
    }
  }

  ngOnInit() {
    this.generalSettingsService.getPassengerCategories().subscribe((results) => {
      this.passengerCategories = results;
      if (!this.form) {
        this.prepareForm();
      }
    });
    if (this.delayCodes?.length) {
      for (const delayCode of this.delayCodes) {
        this.delayCodesKV[delayCode.id] = delayCode;
      }
    }
    this.calculate$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.calculateMaxCurrentDelays();
    });
    this.generalSettingsService.generalSettings.pipe(takeUntil(this.unsubscribe)).subscribe((settings) => {
      this.dateFormat = settings?.dateFormat || 'DD/MM/YYYY';
    });
    const station = this.authService.user.location;
    if (this.authService.user.role  === 'DUTY_MANAGER') {
      this.disabled = station !== this.leg.arrivalStation;
    }
    if (this.leg && this.passengerClasses && this.passengerCategories) {
      this.prepareForm();
    }
  }

  prepareForm() {
    this.createForm();
    this.form.get('atdDate').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() =>
    {
      this.actualTimeChanged(false);
      this.calculateDelaySubject.next();
    });
    this.form.get('atdTime').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.actualTimeChanged(false);
      this.calculateDelaySubject.next();
    });
    this.form.get('etdDate').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.calculateDelaySubject.next());
    this.form.get('etdTime').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.calculateDelaySubject.next());
    this.form.get('ataTime').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.actualTimeChanged(true);
      this.calculateDelaySubject.next();
    });
    const seatConfigs = extractSeatingConfigurations(this._leg.seatingConfiguration);

    seatConfigs.forEach((item: string) => {
      const code = item.slice(0, 1);
      const amount = item.slice(1);
      this.seatingConfigurations[code] = amount;
    });
    this.paxContainer = this.calcPaxData(this.leg ? this.leg.pax : null);
    console.log('LEG PAX:', this.leg.pax);
    this.patchForm();
    this.formRestoreValue = this.form?.getRawValue();
    this.form.updateValueAndValidity();
  }

  createForm() {
    if (!this.leg || !this.passengerClasses) {
      return;
    }
    this.form = FlightsChangeFlightDetailsArrivalDepartureFlightFormBuilder.constructForm(
      this.fb,
      this.timeFromMinutesPipe,
      this.passengerClasses,
      this.passengerCategories
    );

    this.paxContainer = this.calcPaxData(this.leg ? this.leg.pax : null);
    this.patchForm();
    console.log(this.form);
  }

  calcPaxData(seatConfig: string | null | undefined): IGenericContainerObject<ISimpleData> {

    const paxContainer: IGenericContainerObject<ISimpleData> = {};
    if (!seatConfig) {
      return paxContainer;
    }

    const passClasses = this.passengerClasses ? this.passengerClasses : [];

    const seatConfigs = extractSeatingConfigurations(seatConfig);

    seatConfigs.forEach((item: string) => {
      const code = item.slice(0, 1);
      const amount = item.slice(1);

      const title = passClasses.find((cls) => cls.code === code)?.code;

      if (title) {
        paxContainer[code] = {code, description: amount};
      }
    });

    return paxContainer;
  }

  patchForm() {
    if (!this.leg) {
      return;
    }

    const etdMoment = this.leg.etd ? dayjs.utc(this.leg.etd) : null;
    const atdMoment = this.leg.atd ? dayjs.utc(this.leg.atd) : null;
    const totMoment = this.leg.takeOffTime ? dayjs.utc(this.leg.takeOffTime) : null;
    const etaMoment = this.leg.eta ? dayjs.utc(this.leg.eta) : null;
    const lndMoment = this.leg.landingTime ? dayjs.utc(this.leg.landingTime) : null;
    const ataMoment = this.leg.ata ? dayjs.utc(this.leg.ata) : null;
    const ctotMoment = this.leg.ctot ? dayjs.utc(this.leg.ctot) : null;
    const niMoment = this.leg.ni ? dayjs.utc(this.leg.ni) : null;

    this.form?.patchValue({
      etdDate: etdMoment && etdMoment.isValid() ? dayjsToNgbDate(etdMoment) : null,
      etdTime: etdMoment && etdMoment.isValid() ? etdMoment.format('HH:mm') : null,
      atdDate: atdMoment && atdMoment.isValid() ? dayjsToNgbDate(atdMoment) : dayjsToNgbDate(dayjs()),
      atdTime: atdMoment && atdMoment.isValid() ? atdMoment.format('HH:mm') : null,
      totTime: totMoment && totMoment.isValid() ? totMoment.format('HH:mm') : null,
      etaTime: etaMoment && etaMoment.isValid() ? etaMoment.format('HH:mm') : null,
      lndTime: lndMoment && lndMoment.isValid() ? lndMoment.format('HH:mm') : null,
      ataTime: ataMoment && ataMoment.isValid() ? ataMoment.format('HH:mm') : null,
      ctotTime: ctotMoment && ctotMoment.isValid() ? ctotMoment.format('HH:mm') : null,
      niTime: niMoment && niMoment.isValid() ? niMoment.format('HH:mm') : null,
      actualPassengers: this.paxContainer,
      estimatedPassengers: this.calcPaxData(this.leg ? this.leg.estimatedPassengers : null),
      gate: this.leg.departureGate,
      stand: this.leg.departureAcStand
    });

    if(this.leg?.arrivalDelays)
    {
      const arrDelays: ILegDelaysLogModel[] = Object.values(this.leg.arrivalDelays);
      for (let i= 0; i < Math.min(arrDelays.length, 2); i++) {
        const delay = arrDelays[i];
        const ctrl = (this.form?.get("arrivalDelaysNew").get('arrival' + (i+1)) as FormGroup);
        ctrl.patchValue({
          delayCode: delay.delayCodeId,
          time: delay.minutes,
        });
      }
    }
    if(this.leg?.departureDelays)
    {
      const depDelays: ILegDelaysLogModel[] = Object.values(this.leg.departureDelays);
      for (let i= 0; i < Math.min(depDelays.length, 4); i++) {
        const delay = depDelays[i];
        const ctrl = (this.form?.get("departureDelaysNew").get('departure' + (i+1)) as FormGroup);
        ctrl.patchValue({
          delayCode: delay.delayCodeId,
          time: delay.minutes,
        });
      }
    }

    if (this._leg?.passengerCategories?.length) {
      const passengerData = extractPassengersPerCategory(this._leg.passengerCategories);
      for (const data of passengerData) {
        const subForm: FormGroup = this.form.get('categories').get(data.code) as FormGroup;
        subForm?.patchValue({ amount: data.amount });
      }
    }

    if (!this.formRestoreValue) {
      this.formRestoreValue = this.form?.getRawValue();
    }
    if (!this.leg.eta) {
      this.localCalculateETA();
    }
  }

  localCalculateETA()
  {
    const eta = calculateETA(this.leg, this.form.getRawValue().atdDate, this.form.getRawValue().atdTime ?? null, this.form.getRawValue().totTime ?? null, this.leg?.etd ? dayjs(this.leg.etd).toDate() : null);
    if (eta && (this.form.get('atdTime').value || this.form.get('etdTime').value) ) {
      this.form.get('etaTime').setValue(eta);
    } else {
      this.form.get('etaTime').setValue(null);
    }
  }

  restoreForm() {
    this.createForm();
    this.form.get('atdDate').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() =>
    {
      this.actualTimeChanged(false);
      this.calculateDelaySubject.next();
    });
    this.form.get('atdTime').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.actualTimeChanged(false);
      this.calculateDelaySubject.next();
    });
    this.form.get('etdDate').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.calculateDelaySubject.next());
    this.form.get('etdTime').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.calculateDelaySubject.next());
    this.form.get('ataTime').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.actualTimeChanged(true);
      this.calculateDelaySubject.next();
    });

    this.form?.patchValue(this.formRestoreValue);
    this.calculateDelaySubject.next();
  }

  async saveForm() {
    if (this.form?.invalid || this.maxDepartureDelayMinutes > 0 && this.currentDepartureDelayMinutes > this.maxDepartureDelayMinutes || this.maxArrivalDelayMinutes > 0 && this.currentArrivalDelayMinutes !== this.maxArrivalDelayMinutes) {
      console.log('form is invalid');
      return;
    }
    this.isBusy = true;

    const newLeg: ILegsModel | undefined = this.generateLegFromForm(this.form);

    if (!newLeg) {
      console.log('new leg is empty');
      this.isBusy = false;
      return;
    }

    const timeTypeResult = await firstValueFrom(this.timeTypesService.getTimeTypes());
    if (!timeTypeResult.length) {
      this.toastService.showError('Could not get time types from the database. Please try again.');
      return;
    }
    for (const timeType of timeTypeResult) {
      this.timeTypes[timeType.identifier] = timeType;
    }

    if(newLeg.eta && dayjs(newLeg.eta).isBefore(dayjs(getLatestLegTime(newLeg, false)))) {
      newLeg.eta = dayjs(newLeg.eta).add(1, 'day').toDate();
    }

    if (newLeg.ata && newLeg.atd && dayjs(newLeg.ata).isBefore(newLeg.atd)) {
      newLeg.ata = dayjs(newLeg.ata).add(1, 'day').toDate();
    }

    const result = await firstValueFrom(this.legService.saveLeg(newLeg));
    if(result) {
      this.toastService.showSuccess("The leg details have been saved");
    }

    this.saveDelays(result).subscribe((result) => {
      if(result) {
        this.createMVT(newLeg);
        this.toastService.showSuccess("Leg delays have been saved");
      }
      this.isBusy = false;
      this.activeModal.dismiss('repair');
    });
  }

  saveDelays(leg: ILegsModel): Observable<ILegDelaysLogBulkModel> {
    const objToSend: ILegDelaysLogBulkModel = {
      legId: leg.id,
      arrivalDelays: [],
      departureDelays: [],
    };
    for (const key in this.form.getRawValue().arrivalDelaysNew) {
      const val = this.form.getRawValue().arrivalDelaysNew[key];
      if (val.time && val.delayCode) {
        objToSend.arrivalDelays.push({
          delayCodeId: Number(val.delayCode),
          minutes: this.minutesFromTimePipe.transform(val.time),
        });
      }
    }
    for (const key in this.form.getRawValue().departureDelaysNew) {
      const val = this.form.getRawValue().departureDelaysNew[key];
      if (val.time && val.delayCode) {
        objToSend.departureDelays.push({
          delayCodeId: Number(val.delayCode),
          minutes: this.minutesFromTimePipe.transform(val.time),
        });
      }
    }
    this.delaysToBeSaved = objToSend;
    return this.legDelayService.saveLegDelayLogBulk(objToSend);
  }

  async createMVT(leg: ILegsModel) {
    const lineSeperator = '\n'
    let contentOfAD: string;
    let contentOfAA: string;
    let delaysText = '';
    let paxAmount = 0;
    console.log('Old atd : ', this.leg.atd);
    console.log('New atd : ', leg.atd);
    if (!dayjs(this.leg.atd).isSame(dayjs(leg.atd)) && leg.takeOffTime !== null) {
      //Check for delays
      if (this.delaysToBeSaved.departureDelays.length) {
        let delayCodeDep = '';
        let delayMinutesDep = ''
        for (const delay of this.delaysToBeSaved.departureDelays) {
          delayCodeDep = delayCodeDep + this.delayCodes.find((dl) => dl.id === delay.delayCodeId).code + '/';
          delayMinutesDep = delayMinutesDep + minutesToHHmm(+delay.minutes) + '/' ;
        }
        delayMinutesDep = delayMinutesDep.slice(0, delayMinutesDep.length - 1) //remove the '/' in the end
        delaysText = 'DL' + delayCodeDep + delayMinutesDep
      }

      //check for Pax
      if (leg.pax) {
        const paxOfLegDep = extractSeatingConfigurations(leg.pax);
        for (const pax of paxOfLegDep) {
          paxAmount = +pax.slice(1) + paxAmount;
        }
      }
      contentOfAD = `MVT${lineSeperator}${(leg.airlineDesignator) + (leg.flightNumber)}/${dayjs(leg.std).utc().format('DD').toUpperCase()}.${leg.acRegistration}.${leg.departureStation}${lineSeperator}AD${dayjs(leg.atd).utc().format('DD').toUpperCase()}${dayjs(leg.atd).utc().format('HHmm').toUpperCase()}/${dayjs(leg.takeOffTime).utc().format('DD').toUpperCase()}${dayjs(leg.takeOffTime).utc().format('HHmm').toUpperCase()} EA${dayjs(leg.eta).utc().format('HHmm').toUpperCase()} ${leg.arrivalStation}${lineSeperator}${delaysText !== '' ? delaysText + lineSeperator : ''}${paxAmount > 0 ? 'PX'+String(paxAmount) : ''}`;
      const nameOfAD = 'AD' + dayjs(leg.atd).format('DDMMMYY').toUpperCase() + '__##__' + 'MVT_SFTP' + '_' + getTimestamp() + '_' +'OUT' + '.txt'
      const file: ITransferFile = {fileName: nameOfAD, fileContent: contentOfAD}
      this.transferFileService.sendFile(file).subscribe();
    }

    if (!dayjs(this.leg.landingTime).isSame(dayjs(leg.landingTime)) && leg.ata !== null && leg.landingTime !== null) {
      contentOfAA = `MVT${lineSeperator}${(leg.airlineDesignator) + (leg.flightNumber)}/${dayjs(leg.std).utc().format('DD').toUpperCase()}.${leg.acRegistration}.${leg.departureStation}${lineSeperator}AA${dayjs(leg.ata).utc().format('DD').toUpperCase()}${dayjs(leg.ata).utc().format('HHmm').toUpperCase()}/${dayjs(leg.landingTime).utc().format('DD').toUpperCase()}${dayjs(leg.landingTime).utc().format('HHmm').toUpperCase()}`;
      const nameOfAA = 'AA' + dayjs(leg.ata).format('DDMMMYY').toUpperCase() + '__##__' + 'MVT_SFTP' + '_' + getTimestamp() + '_' +'OUT' + '.txt'
      const file: ITransferFile = {fileName: nameOfAA, fileContent: contentOfAA}
      this.transferFileService.sendFile(file).subscribe();
    }
    console.log('Content :', contentOfAD, contentOfAA)
  }

  divertFlight(leg: ILeg) {

  }

  returnToRamp(leg: ILegsModel) {

    const modalRef = this.modalService.open(ReturnToRampAirborneDialogComponent, {size: 'xl'});
    const modal = modalRef.componentInstance as ReturnToRampAirborneDialogComponent;
    modal.type = 'RR';
    modal.title = 'Return to Ramp';
    modal.leg = leg;

  }

  returnFromAirborne(leg: ILegsModel) {
    const modalRef = this.modalService.open(ReturnToRampAirborneDialogComponent, {size: 'xl'});
    const modal = modalRef.componentInstance as ReturnToRampAirborneDialogComponent;
    modal.type = 'FR';
    modal.title = 'Return from Airborne';
    modal.leg = leg;
  }

  private generateLegFromForm(form: FormGroup | undefined): ILegsModel | undefined {
    if (!form) {
      return undefined;
    }

    const formValues = this.form?.getRawValue();

    const newLeg: ILegsModel = {...this.leg};

    let lastGivenDateTimeMoment = newLeg.atd ? dayjs.utc(newLeg.atd) : newLeg.etd ? dayjs.utc(newLeg.etd) : dayjs.utc(newLeg.std);

    if (!lastGivenDateTimeMoment || !lastGivenDateTimeMoment.isValid()) {
      console.log('last given departure datetime is invalid. aborting saving process here!');
      return undefined;
    }

    if (formValues.atdDate && formValues.atdTime) {
      const atdMoment = dayjs.utc(ngbDateToFormat(formValues.atdDate) + '-' + (formValues?.atdTime ?? '00:00'), 'DD.MM.YYYY-HH:mm');
      newLeg.atd = atdMoment.toDate();
      lastGivenDateTimeMoment = atdMoment;
    } else {
      newLeg.atd = null;
    }

    if (formValues.totTime) {
      newLeg.takeOffTime = this.calcApplicableButPossibleNextDayDateTime(formValues.totTime, lastGivenDateTimeMoment).toDate();
      lastGivenDateTimeMoment = dayjs.utc(newLeg.takeOffTime);
    } else {
      newLeg.takeOffTime = null;
    }

    if (formValues.etaTime) {
      newLeg.eta = this.calcApplicableButPossibleNextDayDateTime(formValues.etaTime, lastGivenDateTimeMoment).toDate();
      lastGivenDateTimeMoment = dayjs.utc(newLeg.eta);
    } else {
      newLeg.eta = null;
    }

    if (formValues.lndTime) {
      const atdTime: string = this.form.getRawValue().atdTime?.split(':');
      let atdDate = ngbDateToDayjs(this.form.getRawValue().atdDate);
      if (atdTime?.length === 2 && atdDate.isValid())
      {
        atdDate = atdDate.hour(+atdTime[0]).minute(+atdTime[1]);
      } else {
        atdDate = dayjs.utc();
      }
      newLeg.landingTime = this.calcApplicableButPossibleNextDayDateTime(formValues.lndTime, atdDate).toDate();
      lastGivenDateTimeMoment = dayjs.utc(newLeg.landingTime);
    } else {
      newLeg.landingTime = null;
    }

    if (formValues.ataTime) {
      const atdTime: string[] = this.form.getRawValue().atdTime?.split(':');
      let atdDate = ngbDateToDayjs(this.form.getRawValue().atdDate);
      if (atdTime?.length === 2 && atdDate.isValid())
      {
        atdDate = atdDate.hour(+atdTime[0]).minute(+atdTime[1]);
      } else {
        atdDate = dayjs.utc();
      }
      newLeg.ata = this.calcApplicableButPossibleNextDayDateTime(formValues.ataTime, atdDate).toDate();
      lastGivenDateTimeMoment = dayjs.utc(newLeg.ata);
    } else {
      newLeg.ata = null;
    }

    if (formValues.ctotTime) {
      newLeg.ctot = this.calcApplicableButPossibleNextDayDateTime(formValues.ctotTime, lastGivenDateTimeMoment).toDate();
    } else {
      newLeg.ctot = null;
    }

    if (formValues.actualPassengers) {
      const finalSeatConfig: string | undefined = Object.values(formValues.actualPassengers).map((item: ISimpleData | any) => {
        if (item?.code && item?.description) {
          return `${item?.code}${item?.description}`;
        }
        return null;
      }).filter(item => item).join('') || '';

      if (finalSeatConfig) {
        newLeg.pax = finalSeatConfig;
      }
    }

    newLeg.departureGate = formValues.gate ? formValues.gate : null;
    newLeg.departureAcStand = formValues.stand ? formValues.stand : null;
    const passengerCategories: string[] = [];
    for (const key in formValues.categories) {
      if(+formValues.categories[key]?.amount) {
        passengerCategories.push(`${key}:${formValues.categories[key].amount}`);
      }
    }
    newLeg.passengerCategories = '' + passengerCategories.join('/');

    const estimatedPassengers: string[] = [];
    for (const key in formValues.estimatedPassengers) {
      if(+formValues.estimatedPassengers[key]?.description) {
        estimatedPassengers.push(`${key}${formValues.estimatedPassengers[key].description}`);
      }
    }
    newLeg.estimatedPassengers = estimatedPassengers.join('');
    return newLeg;
  }

  private calcApplicableButPossibleNextDayDateTime(currentTimeString: string, lastGivenDateTimeMoment: dayjs.Dayjs): dayjs.Dayjs {
    const currentTimeMoment = dayjs.utc(lastGivenDateTimeMoment.format('YYYY-MM-DD') + '-' + currentTimeString, 'YYYY-MM-DD-HH:mm');

    if (lastGivenDateTimeMoment.isAfter(currentTimeMoment)) {
      currentTimeMoment.add(1, 'days');
    }
    return currentTimeMoment;
  }

  get departureDelayListNew(): Record<string, any> {
    const g = this.form?.get('departureDelaysNew') as FormGroup;

    return g && g.controls ? g.controls : {};
  }

  get arrivalDelayListNew(): Record<string, any> {
    const g = this.form?.get('arrivalDelaysNew') as FormGroup;

    return g && g.controls ? g.controls : {};
  }

  get arrivalDelayList(): string[] {
    const g = this.form?.get('arrivalDelays') as FormGroup;

    return g && g.controls ? Object.keys(g.controls) : [];
  }
}
