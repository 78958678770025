import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IGenericContainerObject } from '../../../../../shared/models/genericContainerObject.model';
import { IGse } from '../../../../../shared/models/gse.model';
import { DefinedGses } from '../../../../../shared/constants/defined-gses';
import { ToastService } from '../../../../../services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GseService } from '../../../../../services/gse.service';
import { IGsesModel } from '../../../../../shared/models/gses.model';
import { IAcType } from '../../../../../shared/models/ac-type.model';
import { AcTypesService } from '../../../../../services/ac-types.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlightType } from '../../../../../shared/constants/flight-types.constants';
import { IAirport } from '../../../../../shared/models/airport.model';
import { TitleCasePipe } from '@angular/common';

type SLAGseTableData = {
  id?: number;
  acType?: IAcType;
  isApplicable?: boolean;
  isSla?: boolean;
  time?: number;
  gseRequired?: boolean;
  gseType?: string;
}

@Component({
  selector: 'app-sla-gse-edit-airport-dialog',
  templateUrl: './sla-gse-edit-airport-dialog.component.html',
  styleUrls: ['./sla-gse-edit-airport-dialog.component.scss']
})
export class SlaGseEditAirportDialogComponent implements OnInit, OnDestroy {
  title: string = '';
  isBusy = false;
  formGroup?: FormGroup;
  definedGses: IGenericContainerObject<IGse> = DefinedGses.IGENERIC_CONTAINER_OBJECT;
  gse: IGsesModel;
  acTypes: IAcType[];
  airport: IAirport;
  gses: IGsesModel[];
  toggleSla = false;
  toggleApplicable = false;
  toggleHasGse = false;
  constructor(private toastService: ToastService, private activeModal: NgbActiveModal, private gseService: GseService, private acTypesService: AcTypesService, private fb: FormBuilder, private titleCase: TitleCasePipe) {

  }

  unsubscribe$ = new Subject();
  ngOnDestroy() {
      this.unsubscribe$.next(undefined);
      this.unsubscribe$.complete();
    }

  closeModal(reason?: any) {
    this.activeModal.dismiss(reason);
  }

  ngOnInit(): void {
    this.toggleSla = !this.gse.isSla;
    this.toggleApplicable = !this.gse.isApplicable;
    this.toggleHasGse = !this.gse.gseIdentifier?.length;
    this.acTypesService.acTypes.pipe(takeUntil(this.unsubscribe$)).subscribe((acTypes) => {
      this.acTypes = acTypes;
      this.buildTable();
    });

    this.gseService.gses.pipe(takeUntil(this.unsubscribe$)).subscribe((gses) => {
      this.gses = gses;
      this.buildTable();
    });
  }

  onSaveClick() {
    if (this.formGroup.invalid) {
      return;
    }
    const tblData: SLAGseTableData[] = this.tableData.getRawValue();
    const dataToSend: IGsesModel[] = [];
    for (const data of tblData) {
      dataToSend.push({
        isSla: data.isSla,
        gseIdentifier: data.gseRequired ? data.gseType : '',
        maximumProcessTimeInMinutes: data.time,
        isApplicable: data.isApplicable,
        title: this.titleCase.transform(data.gseType.replace('_', ' ')),
        airportId: this.airport.id,
        processId: this.gse.processId,
        acTypeId: data.acType.id,
        isNone: false,
        isActive: true,
      });
    }
    this.gseService.saveAirportExceptions(this.airport.id, this.gse.processId, { exceptions: dataToSend }).subscribe((results) => {
      this.gseService.fetchGses().subscribe(() => {});
      if (results) {
        this.toastService.showSuccess('Changes saved successfully!');
      }
      this.closeModal(true);
    });
  }

  get tableData(): FormArray {
    return this.formGroup.get('data') as FormArray;
  }

  get tableDataGroups(): FormGroup[] {
    return this.tableData.controls as FormGroup[];
  }

  buildTable() {
    if (!this.gses?.length || !this.acTypes?.length) {
      return;
    }

    this.formGroup = this.fb.group({
      data: this.fb.array([])
    });

    const data: FormArray = this.formGroup.get('data') as FormArray;
    for (const acType of this.acTypes) {
      const entry = this.gses.find((item) => item.airportId === this.airport.id && item.acTypeId === acType.id && item.processId === this.gse.processId);
      const newGroup = new FormGroup({
        id: new FormControl(entry ? entry.id : null),
        acType: new FormControl(acType),
        isApplicable: new FormControl(entry ? entry.isApplicable : this.gse.isApplicable),
        isSla: new FormControl(entry ? entry.isSla : this.gse.isSla),
        time: new FormControl(entry ? entry.maximumProcessTimeInMinutes : this.gse.maximumProcessTimeInMinutes, [Validators.required, Validators.min(0)]),
        gseRequired: new FormControl(entry ? !!entry.gseIdentifier?.length : !!this.gse.gseIdentifier.length ),
        gseType: new FormControl(entry ? entry?.gseIdentifier : this.gse.gseIdentifier),
      });
      if (newGroup.value.isApplicable === false) {
        newGroup.get('gseType').disable({ emitEvent: false });
        newGroup.get('gseRequired').disable({ emitEvent: false });
        newGroup.get('isSla').disable({ emitEvent: false });
        newGroup.get('time').disable({ emitEvent: false });
      }
      if (!newGroup.value.gseRequired) {
        newGroup.get('gseType').disable({ emitEvent: false });
      }
      newGroup.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        const value = newGroup.getRawValue();
        if (value.isApplicable === false) {
          newGroup.get('gseType').disable({ emitEvent: false });
          newGroup.get('gseRequired').disable({ emitEvent: false });
          newGroup.get('isSla').disable({ emitEvent: false });
          newGroup.get('time').disable({ emitEvent: false });
          return;
        } else {
          newGroup.get('gseRequired').enable({ emitEvent: false });
          newGroup.get('isSla').enable({ emitEvent: false });
          newGroup.get('time').enable({ emitEvent: false });
          if (value.gseRequired) {
            newGroup.get('gseType').enable({ emitEvent: false });
          }
        }
        if (value.gseRequired) {
          if (!newGroup.get('gseType').hasValidator(Validators.required)) {
            newGroup.get('gseType').addValidators(Validators.required);
            newGroup.get('gseType').enable({ emitEvent: false });
            newGroup.get('gseType').updateValueAndValidity({ emitEvent: false });
          }
        } else {
          newGroup.get('gseType').clearValidators();
          newGroup.get('gseType').disable({ emitEvent: false });
          newGroup.get('gseType').updateValueAndValidity({emitEvent: false});
        }

      });
      data.push(newGroup);
    }
  }

  protected readonly FlightType = FlightType;

  toggleApplicableChanged() {
    for (const ctrl of this.tableData.controls) {
      ctrl.patchValue({
        isApplicable: !this.toggleApplicable
      })
    }
  }

  toggleHasGseChanged() {
    for (const ctrl of this.tableData.controls) {
      ctrl.patchValue({
        gseRequired: !this.toggleHasGse
      })
    }
  }

  toggleSlaChanged() {
    for (const ctrl of this.tableData.controls) {
      ctrl.patchValue({
        isSla: !this.toggleSla
      })
    }
  }

  getIconClass(gseTypeName: string): string {
    return DefinedGses[gseTypeName]?.class || '';
  }
}
