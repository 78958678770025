import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsMenuModel } from '../../shared/models/settings-menu.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {

  links: SettingsMenuModel[] = [
    { title: 'General Settings', link: 'general', imgSrc: 'content/images/icons/settings-general-settings.svg' },
    { title: 'User Management',  link: 'users', imgSrc: 'content/images/icons/settings-user-management.svg' },
    { title: 'Flight Schedule Management',  link: 'ssim', imgSrc: 'content/images/icons/gses/3 departing.svg' },
    { title: 'A/C Type Management',  link: 'ac-types', imgSrc: 'content/images/icons/settings-plane.svg' },
    { title: 'A/C Registration Management',  link: 'ac-registrations', imgSrc: 'content/images/icons/settings-plane-front.svg', width: 35 },
    { title: 'Airport Management',  link: 'airports', imgSrc: 'content/images/icons/settings-airport-management.svg' },
    { title: 'SLA and GSE Management',  link: 'sla-gse', imgSrc: 'content/images/icons/settings-sla-gse.svg' },
    { title: 'Interface Management',  link: 'interfaces', imgSrc: 'content/images/icons/database-fill-up.svg', width: 24, height: 24 },
    { title: 'Alert Management',  link: 'alerts', imgSrc: 'content/images/icons/alert.svg', width: 24, height: 24 },
  ];
  activeNavItem: string;
  constructor(public router: Router) {
  }

  onActivate() {
    window.scroll(0,0);
  }
}
