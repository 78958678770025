<app-settings-header text="A/C Registration Management" [showSaveButton]="false" [textSize]="20"></app-settings-header>
<app-settings-card (onAddClicked)="doAdd()" sectionTitle="A/C Registrations" addText="Add Aircraft Registration">
  <div class="flex flex-column">
    <div>
      <label class="dark-blue" for="delay-page-size">Results per page</label>
      <select id="delay-page-size" class="form-select light-blue-border page-size-select" [(ngModel)]="pageSize" [ngModelOptions]="{standalone: true}" data-test="result-size-select">
        <option [ngValue]="25">25</option>
        <option [ngValue]="50">50</option>
        <option [ngValue]="150">150</option>
        <option [ngValue]="500">500</option>
        <option [ngValue]="1000">1000</option>
      </select>
    </div>

    <app-settings-data-table [testTagPipe]="AcRegistrationsTestTagPipe" [newItemsPerPage]="pageSize" class="mt-3" [columns]="['Registration', 'A/C Type', 'Description', 'A/C Name', 'Seating Configuration', 'SELCAL']" [data]="acRegistrations" [variableNames]="['registration', 'acTypeId', 'description', 'name', 'seatingConfigurations', 'selcal']" (onEditClicked)="doEdit($event)" (onDeleteClicked)="doDelete($event)">
      <ng-template propName="seatingConfigurations" let-seating>
        {{ getApplicableSeatingConfigurations(seating) }} ({{ getAirlineDesignator(seating) }})
      </ng-template>
      <ng-template propName="acTypeId" let-actype>
        {{ getAcTypeFromId(actype)?.alias }}
      </ng-template>
    </app-settings-data-table>
  </div>
</app-settings-card>
