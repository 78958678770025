import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { IAlertsModel } from '../../../../shared/models/alerts.model';
import { IPairOverview } from '../../../../shared/models/pair-overview.model';
import { IPairDetail } from '../../../../shared/models/pair-detail.model';
import { firstValueFrom, Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAcType } from '../../../../shared/models/ac-type.model';
import { PairsService } from '../../../../services/pairs.service';
import { PairLegTimesLogService } from '../../../../services/pair-leg-times-log.service';
import { LegDelaysLogService } from '../../../../services/leg-delays-log.service';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LegsService } from '../../../../services/legs.service';
import { GeneralSettingsService } from '../../../../services/general-settings.service';
import { TimeTypesService } from '../../../../services/time-types.service';
import { ToastService } from '../../../../services/toast.service';
import { AcTypesService } from '../../../../services/ac-types.service';
import { AlertService } from '../../../../services/alert.service';
import dayjs from 'dayjs';
import { AlertTypesEnum } from '../../../../shared/constants/alert-types.enum';
import { calculateETADate, dayjsToNgbDate, ngbDateToDayjs, ngbDateToFormat } from '../../../../shared/utils/utils';
import { takeUntil } from 'rxjs/operators';
import { IDelayCode } from '../../../../shared/models/delay-code.model';
import { IGenericContainerObject } from '../../../../shared/models/genericContainerObject.model';

@Component({
  selector: 'app-simple-issue-etd-alert-type',
  templateUrl: './simple-issue-etd-alert-type.component.html',
  styleUrls: ['./simple-issue-etd-alert-type.component.scss']
})
export class SimpleIssueEtdAlertTypeComponent implements OnInit, OnDestroy, OnChanges {

  @Input() alert: IAlertsModel;
  @Input() timezone: string;
  @Input() dateFormat: string;
  @Input() delayCodes: IDelayCode[];
  delayCodesKV: IGenericContainerObject<IDelayCode> = {};
  pairOverviewModel: IPairOverview;
  pairDetail: IPairDetail;
  destroy$: Subject<any> = new Subject();
  delayInMinutes: number;
  showContent = false;
  form: FormGroup;
  isBusy = false;
  acType: IAcType;
  constructor(private pairService: PairsService,
              private pairLegTimesLogService: PairLegTimesLogService,
              private legDelaysLogService: LegDelaysLogService,
              private activeModal: NgbActiveModal,
              private legService: LegsService,
              private generalSettingsService: GeneralSettingsService,
              private timeTypesService: TimeTypesService,
              private toastService: ToastService,
              private acTypeService: AcTypesService,
              private alertService: AlertService) { }

  ngOnInit(): void {
    this.getPairDetail().then(() => {
      this.init();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.delayCodes) {
      this.delayCodes?.forEach((delay) => {
        this.delayCodesKV[delay.id] = delay;
      });
    }
  }

  calculateNewEtd(): dayjs.Dayjs {
    switch(this.alertService.alertTypes[this.alert.alertTypeId].title) {
      case AlertTypesEnum.UNPAIRED_SECTOR:
        return dayjs(this.pairDetail?.departureLeg?.std).utc();
    }
  }

  async getPairDetail(): Promise<void> {
    switch(this.alertService.alertTypes[this.alert.alertTypeId].title) {
      case AlertTypesEnum.UNPAIRED_SECTOR:
        const legId = this.alert.__leg__?.id ?? this.alert.__pair__?.departureLegId;
        const pair = await firstValueFrom(this.pairService.getPairsByFilter({ departureLegId: legId, isActive: true }));
        if (!pair) {
          return;
        }
        this.pairDetail = await firstValueFrom(this.pairService.getPairDetail(pair[0].id));
        break;
    }
  }

  async init()
  {
    this.pairOverviewModel = this.pairDetail;
    this.pairOverviewModel.flightNumberDeparting = this.pairDetail.departureLeg?.flightNumber;
    this.pairOverviewModel.flightNumberArriving = this.pairDetail.arrivalLeg?.flightNumber;
    this.pairOverviewModel.airlineDesignator = this.pairDetail.arrivalLeg.airlineDesignator;
    const acTypes = await firstValueFrom(this.acTypeService.fetchAcTypes({ id: this.pairDetail.departureLeg.acTypeId }));
    if (!acTypes?.length) {
      return;
    }
    this.acType = acTypes[0];

    const newEtd = this.calculateNewEtd();
    this.form = new FormGroup({
      etdDate: new FormControl(dayjsToNgbDate(newEtd), Validators.required),
      newEtdTime: new FormControl(newEtd.format('HH:mm'), Validators.required),
      delayCode: new FormControl('', Validators.required),
    });
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(item => this.updateDelayTimespan(item));
    this.form.patchValue({ delayCode: "" });
    this.showContent = true;
  }

  updateDelayTimespan(item: { etdDate: NgbDateStruct, newEtdTime: string, code: string }) {
    console.log('Update:', item);
    if (!item.etdDate || !item.newEtdTime
      || !dayjs([ item.etdDate.year, item.etdDate.month-1, item.etdDate.day]).isValid() || item.newEtdTime.length !== 5) {
      return;
    }
    const newDelayMoment = dayjs(ngbDateToFormat(item.etdDate, this.dateFormat) + ' ' + item.newEtdTime + '+00:00', this.dateFormat + ' HH:mmZ');
    const stdMoment = dayjs(this.pairDetail?.departureLeg?.std);
    this.delayInMinutes = newDelayMoment.diff(stdMoment, 'minutes');
  }

  async save() {
    const leg = this.pairDetail.departureLeg;
    const formValue = this.form.value;
    this.isBusy = true;
    const etdTimeSplit = formValue.newEtdTime.split(':');
    const newEtd = ngbDateToDayjs(formValue.etdDate).utc(true).hour(etdTimeSplit[0]).minute(etdTimeSplit[1]).toDate();
    const newEta = calculateETADate({...leg, etd: newEtd});
    const saveResult = await firstValueFrom(this.legService.saveLeg({...leg, etd: newEtd, tod: newEtd, eta: newEta} as any));
    if (!saveResult) {
      return;
    }
    this.toastService.showSuccess('Leg has been saved');
    const timeTypes = await firstValueFrom(this.timeTypesService.getTimeTypes());
    if (!timeTypes) {
      return;
    }
    const pairLegTimesLogId = await firstValueFrom(this.pairLegTimesLogService.getPairLegTimeLogs({isActive: true, legId: leg.id, timeTypeId: timeTypes.filter((item) => item.identifier === 'ETD')[0]?.id}));
    const delayResult = await firstValueFrom(this.legDelaysLogService.saveLegDelayLog({
      legId: leg.id,
      delayCodeId: formValue.delayCode,
      minutes: this.delayInMinutes,
      pairLegTimesLogId: pairLegTimesLogId[0]?.id ?? null,
      issueEtd: true,
    } as any));
    if (delayResult) {
      this.toastService.showSuccess('Leg delay has been saved');
    }
    this.closeModal();
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  saveClicked() {
    this.isBusy = true;
    this.save().catch().finally(() => this.isBusy = false);
  }

}
