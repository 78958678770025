/* tslint:disable:max-line-length */
import * as dayjs from 'dayjs';

import { SSIMValidatorErrors } from '../constants/ssimValidatorErrors.constants';
import { IAcType } from '../models/ac-type.model';
import { compareSeatingConfigurations, fixMonth } from './utils';
import {toDegrees} from "chart.js/helpers";

class BuiltFlight {
  airlineDesignator?: string;
  flightNumber?: string;
  serviceType?: string;
  departureStation?: string;
  arrivalStation?: string;
  std?: string;
  sta?: string;
  tod?: string;
  toa?: string;
  acType?: string;
  seatingConfiguration?: string;
  asmUtcKey?: string;
  asmLtKey?: string;
  operationStatus?: string;
}

enum MessageType {
  WARNING,
  ERROR,
  HIGH_RISK
}

class FlightSchedule {
  airlineDesignator?: string;
  flightNumber?: string;
  serviceType?: string;
  flightPeriodFrom?: dayjs.Dayjs;
  flightPeriodTo?: dayjs.Dayjs;
  daysOfOperation?: string;
  departureStation?: string;
  departureAircraftSTD?: string;
  departureStationTimeVariation?: string;
  arrivalStation?: string;
  arrivalAircraftSTA?: string;
  arrivalStationTimeVariation?: string;
  acType?: string;
  seatingConfiguration?: string;
  rowNumber?: number;
  isUtc?: boolean;
}

export class SSIMParseValidator {

  private dbRef: any;
  private onlyValidation = false;
  private errors: string[] = [];
  private warnings: string[] = [];
  private isValid = true;
  private highRisk: string[] = [];
  private validSchedule: FlightSchedule[] = [];
  private schedule = {};
  private flights = {};
  private isUTC = false;
  private selectedDateFrom?: dayjs.Dayjs;
  private selectedDateTo?: dayjs.Dayjs;

  constructor() {

  }

  public async parseAndValidateFlightSchedules(
    justValidation: boolean,
    schedules: string,
    airlineDesignators: {code: string, allow: boolean}[] = [],
    airports: string[] = [],
    globalAirports: string[] = [],
    acTypes: { [key: string]: IAcType; } = {},
    serviceTypes: string[] = [],
    fromDate?: dayjs.Dayjs,
    untilDate?: dayjs.Dayjs,
    dbRef?: any
  ): Promise<{ valid: boolean, errors: string[], warnings: string[], schedule: object, flights: object, isUtc: boolean, highRisk?: string[] }> {
    this.selectedDateFrom = fromDate;
    this.selectedDateTo = untilDate;
    this.onlyValidation = justValidation;
    this.dbRef = dbRef;

    console.log('parsing schedules');
    const parsedSchedules = this.parseFlightScheduleString(schedules);
    console.log('validating schedules');
    this.isValid = await this.validateFlightSchedules(parsedSchedules, airlineDesignators, serviceTypes, airports, globalAirports, acTypes);

    // console.log(this.flights);

    // console.log('check if is just validation - justValidation value is: ' + justValidation);
    if (!justValidation) {
      // this.flights = this.buildFlights(this.validSchedule, fromDate, untilDate);
    }

    // console.log('flights!!!');
    // console.log(this.flights);


    const schedObj: any = {};
    this.validSchedule.map(item => {
      const periodFromTotal = (item.flightPeriodFrom && item.flightPeriodFrom.isValid()) ? item.flightPeriodFrom.format() : 'invalid-date';
      const periodFromDate = (item.flightPeriodFrom && item.flightPeriodFrom.isValid()) ? item.flightPeriodFrom.format('YYYYMMDD') : 'invalid-date';
      const periodToTotal = (item.flightPeriodTo && item.flightPeriodTo.isValid()) ? item.flightPeriodTo.format() : 'invalid-date';
      const periodToDate = (item.flightPeriodTo && item.flightPeriodTo.isValid()) ? item.flightPeriodTo.format('YYYYMMDD') : 'invalid-date';
      const key: string = item.departureStation + '-' + item.arrivalStation + '-' + periodFromDate + '-' + periodToDate;

      schedObj[key] = {...item, flightPeriodFrom: periodFromTotal, flightPeriodTo: periodToTotal};
    });
    this.schedule = schedObj;

    return {
      valid: this.isValid,
      errors: this.errors,
      warnings: this.warnings,
      schedule: this.schedule,
      flights: this.flights,
      isUtc: this.isUTC,
      highRisk: this.highRisk
    };
  }

  public createFlightsFromSchedule(schedules: any, fromDate: dayjs.Dayjs, untilDate: dayjs.Dayjs) {
    return this.buildFlights(Object.values(schedules), fromDate, untilDate);
  }

  parseFlightScheduleString(schedules: string): string[] {

    if (schedules) {
      const lineSeperator = schedules.includes('\r\n') ? '\r\n' : '\n';
      return schedules.trim().split(lineSeperator);
    }
    return [];
  }

  private async validateFlightSchedules(schedules: string[], airlineDesignators: {code: string, allow: boolean}[], serviceTypes: string[], airports: string[], globalAirports: string[], acTypes: { [key: string]: IAcType; }): Promise<boolean> {

    if (!schedules || schedules.length === 0 || !this.isSSIM(schedules)) {
      this.prepareMessage(MessageType.ERROR, 'Given file is no valid SSIM file', -1);
      return false;
    }
    console.log('validateFlightScedules','airlinedesignators array', airlineDesignators);
    const recordType2List = this.parseRC2(schedules);

    this.validateRC2(recordType2List, airlineDesignators);

    if (!this.isValid) {
      console.log('is invalid');
      return false;
    }

    const parsedFlights = this.parseRC3(schedules);

    // const validFlights = this.validateRC3(parsedFlights, recordType2List, airlineDesignators, serviceTypeCodeList, airportIataList, globalAirportIataList, acTypes);
    const validFlights = await this.validateRC3(parsedFlights, recordType2List, airlineDesignators.map((des) => des.code), serviceTypes, airports, globalAirports, acTypes);

    if(!validFlights.length) {
      this.isValid = false;
    }
    if (this.isValid) {
      this.validSchedule = validFlights;
    }

    console.log('validateFlightSchedules - validFlights');
    console.log(validFlights);

    return this.isValid;
  }

  parseRC2(schedules: string[]): {timezoneIndicator: string
    isUTC: boolean
    periodFrom: dayjs.Dayjs,
    periodTo: dayjs.Dayjs,
    airlineDesignator: string,
    line: number }[] {
    return schedules.filter(row => row.charAt(0) === '2')
      .map(row => {

        const isUTC = row.slice(1, 2) === 'U';

        const timezoneIndicator = row.slice(1, 2).trim();

        const airlineDesignator = row.slice(2, 5).trim();

        let periodFrom = dayjs(fixMonth(row.slice(14, 21)), 'DDMMMYY');
        let periodTo = dayjs(fixMonth(row.slice(21, 28)), 'DDMMMYY');
        const line = schedules.indexOf(row);
        const airlineDesignatorName = row.slice(72,107).trim();

        if (isUTC) {
          periodFrom = periodFrom.utc(true);
          periodTo = periodTo.utc(true);
        }
        console.log('parceRC2','airline des: ',airlineDesignator, airlineDesignatorName)
        return {
          timezoneIndicator,
          isUTC,
          periodFrom,
          periodTo,
          airlineDesignator,
          line
        };
      });
  }

  private validateRC2(recordType2List: {timezoneIndicator: string
    isUTC: boolean
    periodFrom: dayjs.Dayjs,
    periodTo: dayjs.Dayjs,
    airlineDesignator: string,
    line: number }[], airlineDesignators: {code: string, allow: boolean}[]) {
    recordType2List.map(item => {

      const timezoneChecker = /^[U,L]$/g;

      if (!timezoneChecker.test(item.timezoneIndicator)) {
        this.prepareMessage(MessageType.ERROR, SSIMValidatorErrors.RC2_INVALID_TIMEZONE, item.line);
        this.isValid = false;
      }

      if (!item.periodFrom || !item.periodFrom.isValid() || !item.periodTo ||
        !item.periodTo.isValid() || !item.periodFrom.isSameOrBefore(item.periodTo)) {
        this.prepareMessage(MessageType.ERROR, SSIMValidatorErrors.RC2_INVALID_PERIOD, item.line);
        this.isValid = false;
      }

      // if (!airlineDesignators.includes(item.airlineDesignator)) {
      //   this.prepareMessage(MessageType.ERROR, SSIMValidatorErrors.RC2_UNKNOWN_AIRLINE_DESIGNATOR, item.line);
      //   this.isValid = false;
      // }

      if (airlineDesignators.map((des) => des.code).includes(item.airlineDesignator)) {
        const isAllowedForSSIM = airlineDesignators.find((air) => air.code === item.airlineDesignator).allow;
        if (!isAllowedForSSIM) {
          this.prepareMessage(MessageType.ERROR, SSIMValidatorErrors.RC2_NOT_ALLOWED_FOR_SSIM, item.line);
          this.isValid = false
        }
      } else {
        this.prepareMessage(MessageType.ERROR, SSIMValidatorErrors.RC2_UNREGISTERED_AIRLINE_DESIGNATOR, item.line);
        this.isValid = false
      }

      if (this.isValid) {
        this.isUTC = 'U' === item.timezoneIndicator;
      }
      if (this.selectedDateFrom?.isValid() && item.periodTo.isBefore(this.selectedDateFrom) || this.selectedDateTo?.isValid() && item.periodFrom.isAfter(this.selectedDateTo)) {
        this.errors.push('There are no flights within the specified dates.');
      }
    });
  }

  parseRC3(schedules: string[]): FlightSchedule[] {
    return schedules.filter(row => row.charAt(0) === '3')
      .map(row => {

        const airlineDesignator = row.slice(2, 5).trim();
        const flightNumber = row.slice(5, 9).trim();
        const serviceType = row.slice(13, 14).trim();

        const flightPeriodFrom = dayjs(fixMonth(row.slice(14, 21).trim()), 'DDMMMYY');
        // const flightPeriodFromUtc = dayjs.utc(row.slice(14, 21).trim() + '+0000', 'DDMMMYY Z');
        const flightPeriodTo = dayjs(fixMonth(row.slice(21, 28).trim()), 'DDMMMYY');

        const daysOfOperation = row.slice(28, 35);

        const departureStation = row.slice(36, 39).trim();
        const departureAircraftSTD = (row.slice(43, 47).trim() === '2400') ? '0000' : row.slice(43, 47).trim();
        const departureStationTimeVariation = row.slice(47, 52).trim();

        const arrivalStation = row.slice(54, 57).trim();
        const arrivalAircraftSTA = (row.slice(57, 61).trim() === '2400') ? '0000' : row.slice(57, 61).trim();
        const arrivalStationTimeVariation = row.slice(65, 70).trim();

        const acType = row.slice(72, 75).trim();

        const passengerReservation = row.slice(75, 94).trim();
        const acConfig = row.slice(172, 191).trim();
        const seatingConfiguration = (acConfig) ? acConfig : passengerReservation;

        const rowNumber = schedules.indexOf(row);

        return {
          airlineDesignator,
          flightNumber,
          serviceType,
          flightPeriodFrom,
          flightPeriodTo,
          daysOfOperation,
          departureStation,
          departureAircraftSTD,
          departureStationTimeVariation,
          arrivalStation,
          arrivalAircraftSTA,
          arrivalStationTimeVariation,
          acType,
          seatingConfiguration,
          rowNumber,
          isUtc: this.isUTC
        };
      });
  }

  private async validateRC3(flights: any[], recordType2List: any[], airlineDesignators: string[], serviceTypes: string[], airportList: string[], globalAirportList: string[], acTypesObject: { [key: string]: IAcType; } = {}): Promise<any[]> {
    const sameFlights = flights.map(async (flight: any) => {
      const rc2 = this.getSpecificRC2(flight.rowNumber, recordType2List);

      console.log('entered validateRC3 map iteration');

      // check airline designator
      if (this.isNullOrEmpty(flight.airlineDesignator, true, SSIMValidatorErrors.RC3_INVALID_AIRLINE_DESIGNATOR, flight.rowNumber) ||
        !this.isAvailableInCollection(flight.airlineDesignator, airlineDesignators,
          true, SSIMValidatorErrors.RC3_UNKNOWN_AIRLINE_DESIGNATOR, flight.rowNumber)) {
        this.isValid = false;
      }

      // check flight number
      if (this.isNullOrEmpty(flight.flightNumber, true, SSIMValidatorErrors.RC3_INVALID_FLIGHT_NUMBER, flight.rowNumber)) {
        this.isValid = false;
      }

      // check days of operation
      const daysOfOperationRegex = /^[1, ][2, ][3, ][4, ][5, ][6, ][7, ]$/g;

      if (!flight.daysOfOperation || this.isNullOrEmpty(flight.daysOfOperation.trim(), true, SSIMValidatorErrors.RC3_INVALID_DAYS_OF_OPERATION, flight.rowNumber)) {
        this.isValid = false;
      } else if (!daysOfOperationRegex.test(flight.daysOfOperation)) {
        this.prepareMessage(MessageType.ERROR, SSIMValidatorErrors.RC3_INVALID_DAYS_OF_OPERATION, flight.rowNumber);
        this.isValid = false;
      }

      // check service type
      if (this.isNullOrEmpty(flight.serviceType, true, SSIMValidatorErrors.RC3_INVALID_SERVICE_TYPE, flight.rowNumber) ||
        !this.isAvailableInCollection(flight.serviceType, serviceTypes,
          true, SSIMValidatorErrors.RC3_UNKNOWN_SERVICE_TYPE, flight.rowNumber)) {
        this.isValid = false;
      }

      console.log('validateRC3 check departing ac');
      // check departure airport station
      if (this.isNullOrEmpty(flight.departureStation, true, SSIMValidatorErrors.RC3_INVALID_DEPARTURE_STATION, flight.rowNumber)) {
        this.isValid = false;
      } else if (!globalAirportList.includes(flight.departureStation)) {
        this.prepareMessage(MessageType.ERROR, SSIMValidatorErrors.RC3_UNKNOWN_DEPARTURE_STATION, flight.rowNumber);
        this.isValid = false;
      } else {
        // trigger addition of global airport into system - only on firebase
        // await this.addGlobalAirportToSystemAirports(flight.departureStation);
        // airportList.push(flight.departureStation);
        // this.prepareMessage(MessageType.WARNING, SSIMValidatorErrors.RC3_WARNING_NEW_DEPARTURE_STATION, flight.rowNumber);
      }

      console.log('validateRC3 check arriving ac');
      // check arrival airport station
      if (this.isNullOrEmpty(flight.arrivalStation, true, SSIMValidatorErrors.RC3_INVALID_ARRIVAL_STATION, flight.rowNumber)) {
        this.isValid = false;
      } else if (!globalAirportList.includes(flight.arrivalStation)) {
        this.prepareMessage(MessageType.ERROR, SSIMValidatorErrors.RC3_UNKNOWN_ARRIVAL_STATION, flight.rowNumber);
        this.isValid = false;
      } else {
        // trigger addition of global airport into system - only on firebase
        // await this.addGlobalAirportToSystemAirports(flight.arrivalStation);
        // airportList.push(flight.arrivalStation);
        // this.prepareMessage(MessageType.WARNING, SSIMValidatorErrors.RC3_WARNING_NEW_ARRIVAL_STATION, flight.rowNumber);
      }

      // check flight periods
      if (!this.checkIfDateIsValidAndInPeriod(flight.flightPeriodFrom, rc2.periodFrom, rc2.periodTo, true, flight.rowNumber) ||
        !this.checkIfDateIsValidAndInPeriod(flight.flightPeriodTo, rc2.periodFrom, rc2.periodTo, true, flight.rowNumber)) {
        this.isValid = false;
      } else if (!flight.flightPeriodFrom.isSameOrBefore(flight.flightPeriodTo)) {
        this.prepareMessage(MessageType.ERROR, SSIMValidatorErrors.RC3_INVALID_PERIOD, flight.rowNumber);
        this.isValid = false;
      }

      // check aircraft STD / STA
      const standardTimeRegex = /^(([0-1][0-9]|[2][0-3])[0-5][0-9]|[2][4][0][0])$/;
      if (this.isNullOrEmpty(flight.departureAircraftSTD, true, SSIMValidatorErrors.RC3_INVALID_AIRCRAFT_STD, flight.rowNumber)) {
        this.isValid = false;
      } else if (!standardTimeRegex.test(flight.departureAircraftSTD)) {
        this.prepareMessage(MessageType.ERROR, SSIMValidatorErrors.RC3_INVALID_AIRCRAFT_STD, flight.rowNumber);
        this.isValid = false;
      }
      if (this.isNullOrEmpty(flight.arrivalAircraftSTA, true, SSIMValidatorErrors.RC3_INVALID_AIRCRAFT_STA, flight.rowNumber)) {
        this.isValid = false;
      } else if (!standardTimeRegex.test(flight.arrivalAircraftSTA)) {
        this.prepareMessage(MessageType.ERROR, SSIMValidatorErrors.RC3_INVALID_AIRCRAFT_STA, flight.rowNumber);
        this.isValid = false;
      }

      console.log('validateRC3 check time variation');
      // check time variation of STD / STA
      const timeVariationRegex = /^[+,-](([0-1][0-9]|[2][0-3])[0-5][0-9]|[2][4][0][0])$/;
      if (this.isNullOrEmpty(flight.departureStationTimeVariation, true, SSIMValidatorErrors.RC3_INVALID_AIRCRAFT_STD_VARIATION, flight.rowNumber)) {
        this.isValid = false;
      } else if (!timeVariationRegex.test(flight.departureStationTimeVariation)) {
        this.prepareMessage(MessageType.ERROR, SSIMValidatorErrors.RC3_INVALID_AIRCRAFT_STD_VARIATION, flight.rowNumber);
        this.isValid = false;
      }
      if (this.isNullOrEmpty(flight.arrivalStationTimeVariation, true, SSIMValidatorErrors.RC3_INVALID_AIRCRAFT_STA_VARIATION, flight.rowNumber)) {
        this.isValid = false;
      } else if (!timeVariationRegex.test(flight.arrivalStationTimeVariation)) {
        this.prepareMessage(MessageType.ERROR, SSIMValidatorErrors.RC3_INVALID_AIRCRAFT_STA_VARIATION, flight.rowNumber);
        this.isValid = false;
      }

      // check ac type
      const selectedAcType = acTypesObject[flight.acType];
      if (this.isNullOrEmpty(flight.acType, true, SSIMValidatorErrors.RC3_INVALID_AC_TYPE, flight.rowNumber)) {
        this.isValid = false;
      } else if (!selectedAcType) {
        this.prepareMessage(MessageType.HIGH_RISK, SSIMValidatorErrors.RC3_UNKNOWN_AC_TYPE, flight.rowNumber);
        //this.isValid = true;
      }
      // check seating configuration
      if(selectedAcType) {
        if (this.isNullOrEmpty(flight.seatingConfiguration, true, SSIMValidatorErrors.RC3_INVALID_SEATING_CONFIGURATION, flight.rowNumber)) {
          this.isValid = false;
        } else if (!selectedAcType || !selectedAcType?.seatingConfigurations || !compareSeatingConfigurations(flight.seatingConfiguration, selectedAcType?.seatingConfigurations.filter((item) => item.acRegistrationId === null).map(item => item.code + item.description))) {
          this.prepareMessage(MessageType.WARNING, SSIMValidatorErrors.RC3_UNKNOWN_SEATING_CONFIGURATION, flight.rowNumber);
          // this.isValid = false;
        }
      }

      console.log('validateRC3 - flight');
      console.log(flight);

      return flight;
    });

    // Promise.all(sameFlights).then((completed) => document.writeln( `\nResult: ${completed}`));
    await Promise.all(sameFlights);
    return flights;
  }

  private buildFlights(flightSchedule: FlightSchedule[], fromDate?: dayjs.Dayjs, untilDate?: dayjs.Dayjs): object {
    console.log(':::: BUILD FLIGHTS :::::');
    console.log(flightSchedule.length);
    console.log(fromDate);
    console.log(untilDate);
    console.log('buildFlightsEnd');
    // let res: BuiltFlight[] = [];
    let res1 = {};
    // flightSchedule.map(item => res = [...res, ...this.buildFlightsFromSchedule(item, fromDate, untilDate)]);
    flightSchedule.map(item => res1 = {...res1, ...this.buildFlightsFromSchedule(item, fromDate, untilDate)});

    return res1;
  }

  private buildFlightsFromSchedule(schedule: FlightSchedule, fromDate?: dayjs.Dayjs, untilDate?: dayjs.Dayjs): object {
    // console.log(':::: BUILD FLIGHTS FROM SCHEDULE :::::');
    const res: any = {};

    // console.log(schedule.flightPeriodFrom);
    // console.log(schedule.flightPeriodTo);

    if (!schedule || !schedule.flightPeriodFrom || !schedule.flightPeriodTo) {
      return res;
    }

    schedule.flightPeriodFrom = dayjs(schedule.flightPeriodFrom);
    schedule.flightPeriodTo = dayjs(schedule.flightPeriodTo);


    console.log(':::: dates :::: ');
    console.log(fromDate);
    console.log(untilDate);


    if (fromDate) {
      fromDate = dayjs(fromDate);
    }

    if (untilDate) {
      untilDate = dayjs(untilDate);
    }


    console.log(fromDate);
    console.log(untilDate);


    const periodFrom = dayjs(schedule.flightPeriodFrom.format('YYYY-MM-DD') + 'T00:00:00' + '+0000');
    const periodTo = schedule.flightPeriodTo.add(1, 'd');
    while (schedule.daysOfOperation && periodFrom < periodTo) {
      const daysOfOperation = schedule.daysOfOperation.trim().replace('7', '0').split('').map(item => parseInt(item, 10));
      /*
            console.log('days of operation');
            console.log(JSON.stringify(daysOfOperation));

            console.log('condition fromDate');
            console.log((!fromDate || (fromDate.isValid() && fromDate.isSameOrBefore(periodFrom, 'day'))));
            console.log('condition untilDate');
            console.log((!untilDate || (untilDate.isValid() && untilDate.isSameOrAfter(periodFrom, 'day'))));
            console.log('condition weekdays');
            console.log(daysOfOperation.includes(periodFrom.weekday())); */

      if ((!fromDate || (fromDate.isValid() && fromDate.isSameOrBefore(periodFrom, 'day'))) && (!untilDate || (untilDate.isValid() && untilDate.isSameOrAfter(periodFrom, 'day'))) && daysOfOperation.includes(periodFrom.weekday())) {
        console.log('bla blubb');
        const tempFLight = this.buildFlight(schedule, periodFrom);
        const key: string = schedule.departureStation + '-' + schedule.arrivalStation + '-' + dayjs(tempFLight.std).format('YYYYMMDD-HHmm') + '-' + schedule.airlineDesignator + '' + schedule.flightNumber;
        res[key] = tempFLight;
      }
      periodFrom.add(1, 'd');
    }
    return res;
  }

  private buildFlight(schedule: FlightSchedule, date: dayjs.Dayjs): BuiltFlight {
    console.log(':::: BUILD FLIGHT :::::');
    console.log(schedule.isUtc);
    console.log('date');
    console.log(date.format());
    console.log('schedule');
    console.log(JSON.stringify(schedule));

    const depTimezone = (schedule.isUtc) ? '+0000' : schedule.departureStationTimeVariation;
    const arrTimezone = (schedule.isUtc) ? '+0000' : schedule.arrivalStationTimeVariation;

    const departureDate = date;
    const arrivalDate = dayjs(date);

    if (schedule.departureAircraftSTD && schedule.arrivalAircraftSTA && schedule.departureAircraftSTD > schedule.arrivalAircraftSTA) {
      arrivalDate.add(1, 'd');
    }

    const depStation = schedule.departureStation ? schedule.departureStation : 'NULL';
    const arrStation = schedule.arrivalStation ? schedule.arrivalStation : 'NULL';
    const designator = schedule.airlineDesignator ? schedule.airlineDesignator : 'NULL';
    const flightNum = schedule.flightNumber ? schedule.flightNumber : '0000';

    const asmDateTimeUtcMoment = dayjs(departureDate.format('DDMMYYYY') + ' ' + schedule.departureAircraftSTD + ' ' + depTimezone, 'DDMMYYYY HHmm Z');
    const asmDateTimeLtMoment = dayjs(departureDate.format('DDMMYYYY') + ' ' + schedule.departureAircraftSTD + ' ' + depTimezone, 'DDMMYYYY HHmm Z');

    const depVariation = schedule.departureStationTimeVariation;

    if (depVariation) {
      const isNegativeVariation = depVariation.includes('-');

      const variationHours = depVariation.slice(1, -2);
      const variationMinutes = depVariation.slice(-2);

      if (isNegativeVariation) {
        asmDateTimeLtMoment.subtract(Number(variationHours), 'hours').subtract(Number(variationMinutes), 'minutes');
      } else {
        asmDateTimeLtMoment.add(Number(variationHours), 'hours').add(Number(variationMinutes), 'minutes');
      }
    }

    const asmDateTimeUtcUppercase = asmDateTimeUtcMoment ? asmDateTimeUtcMoment.format('DDMMMYY').toUpperCase() : 'NULL';
    const asmDateTimeLtUppercase = asmDateTimeLtMoment ? asmDateTimeLtMoment.format('DDMMMYY').toUpperCase() : 'NULL';

    /*
    console.log({
      airlineDesignator: schedule.airlineDesignator,
      flightNumber: schedule.flightNumber,
      serviceType: schedule.serviceType,
      departureStation: schedule.departureStation,
      arrivalStation: schedule.arrivalStation,
      std: dayjs(departureDate.format('DDMMYYYY') + ' ' + schedule.departureAircraftSTD + ' ' + depTimezone, 'DDMMYYYY HHmm Z').format(),
      sta: dayjs(arrivalDate.format('DDMMYYYY') + ' ' + schedule.arrivalAircraftSTA + ' ' + arrTimezone, 'DDMMYYYY HHmm Z').format(),
      tod: dayjs(departureDate.format('DDMMYYYY') + ' ' + schedule.departureAircraftSTD + ' ' + depTimezone, 'DDMMYYYY HHmm Z').format(),
      toa: dayjs(arrivalDate.format('DDMMYYYY') + ' ' + schedule.arrivalAircraftSTA + ' ' + arrTimezone, 'DDMMYYYY HHmm Z').format(),
      asmUtcKey: designator + flightNum + '-' + asmDateTimeUppercase + '-' + depStation + '-' + arrStation,
      asmLtKey: designator + flightNum + '-' + asmDateTimeUppercase + '-' + depStation + '-' + arrStation,
      acType: schedule.acType,
      seatingConfiguration: schedule.seatingConfiguration
    }); */

    return {
      airlineDesignator: schedule.airlineDesignator,
      flightNumber: schedule.flightNumber,
      serviceType: schedule.serviceType,
      departureStation: schedule.departureStation,
      arrivalStation: schedule.arrivalStation,
      std: dayjs(departureDate.format('DDMMYYYY') + ' ' + schedule.departureAircraftSTD + ' ' + depTimezone, 'DDMMYYYY HHmm Z').format(),
      sta: dayjs(arrivalDate.format('DDMMYYYY') + ' ' + schedule.arrivalAircraftSTA + ' ' + arrTimezone, 'DDMMYYYY HHmm Z').format(),
      tod: dayjs(departureDate.format('DDMMYYYY') + ' ' + schedule.departureAircraftSTD + ' ' + depTimezone, 'DDMMYYYY HHmm Z').format(),
      toa: dayjs(arrivalDate.format('DDMMYYYY') + ' ' + schedule.arrivalAircraftSTA + ' ' + arrTimezone, 'DDMMYYYY HHmm Z').format(),
      asmUtcKey: designator + flightNum + '-' + asmDateTimeUtcUppercase + '-' + depStation + '-' + arrStation,
      asmLtKey: designator + flightNum + '-' + asmDateTimeLtUppercase + '-' + depStation + '-' + arrStation,
      acType: schedule.acType,
      seatingConfiguration: schedule.seatingConfiguration,
      operationStatus: 'operating'
    };
  }

  private getSpecificRC2(flightLine: number, rc2List: any[]) {
    return rc2List.reduce((prev, current) => (prev.y > current.y && prev.y < flightLine) ? prev : current, rc2List[0]);
  }

  private isSSIM(scheduleRows: string[]): boolean {
    const hStart = scheduleRows[0].startsWith('1AIRLINE STANDARD SCHEDULE DATA SET');
    const hEnd = scheduleRows[0].endsWith('000001');
    const hasHeader = hStart && hEnd;
    const hasFooter = scheduleRows[scheduleRows.length - 1] === '00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000';
    const hasTrailer = scheduleRows.filter(line => line.charAt(0) === '5').length === 1;

    return hasHeader && hasFooter && hasTrailer;
  }

  private isNullOrEmpty(valueToCheck: string, failureIsError: boolean, failureText: string, lineNumber: number) {
    if (!valueToCheck || valueToCheck === '') {
      this.prepareMessage(failureIsError ? MessageType.ERROR : MessageType.WARNING, failureText, lineNumber);
      return true;
    }
    return false;
  }

  private isAvailableInCollection(needle: string,
                                  collection: string[] = [],
                                  failureIsError: boolean,
                                  failureText: string,
                                  lineNumber: number): boolean {
    if (!collection.includes(needle)) {
      this.prepareMessage(failureIsError ? MessageType.ERROR : MessageType.WARNING, failureText, lineNumber);
      return false;
    }
    return true;
  }

  private checkIfDateIsValidAndInPeriod(dateToCheck: dayjs.Dayjs,
                                        periodFrom: dayjs.Dayjs,
                                        periodTo: dayjs.Dayjs,
                                        failureIsError: boolean,
                                        lineNumber: number) {
    if (!periodFrom || !periodFrom.isValid() || !periodTo || !periodTo.isValid()) {
      return false;
    }

    if (!dateToCheck || !dateToCheck.isValid()) {
      this.prepareMessage(failureIsError ? MessageType.ERROR : MessageType.WARNING, SSIMValidatorErrors.RC3_INVALID_PERIOD, lineNumber);
      return false;
    }

    if (!dateToCheck.isBetween(periodFrom, periodTo, 'day', '[]')) {
      this.prepareMessage(failureIsError ? MessageType.ERROR : MessageType.WARNING, SSIMValidatorErrors.RC3_INVALID_PERIOD, lineNumber);
      return false;
    }
    return true;
  }

  private prepareMessage(messageType: MessageType, failureText: string, lineNumber: number) {
    switch(messageType) {
      case MessageType.ERROR:
        this.errors.push(`${failureText} on line number ${lineNumber + 1}`);
        break;
      case MessageType.WARNING:
        this.warnings.push(`${failureText} on line number ${lineNumber + 1}`);
        break;
      case MessageType.HIGH_RISK:
        this.highRisk.push(`${failureText} on line number ${lineNumber + 1}`);
        break;
    }
  }
}
