<form *ngIf="airports?.length" [formGroup]="formGroup" class="container-fluid mt-4">
    <div class="row mt-3">
        <div class="col-6">
          <div class="light-blue-border card" formGroupName="operations">
            <div class="card-header text-center title">
              <div class="report-title">Operations
                <div ngbDropdown class="d-inline-block float-end cursor-pointer" data-test="reports-operations-menu">
                  <fa-icon [icon]="dotsIcon" ngbDropdownToggle></fa-icon>
                  <div ngbDropdownMenu>
                    <button ngbDropdownItem data-test="reports-operations-download-pdf">Download as PDF</button>
                    <button (click)="download('operationsChart','Operations',operationsChartLabel)"  ngbDropdownItem data-test="reports-operations-download-png">Download as PNG</button>
                    <button (click)="createOperationsExcelFile(operationsChartLabel, pairs, legs)" ngbDropdownItem data-test="reports-operations-download-xlsx">Download as XLSX</button>
                  </div>
                </div>
              </div>

            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <ng-select formControlName="type" data-test="reports-operation-type-select" [inputAttrs]="{ 'data-test': 'reports-operation-type-input' }" [clearable]="false" class="w-100">
                    <ng-option value="1" data-test="turnaround-performance">Turnaround Performance</ng-option>
                    <ng-option value="2" data-test="completed-non-sla-processes">Completed Non SLA Processes</ng-option>
                    <ng-option value="3" data-test="ongoing-non-sla-processes">Ongoing Non SLA Processes</ng-option>
                    <ng-option value="4" data-test="total-process-performance">Total Process Performance</ng-option>
                    <ng-option value="5" data-test="total-process-performance-breakdown">Process Performance Breakdown</ng-option>
                    <ng-option value="6" data-test="completed-turnarounds-amount-gses-used">Completed Turnarounds-Amount of GSEs Used</ng-option>
                  </ng-select>
                </div>
                <div class="col-6">
                  <div class="flex justify-content-center">
                    <app-date-picker-range-popup class="w-100" formControlName="date" data-test="reports-operation-date-range"></app-date-picker-range-popup>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  <ng-select  formControlName="airport" data-test="reports-operations-airport-select" [inputAttrs]="{ 'data-test': 'reports-operations-airport-input' }" placeholder="All Airports">
                    <ng-option *ngFor="let airport of airports" [value]="airport.iata">{{airport.iata}}</ng-option>
                    <ng-template ng-option-tmp let-item="item">
                      <span class="ng-option-label" [attr.data-test]="'airport-option-' + item">{{ item }}</span>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="col-6">
                  <ng-select formControlName="acType" data-test="reports-operations-ac-type-select" [inputAttrs]="{ 'data-test': 'reports-operations-ac-type-input' }" placeholder="All AC Types">
                    <ng-option *ngFor="let acType of acTypes" [value]="acType.iata">{{acType.description}}</ng-option>
                    <ng-template ng-option-tmp let-item="item">
                      <span class="ng-option-label" [attr.data-test]="'ac-type-option-' + item">{{ item }}</span>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="col-2 text-center" *ngIf="financeFiltersChanged">
                  <button type="button" class="btn btn-outline-success" data-test="reports-operations-apply-filters-button">Apply filters</button>
                </div>
              </div>
              <div class="row flex justify-content-center">
                <div>
                  <canvas id="operationsChart" baseChart
                          [data]="operationData"
                          [options]="operationOptions"
                          [type]="operationConfig.type">
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="light-blue-border card" formGroupName="punctuality">
            <div class="card-header text-center title">
              <div class="report-title">Punctuality
                <div ngbDropdown class="d-inline-block float-end cursor-pointer" data-test="reports-punctuality-menu">
                  <fa-icon [icon]="dotsIcon" ngbDropdownToggle></fa-icon>
                  <div ngbDropdownMenu>
                    <button ngbDropdownItem data-test="reports-punctuality-download-pdf">Download as PDF</button>
                    <button (click)="download('punctualityChart','Punctuality',punctualityData.datasets[0].label)" ngbDropdownItem data-test="reports-punctuality-download-png">Download as PNG</button>
                    <button (click)="createPunctualityExcelFile(punctualityData.datasets[0].label, legs)" ngbDropdownItem data-test="reports-punctuality-download-xlsx">Download as XLSX</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <div class="flex justify-content-center">

                    <ng-select #punctualitySelector formControlName="type" data-test="reports-punctuality-type-select" [inputAttrs]="{ 'data-test': 'reports-punctuality-type-input' }" [clearable]="false" class="w-100">
                      <ng-option value="1">On Time Performance</ng-option>
                      <ng-option value="2">Arrival Punctuality</ng-option>
                      <ng-option value="3">Delayed per DC</ng-option>
                      <ng-option value="4">Total Departure Delay</ng-option>
                      <ng-option value="5">Total Arrival Delay</ng-option>
                    </ng-select>
                  </div>
                </div>
                <div class="col-6 flex justify-content-center">
                  <app-date-picker-range-popup class="w-100" formControlName="date" data-test="reports-punctuality-date-range"></app-date-picker-range-popup>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  <ng-select  formControlName="airport" data-test="reports-punctuality-airport-select" [inputAttrs]="{ 'data-test': 'reports-punctuality-airport-input' }" placeholder="All Airports">
                    <ng-option *ngFor="let airport of airports" [value]="airport.iata">{{airport.iata}}</ng-option>
                    <ng-template ng-option-tmp let-item="item">
                      <span class="ng-option-label" [attr.data-test]="'airport-option-' + item">{{ item }}</span>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="col-2 text-center" *ngIf="financeFiltersChanged">
                  <button type="button" class="btn btn-outline-success" data-test="reports-punctuality-apply-filters-button">Apply filters</button>
                </div>
              </div>
              <div class="row flex justify-content-center">
<!--                <div [ngStyle]="{ 'max-width': punctualitySelector.value !== '3' ? '50%': ''}">-->
                <div >
                  <canvas id="punctualityChart" baseChart *ngIf="+punctualitySelector.selectedValues[0] <= 3"
                     [data]="punctualityData"
                     [options]="punctualityOptions"
                     [type]="punctualityConfig.type">
                  </canvas>
                  <div  class="flex justify-content-center flex-column" *ngIf="+punctualitySelector.selectedValues[0] === 4" [ngStyle]="{ 'height': financialChart.clientHeight + 'px'}">
                    <div class="text-center fw-bold total-delay">
                      {{totalDelayMinutes}} Minutes / {{totalFlights}} Flights
                    </div>
                  </div>
                  <div class="flex justify-content-center flex-column" *ngIf="+punctualitySelector.selectedValues[0] === 5" [ngStyle]="{ 'height': financialChart.clientHeight + 'px'}">
                    <div class="text-center fw-bold total-delay">
                      {{totalDelayMinutes}} Minutes / {{totalFlights}} Flights
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-6">
          <div class="light-blue-border card" formGroupName="passengers">
            <div class="card-header text-center title">
              <div class="report-title">Passengers
                <div ngbDropdown class="d-inline-block float-end cursor-pointer" data-test="reports-passengers-menu">
                  <fa-icon [icon]="dotsIcon" ngbDropdownToggle></fa-icon>
                  <div ngbDropdownMenu aria-labelledby="downloadedDropdown">
                    <button ngbDropdownItem data-test="reports-passengers-download-pdf">Download as PDF</button>
                    <button (click)="download('passengersChart','Passengers',passengerData.datasets[0].label)" ngbDropdownItem data-test="reports-passengers-download-png">Download as PNG</button>
                    <button (click)="createPassengersExcelFile(passengerData.datasets[0].label, legs)" ngbDropdownItem data-test="reports-passengers-download-xlsx">Download as XLSX</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <div class="flex justify-content-center">
                    <ng-select formControlName="type" data-test="reports-passengers-type-select" [inputAttrs]="{ 'data-test': 'reports-passengers-type-input' }" [clearable]="false" class="w-100">
                      <ng-option value="1">Passenger Load Factor</ng-option>
                      <ng-option value="2"># Delayed Passengers</ng-option>
                      <ng-option value="3">% Delayed Passengers</ng-option>
                      <ng-option value="4">Passenger Connections</ng-option>
                      <ng-option value="5">Passenger Misconnections</ng-option>
                    </ng-select>
                  </div>
                </div>
                <div class="col-6 flex justify-content-center">
                  <app-date-picker-range-popup class="w-100" formControlName="date" data-test="reports-passengers-date-range"></app-date-picker-range-popup>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  <ng-select  formControlName="airport" data-test="reports-passengers-airport-select" [inputAttrs]="{ 'data-test': 'reports-passengers-airport-input' }" placeholder="All Airports">
                    <ng-option *ngFor="let airport of airports" [value]="airport.iata">{{airport.iata}}</ng-option>
                    <ng-template ng-option-tmp let-item="item">
                      <span class="ng-option-label" [attr.data-test]="'airport-option-' + item">{{ item }}</span>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="col-6">
                  <ng-select formControlName="acType" data-test="reports-passengers-ac-type-select" [inputAttrs]="{ 'data-test': 'reports-passengers-ac-type-input' }" placeholder="All AC Types">
                    <ng-option *ngFor="let acType of acTypes" [value]="acType.iata">{{acType.description}}</ng-option>
                    <ng-template ng-option-tmp let-item="item">
                      <span class="ng-option-label" [attr.data-test]="'ac-type-option-' + item">{{ item }}</span>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="col-2 text-center" *ngIf="financeFiltersChanged">
                  <button type="button" class="btn btn-outline-success" data-test="reports-passengers-apply-filters-button">Apply filters</button>
                </div>
              </div>
              <div class="row flex justify-content-center">
                <div>
                  <canvas id="passengersChart" baseChart
                          [data]="passengerData"
                          [options]="passengerOptions"
                          [type]="passengerConfig.type">
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="light-blue-border card" formGroupName="utilization">
            <div class="card-header text-center title">
              <div class="report-title">Utilization
                <div ngbDropdown class="d-inline-block float-end cursor-pointer" data-test="reports-finance-menu">
                  <fa-icon [icon]="dotsIcon" ngbDropdownToggle></fa-icon>
                  <div ngbDropdownMenu aria-labelledby="downloadedDropdown">
                    <button ngbDropdownItem data-test="reports-finance-download-pdf">Download as PDF</button>
                    <button (click)="download('utilizationChart','Utilization',utilizationData.datasets[0].label)"  ngbDropdownItem data-test="reports-finance-download-png">Download as PNG</button>
                    <button (click)="createUtilizationExcelFile(utilizationData.datasets[0].label, legs)" ngbDropdownItem data-test="reports-finance-download-xlsx">Download as XLSX</button>

                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <div class="flex justify-content-center">
                    <ng-select formControlName="type" data-test="reports-finance-type-select" [inputAttrs]="{ 'data-test': 'reports-finance-type-input' }" [clearable]="false" class="w-100">
                      <ng-option value="1">AC Reg Utilization</ng-option>
                      <ng-option value="2">AC Type Utilization</ng-option>
                      <!-- <option value="1" selected data-select="landing-fees-per-flight">Landing fees - Per Flight</option>
                      <option value="2" data-select="landing-fees-all-airports">Landing fees - All Airports</option>
                      <option value="3" data-select="parking-fees-per-flight">Parking fees - Per Flight</option>
                      <option value="4" data-select="parking-fees-all-airports">Parking fees - All Airports</option>
                      <option value="5" data-select="gse-costs">GSE Costs</option>
                      <option value="6" data-select="pax-handling-fees">PAX Handling Fees</option> -->
                    </ng-select>
                  </div>
                </div>
                <div class="col-6 flex justify-content-center">
                  <app-date-picker-range-popup class="w-100" data-test="reports-finance-date-range"></app-date-picker-range-popup>
                </div>
              </div>
              <div class="row mt-3">

                <div class="col-6">
                  <ng-select  formControlName="airport" data-test="reports-finance-airport-select" [inputAttrs]="{ 'data-test': 'reports-finance-airport-input' }" placeholder="All Airports">
                    <ng-option *ngFor="let airport of airports" [value]="airport.iata">{{airport.iata}}</ng-option>
                    <ng-template ng-option-tmp let-item="item">
                      <span class="ng-option-label" [attr.data-test]="'airport-option-' + item">{{ item }}</span>
                    </ng-template>
                  </ng-select>
                </div>
                <div *ngIf="formGroup.get('utilization').value.type !== '1'" class="col-6">
                  <ng-select formControlName="acType" data-test="reports-finance-ac-type-select" [inputAttrs]="{ 'data-test': 'reports-finance-ac-type-input' }" placeholder="All AC Types">
                    <ng-option *ngFor="let acType of acTypes" [value]="acType.iata">{{acType.description}}</ng-option>
                    <ng-template ng-option-tmp let-item="item">
                      <span class="ng-option-label" [attr.data-test]="'ac-type-option-' + item">{{ item }}</span>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="col-2 text-center" *ngIf="financeFiltersChanged">
                  <button type="button" class="btn btn-outline-success" data-test="reports-finance-apply-filters-button">Apply filters</button>
                </div>
              </div>
              <div class="row flex justify-content-center">
                <div>
                  <canvas id="utilizationChart" baseChart
                          [data]="utilizationData"
                          [options]="utilizationOptions"
                          [type]="utilizationConfig.type"
                          #financialChart>
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</form>

