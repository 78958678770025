import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IUser} from '../../../models/user.model';
import {IFlightPair} from '../../../models/flight-pair.model';
import {UserRoles} from '../../../constants/user-roles.constants';

@Component({
  selector: 'app-chat-header-selectable',
  templateUrl: './chat-header-selectable.component.html',
  styleUrls: ['./chat-header-selectable.component.scss']
})
export class ChatHeaderSelectableComponent implements OnInit {

  @Input() user: IUser;
  @Input() pair: IFlightPair;
  userRoleObject = UserRoles;
  @Output() closeChatAction = new EventEmitter();

  selection = 1;

  opt1Title = '';
  opt2Title = '';

  line1Title = '';

  role = 'DUTY_MANAGER';
  // role = 'OPS_CONTROLLER';

  names = 'Sabrina, Nik, Christopher';
  @Input() canClose = true;

  constructor() {
  }

  ngOnInit() {
    if (this.user) {
      this.setTitleByRole(this.user.role);
    }
  }

  setTitleByRole(role: string) {
    if (role === 'OPS_CONTROLLER') {
      this.opt1Title = 'ZHR';
      this.opt2Title = 'ATH';
      this.line1Title = 'Duty Managers';
    } else if (role === 'DUTY_MANAGER') {
      this.opt1Title = 'OPS CONTROLLER';
      this.opt2Title = 'RAMP AGENT';
      this.line1Title = '';
    }
  }

  close() {
    this.closeChatAction.emit();
  }
}
