<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()" data-test="view-memos-close-button">
  </button>
</div>
<div class="modal-body dark-blue">
  <div class="title">Departure Memo</div>
  <div class="container mb-3 p-3 border-all">
    <div class="row mb-3">
      <div *ngIf="departureMemo" class="col-12 color-gray">
        <!-- last update 20.12.2019 22:30 by Anna Black -->
        {{departureMemo?.content !== '' ? 'last update on ' : 'deleted on '}} {{departureMemo.lastChangedAt | customDate:dateFormat + ' HH:mm'}} by {{arrivalMemoUser?.firstname}} {{arrivalMemoUser?.lastname}}
      </div>
      <div *ngIf="!departureMemo" class="col-12 color-gray">
        no memo for departure leg added yet
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <pre>{{departureMemo?.content ? departureMemo.content : ''}}</pre>
        <!-- Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.

        Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. -->
      </div>
    </div>
  </div>
  <div class="title">Arrival Memo</div>
  <div class="container mb-3 p-3 border-all">
    <div class="row mb-3">
      <div *ngIf="arrivalMemo" class="col-12 color-gray">
        <!-- last update 20.12.2019 22:30 by Anna Black -->
        {{arrivalMemo?.content !== '' ? 'last update on ' : 'deleted on '}} {{arrivalMemo.lastChangedAt | customDate:dateFormat + ' HH:mm':'UTC'}} by {{arrivalMemoUser?.firstname}} {{arrivalMemoUser?.lastname}}
      </div>
      <div *ngIf="!arrivalMemo" class="col-12 color-gray">
        no memo for arrival leg added yet
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <pre>{{arrivalMemo?.content ? arrivalMemo.content : ''}}</pre>
        <!-- Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.

        Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. -->
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-danger settings-modal-button" type="button" (click)="deleteMemo()" [disabled]="!arrivalMemo?.content && !departureMemo?.content" data-test="view-memos-delete-button">Delete Memo</button>
  <button *ngIf="(arrivalMemo && arrivalMemo.content !== '') || (departureMemo && departureMemo.content !== '')" class="btn button-blue settings-modal-button" type="button" (click)="editMemo()" data-test="view-memos-edit-button">Edit Memo</button>
  <button *ngIf="(!arrivalMemo || arrivalMemo.content === '') && (!departureMemo || departureMemo.content == '')" class="btn button-blue settings-modal-button" type="button" (click)="addMemo()" data-test="view-memos-add-button">Add Memo</button>
</div>
