import { Component, OnDestroy, OnInit } from '@angular/core';
import { IAcType } from '../../../shared/models/ac-type.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { DeleteModalComponent } from '../../../shared/components/delete-modal/delete-modal.component';
import { IGenericContainerObject } from '../../../shared/models/genericContainerObject.model';
import { ISimpleData } from '../../../shared/models/simpleData.model';
import { AcTypesService } from '../../../services/ac-types.service';
import { GeneralSettingsService } from '../../../services/general-settings.service';
import { ToastService } from '../../../services/toast.service';
import { IGeneralSettingsModel } from '../../../shared/models/general-settings.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ISeatingConfigurationModel } from '../../../shared/models/seating-configuration.model';
import { FlightType } from '../../../shared/constants/flight-types.constants';
import { AirportsService } from '../../../services/airports.service';
import { forkJoin, Subject } from 'rxjs';
import { IAirlineDesignator } from '../../../shared/models/airline-designator.model';
import * as dayjs from 'dayjs';
import { AcTypesTestTagPipe } from '../../../shared/pipes/ac-types-test-tag.pipe';

@Component({
  selector: 'app-settings-ac-type-management',
  templateUrl: './settings-ac-type-management.component.html',
  styleUrls: ['./settings-ac-type-management.component.scss']
})
export class SettingsAcTypeManagementComponent implements OnInit, OnDestroy {

  passengerClasses: ISimpleData[];
  flightServiceTypes: ISimpleData[];
  generalSettings?: IGeneralSettingsModel;
  measurementUnits?: ISimpleData[];
  airlineDesignators: IAirlineDesignator[];
  acTypes: IAcType[];
  activeAcTypeId: number = 0;
  dateFormat: string = 'DD/MM/YYYY';
  loaded = false;
  // AC Type ID -> Flight Service Type From -> Flight Service Type To -> Time in minutes
  DOMDOM: IGenericContainerObject<IGenericContainerObject<IGenericContainerObject<number>>> = {};
  DOMINT: IGenericContainerObject<IGenericContainerObject<IGenericContainerObject<number>>> = {};
  INTINT: IGenericContainerObject<IGenericContainerObject<IGenericContainerObject<number>>> = {};
  INTDOM: IGenericContainerObject<IGenericContainerObject<IGenericContainerObject<number>>> = {};

  selected = 0;
  selectedAcType: IAcType;
  unsubscribe$ = new Subject();
  seatingConfigurationsForSelectedAcType: { [order: number]: ISeatingConfigurationModel[] } = {};
  dayjs = dayjs;

  constructor(private modalService: NgbModal, public acTypesService: AcTypesService, private generalSettingsService: GeneralSettingsService, private toastService: ToastService, private router: Router, private route: ActivatedRoute, private airportsService: AirportsService) {}

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    forkJoin([this.acTypesService.fetchAcTypes(), this.generalSettingsService.getMeasurementUnits(), this.generalSettingsService.getGeneralSettings(), this.generalSettingsService.getFlightServiceTypes(), this.generalSettingsService.getPassengerClasses(), this.generalSettingsService.getAirlineDesignators()]).subscribe((results) => {
      this.acTypes = results[0];
      this.measurementUnits = results[1];
      this.generalSettings = results[2]?.length ? results[2][0] : {};
      this.dateFormat = this.generalSettings?.dateFormat;
      this.flightServiceTypes = results[3];
      this.passengerClasses = results[4];
      this.airlineDesignators = results[5];

      this.activeAcTypeId = this.acTypes[0]?.id;
      this.selectedAcType = this.acTypes[0] || null;
      this.seatingConfigurationsForSelectedAcType = {};
      this.parseSeatingConfiguration();
      this.getMinimumTurnaroundTimesForAllAcTypes();
      this.loaded = true;
    });
  }

  doAdd() {
    this.router.navigate(['add'], {relativeTo: this.route });
  }

  doEdit(item: string | any) {
    this.router.navigate([item], {relativeTo: this.route });
  }

  doDelete(item: string | any) {
    if (!item || !this.acTypes?.length || !this.acTypes.find((acType) => acType.id === +item)) {
      return;
    }

    const acType = this.acTypes.find((acType) => acType.id === +item);
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.term = 'A/C Type ' + acType.iata;
    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.acTypesService.deleteAcType(+item).subscribe((response) => {
        if (response) {
          this.toastService.showSuccess('AC Type has been deleted');
          this.acTypesService.fetchAcTypes().subscribe((results) => {
            this.acTypes = results;
          });
        }

      });
    });
  }

  getMinimumTurnaroundTimesForAllAcTypes() {
    this.airportsService.fetchTurnaroundTimes({
        acTypeId: this.acTypes.map((acType) => acType.id),
        isActive: true,
    }).subscribe((result) => {
      for (const acType of this.acTypes) {
        const acTypeId = acType.id;
        for (const flightType of [FlightType.DOMINT, FlightType.DOMDOM, FlightType.INTDOM, FlightType.INTINT]) {
          this[flightType][acTypeId] = {};
          for (const flightServiceTypeHeader of this.flightServiceTypes) {
            for (const flightServiceType of this.flightServiceTypes) {
              if (!this[flightType][acTypeId][flightServiceTypeHeader.id]) {
                this[flightType][acTypeId][flightServiceTypeHeader.id] = {};
              }
              this[flightType][acTypeId][flightServiceTypeHeader.id][flightServiceType.id] = 0;
            }
          }
        }
      }
      for (const turnaroundTime of result) {
        const from = this.flightServiceTypes.findIndex((fs) => fs.id === turnaroundTime.flightServiceTypeFrom);
        const to = this.flightServiceTypes.findIndex((fs) => fs.id === turnaroundTime.flightServiceTypeTo);
        if (from === -1 || to === -1) {
          continue;
        }
        this[turnaroundTime.flightType][turnaroundTime.acTypeId][turnaroundTime.flightServiceTypeFrom][turnaroundTime.flightServiceTypeTo] = turnaroundTime.timeInMinutes;
      }
    });
  }

  selectedAcTypeChanged() {
    this.activeAcTypeId = this.selectedAcType?.id;
    this.seatingConfigurationsForSelectedAcType = {};
    this.parseSeatingConfiguration();
  }

  parseSeatingConfiguration() {
    if (!this.selectedAcType) {
      return;
    }
    for (const seat of this.selectedAcType.seatingConfigurations) {
      if(seat.acRegistrationId) {
        continue;
      }
      if (!this.seatingConfigurationsForSelectedAcType[seat.order]) {
        this.seatingConfigurationsForSelectedAcType[seat.order] = [];
      }
      this.seatingConfigurationsForSelectedAcType[seat.order].push(seat);
    }
  }

  getDesignatorNameFromId(designatorId: number) {
    if (!designatorId) {
      return '-';
    }
    return this.airlineDesignators.find((des) => des.id === designatorId)?.description || '-';
  }

  getNumberForSeatsForCode(order: number, seat: string) {
    return this.seatingConfigurationsForSelectedAcType[order].find((s) => s.code === seat)?.description || '-';
  }



  Object = Object;
    public readonly AcTypesTestTagPipe = AcTypesTestTagPipe;
}
