import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import { forkJoin, Observable, Subject } from 'rxjs';
import { GeneralSettingsService } from 'src/app/services/general-settings.service';
import { LegsService } from 'src/app/services/legs.service';
import { PairLegTimesLogService } from 'src/app/services/pair-leg-times-log.service';
import { ToastService } from 'src/app/services/toast.service';
import { FlightsIssueNewInformationFormBuilder } from '../../forms/formBuilders/flights/flights-issue-new-information-form-builder';
import { IGenericContainerObject } from '../../models/genericContainerObject.model';
import { ILegsModel } from '../../models/legs.model';
import { ISimpleData } from '../../models/simpleData.model';
import { IPairDetail } from '../../models/pair-detail.model';
import { dayjsToNgbDate, getTimestamp, ngbDateToDayjs, ngbDateToFormat } from '../../utils/utils';
import { takeUntil } from 'rxjs/operators';
import { TransferFileService } from '../../../services/transfer-file.service';
import { ITransferFile } from '../../models/transfer-file.model';

@Component({
  selector: 'app-issue-new-info-dialog',
  templateUrl: './issue-new-info-dialog.component.html',
  styleUrls: ['./issue-new-info-dialog.component.scss']
})
export class IssueNewInfoDialogComponent implements OnInit {
  destroySubject = new Subject();

  isBusy = false;
  form?: FormGroup;
  delayCodes?: ISimpleData[];
  title: string;
  pair?: IPairDetail;
  dateFormat = 'DD/MM/YYYY';


  constructor(
    private fb: FormBuilder,
    private db: AngularFireDatabase,
    private fns: AngularFireFunctions,
    private toastService: ToastService,
    private activeModal: NgbActiveModal,
    private generalSettingsService: GeneralSettingsService,
    private pairLegTimeLogService: PairLegTimesLogService,
    private legsService: LegsService,
    private transferFileService: TransferFileService) {}

  ngOnInit() {
    this.generalSettingsService.generalSettings.pipe(takeUntil(this.destroySubject)).subscribe((settings) => {
      this.dateFormat = settings.dateFormat;
    });
    this.fetchDelayCodes();
    this.generateForm();
    this.form?.patchValue({
      flightType: 'departure',
      newInformationDate: dayjsToNgbDate(dayjs.utc())
    });

    if (!this.pair || !this.pair.arrivalLeg || !this.pair.departureLeg) {
      this.form?.get('flightType')?.disable();
    }
    this.form?.markAsDirty();
  }

  ngOnDestroy() {
    this.destroySubject.next(undefined);
    this.destroySubject.complete();
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onSaveClick() {
    if(!this.form.valid) {
      return;
    }
    this.isBusy = true;
    console.log('Form value:', this.form.value);
    const splitTime = this.form.value.newInformationTime.split(':');
    const ni = ngbDateToDayjs(this.form.value.newInformationDate).hour(splitTime[0]).minute(splitTime[1]).utc(true);
    this.legsService.saveLeg({ ...this.pair.departureLeg, ni: ni.utc().toDate(), niDelayCode: this.form.value.delayCode } as ILegsModel).subscribe((result) => {
      if(result?.id)
      {
        this.toastService.showSuccess("New information time has been saved");
        this.createNIMVT(this.form.value, this.pair.departureLeg)
        this.closeModal();
      }
      this.isBusy = false;
    });
  }

  createNIMVT(formValue: any, leg: ILegsModel) {
    const lineSeperator = '\n'
    const delayCode = this.delayCodes.find((dl) => dl.id === formValue.delayCode).code;
    const content = `MVT${lineSeperator}${(leg.airlineDesignator) + (leg.flightNumber)}/${dayjs(leg.std).utc().format('DD').toUpperCase()}.${leg.acRegistration}.${leg.departureStation}${lineSeperator}NI${ngbDateToFormat(formValue.newInformationDate, 'DD').toUpperCase()}${formValue.newInformationTime?.replace(':', '')}${lineSeperator}DL${delayCode}`;
    console.log(content);
    const name = 'NI' + ngbDateToFormat(formValue.newInformationDate, 'DDMMMYY').toUpperCase() + '__##__' + 'MVT_SFTP' + '_' + getTimestamp() + '_' +'OUT' + '.txt'
    const file: ITransferFile = {fileName: name, fileContent: content}
    this.transferFileService.sendFile(file).subscribe();

  }

  fetchDelayCodes () {
    this.generalSettingsService.getDelayCodes().subscribe((result) => {
      this.delayCodes = result;
    });
  }

  generateForm() {
    this.form = FlightsIssueNewInformationFormBuilder.constructForm(this.fb);
  }
}
